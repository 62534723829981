export const getErrorMessage = (errorCode?: string) => {
  const DEFAULT_MESSAGE = 'error.message.default'

  if (!errorCode) {
    return DEFAULT_MESSAGE
  }

  const handledErrorCodes = [
    'LP0007' /* SmartSectionEmpty */,
    'LP0008' /* SmartSectionTooLong */,
    'LP0009' /* SmartSectionAlreadyTaken */,
    'LP0010' /* SmartSectionForbiddenEmoji */,
    'LP0011' /* SmartSectionGroupEmpty */,
    'LP0012' /* SmartSectionGroupTooLong */,
    'LP0013' /* SmartSectionGroupAlreadyTaken */,
    'LP0014' /* SmartSectionGroupForbiddenEmoji */,
    'LP0015' /* GroupEmpty */,
    'LP0016' /* GroupTooLong */,
    'LP0017' /* GroupAlreadyTaken */,
    'LP0018' /* GroupForbiddenEmoji */,
    'LP0019' /* NameEmpty */,
    'LP0020' /* NameTooLong */,
    'LP0021' /* NameForbiddenEmoji */,
    'LP0035' /* LandingPageUnabledToMove */,
    'LP0036' /* ImportForbiddenForFreeAndTrial */,
    'LP0037' /* InvalidMimeType */,
    'LS0003' /* Leads - export already in progress */
  ]

  if (handledErrorCodes.includes(errorCode)) {
    return `error.message.${errorCode}`
  }

  return DEFAULT_MESSAGE
}
