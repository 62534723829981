import { SIDEBAR_BANNER_CLICK_EVENT_NAME } from '@constants/mixpanel'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useUserContext } from '@contexts/user'
import { getLocalStorage, setLocalStorage } from '@helpers/storage'
import { useShouldSeeItem } from '@hooks/useShouldSeeItem'
import { mixpanelEvent } from '@services/mixpanel'
import { Button, Icon, Spacer, Spreader, Text, VerticalBanner } from '@ui-kit'
import { ElementAnimation } from '@ui-kit/SidebarItem/components/ElementAnimation'
import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import styles from './SidebarBanner.module.scss'

// Change the banner NAME value when changing content
const bannerName = 'domain-and-fonts'

export const LOCAL_STORAGE_NAME = `sidebar-banner-${bannerName}`

interface SidebarBannerProps {
  isSidebarMini: boolean
}

export const SidebarBanner: FC<SidebarBannerProps> = ({ isSidebarMini }) => {
  const { t } = useTranslation()

  const RELEASE_DATE = '2025-03-13'

  const { createdAt } = useUserContext()

  const shouldSeeBanner = useShouldSeeItem({
    referenceDate: createdAt?.formatted,
    targetDate: RELEASE_DATE
  })

  const hasAccessToSidebarBanner =
    useFeatureFlags('SIDEBAR_BANNER') &&
    getLocalStorage(LOCAL_STORAGE_NAME) !== 'false' &&
    !isSidebarMini &&
    shouldSeeBanner

  const handleCloseBanner = () => {
    setLocalStorage(LOCAL_STORAGE_NAME, 'false')
  }

  const handleSendMixpanelEvent = () =>
    mixpanelEvent({ name: SIDEBAR_BANNER_CLICK_EVENT_NAME })

  return (
    <div className={styles['sidebar-banner']}>
      {hasAccessToSidebarBanner ? (
        <ElementAnimation>
          <div onClick={handleSendMixpanelEvent}>
            <VerticalBanner
              variant='info'
              closeButton={
                <Button
                  onClick={handleCloseBanner}
                  data-testid='sidebar-banner-button'
                >
                  {t('word.close')}
                </Button>
              }
              data-testid='sidebar-banner'
            >
              <Text size={12}>
                <Trans i18nKey='sidebar.banner.content' />

                <Spacer space={5} />

                <Text size={12} color='neutral-6' weight={600}>
                  <Icon icon='icon-cog' size={12} color='neutral-6' />

                  <Spreader spread={5} />

                  {t('topbar.settings')}
                </Text>
              </Text>
            </VerticalBanner>
          </div>
        </ElementAnimation>
      ) : null}
    </div>
  )
}

SidebarBanner.displayName = 'SidebarBanner'
