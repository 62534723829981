import { APP_LANDINGI_URL } from '@config/env'
import { NEW_LANDINGI_APP_URL } from '@constants/index'
import { PLANS } from '@constants/plans'
import { useAcl } from '@contexts/acl'
import { useUserContext } from '@contexts/user'
import { ACCOUNT, PAYMENTS } from '@routes/path'
import { Button, Spacer } from '@ui-kit'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { useGetAccountVariant } from '../hooks/useGetAccountVariant'
import {
  AccountVariantsTypes,
  FREE_TRIAL_WITH_CREDIT_CARD,
  FREE_TRIAL_WITHOUT_CREDIT_CARD,
  OptionsTypes,
  PAID_ACCOUNT
} from '../types'

export const UpgradeButton = () => {
  const { t } = useTranslation()

  const { planRecordKey } = useUserContext()

  const hasAccessToPayment = useAcl('payments.pay')
  const hasAccessToChangePlan = useAcl('plan.change_plan')

  const isUnlimitedPlan = planRecordKey === PLANS.UNLIMITED_22

  const accountVariant = useGetAccountVariant({
    paidAccountACL: hasAccessToChangePlan && !isUnlimitedPlan,
    freeTrialWithCreditCardACL: hasAccessToPayment,
    freeTrialWithoutCreditCardACL: hasAccessToPayment
  })

  const buttonOptions: {
    [key in AccountVariantsTypes]: OptionsTypes
  } = {
    [FREE_TRIAL_WITH_CREDIT_CARD]: {
      text: t('word.start.subscription'),
      url: `${NEW_LANDINGI_APP_URL}${PAYMENTS.SUBSCRIPTION.SUBSCRIPTION}`
    },
    [FREE_TRIAL_WITHOUT_CREDIT_CARD]: {
      text: t('topbar.my-usage.buy-now'),
      url: `${NEW_LANDINGI_APP_URL}${PAYMENTS.PAYMENTS}`
    },
    [PAID_ACCOUNT]: {
      text: t('word.upgrade'),
      url: `${APP_LANDINGI_URL}${ACCOUNT.ACCOUNT_PACKAGE}`
    }
  }

  const handleOpenTab = () => {
    if (accountVariant) {
      window.open(buttonOptions[accountVariant].url, '_blank')
    }
  }

  return accountVariant ? (
    <Fragment>
      <Spacer space={20} />

      <Button size={12} onClick={handleOpenTab} data-testid='upgrade-button'>
        {buttonOptions[accountVariant].text}
      </Button>
    </Fragment>
  ) : null
}

UpgradeButton.displayName = 'TopbarMyUsageButtonsUpgradeButton'
