import { useStyles } from '@hooks/useStyles'
import { NeutralColor } from '@ui-kit/types/color'
import { SpaceType } from '@ui-kit/types/space'
import { ButtonHTMLAttributes, forwardRef, ReactNode } from 'react'

import styles from './DropdownList.module.scss'

export interface DropdownElementProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'className'> {
  className?: string | string[]
  children: ReactNode
  hasDynamicHeight?: boolean
  isActive?: boolean
  padding?:
    | {
        x: SpaceType
        y: SpaceType
      }
    | number
  border?: { bottom: Extract<NeutralColor, 'neutral-2'> }
  'data-testid'?: string
}

export const DropdownElement = forwardRef<
  HTMLButtonElement,
  DropdownElementProps
>(
  (
    {
      className,
      children,
      hasDynamicHeight,
      isActive = false,
      padding = {
        x: 15,
        y: 10
      },
      border,
      'data-testid': dataTestId = 'dropdown-element',

      ...restProps
    },
    ref
  ) => {
    const paddingX = typeof padding !== 'number' ? padding.x : null
    const paddingY = typeof padding !== 'number' ? padding.y : null

    const dropdownElementStyles = useStyles(
      {
        [styles['dropdown-list__element']]: true,
        [styles['dropdown-list__element--active']]: isActive,
        [styles['dropdown-list__element--dynamic-height']]: hasDynamicHeight,
        [styles[`dropdown-list__element--padding-x-${paddingX}`]]: paddingX,
        [styles[`dropdown-list__element--padding-y-${paddingY}`]]: paddingY,
        [styles[`dropdown-list__element--border-bottom-${border?.bottom}`]]:
          border?.bottom
      },
      className
    )

    return (
      <button
        {...restProps}
        className={dropdownElementStyles}
        type='button'
        data-testid={dataTestId}
        ref={ref}
      >
        <span className={styles.content}>{children}</span>
      </button>
    )
  }
)

DropdownElement.displayName = 'DropdownElement'
