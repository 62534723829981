import { Image } from '@ui-kit/Image'
import { FC } from 'react'

import { icons, IconTypes, StatusTypes } from './icons'

export interface ColorIconProps {
  icon: IconTypes
  status: StatusTypes
  size?: 10 | 12 | 14
  'data-testid'?: string
}

export const ColorIcon: FC<ColorIconProps> = ({
  icon,
  status,
  size = 12,
  'data-testid': dataTestId = 'color-icon'
}) => (
  <Image
    src={icons[icon][status]}
    width={`${size}px`}
    data-testid={dataTestId}
  />
)

ColorIcon.displayName = 'ColorIcon'
