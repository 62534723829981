import {
  AGENCY as AGENCY_ACCOUNT,
  CLIENT as CLIENT_ACCOUNT,
  NORMAL as NORMAL_ACCOUNT
} from '@constants/customerTypes'
import { useAcl } from '@contexts/acl'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useUserContext } from '@contexts/user'

export const TABS = {
  LEADS: 'LEADS',
  ORDERS: 'ORDERS',
  ADD_ONS: 'ADD_ONS',
  MY_USAGE: 'MY_USAGE',
  PRODUCT_UPDATES: 'PRODUCT_UPDATES'
} as const

export type TabTypes = keyof typeof TABS

export const useCheckAccessToTab = (tab: TabTypes) => {
  const { isSupportMode, accountType, isTypeDesigner, isTypeReadOnly } =
    useUserContext()

  const hasAccessToAddonsStore = useFeatureFlags('ADDONS_STORE')

  const hasAccessToEcommerceOrders = useAcl('ecommerce.sidebar_orders')
  const hasAccessToProductUpdates = useAcl('top_bar.product_updates.view')

  const sidebarAccesses = {
    [TABS.LEADS]: {
      [AGENCY_ACCOUNT]: !isSupportMode,
      [CLIENT_ACCOUNT]: !isSupportMode,
      [NORMAL_ACCOUNT]: !isSupportMode && !isTypeDesigner
    },
    [TABS.ORDERS]: {
      [AGENCY_ACCOUNT]: hasAccessToEcommerceOrders,
      [CLIENT_ACCOUNT]: hasAccessToEcommerceOrders,
      [NORMAL_ACCOUNT]: hasAccessToEcommerceOrders && !isTypeDesigner
    },
    [TABS.ADD_ONS]: {
      [AGENCY_ACCOUNT]: hasAccessToAddonsStore,
      [CLIENT_ACCOUNT]: false,
      [NORMAL_ACCOUNT]: hasAccessToAddonsStore
    },
    [TABS.MY_USAGE]: {
      [AGENCY_ACCOUNT]: true,
      [CLIENT_ACCOUNT]: false,
      [NORMAL_ACCOUNT]: !isTypeReadOnly
    },
    [TABS.PRODUCT_UPDATES]: {
      [AGENCY_ACCOUNT]: hasAccessToProductUpdates,
      [CLIENT_ACCOUNT]: hasAccessToProductUpdates,
      [NORMAL_ACCOUNT]: hasAccessToProductUpdates
    }
  }

  return sidebarAccesses[tab][accountType || NORMAL_ACCOUNT]
}
