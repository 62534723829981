import {
  TABS,
  useCheckAccessToTab
} from '@components/SidebarNew/hooks/useCheckAccessToTab'
import { SETTINGS } from '@routes/path'
import ADDONS_STORE from '@routes/path/addons'
import { SidebarItem } from '@ui-kit'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { MyUsage } from './components/MyUsage'
import styles from './SecondNavigationPart.module.scss'

interface SecondNavigationPartProps {
  isSidebarMini: boolean
}

export const SecondNavigationPart: FC<SecondNavigationPartProps> = ({
  isSidebarMini
}) => {
  const { t } = useTranslation()

  const hasAccessToTab = useCheckAccessToTab

  return (
    <nav className={styles.navigation}>
      {hasAccessToTab(TABS.ADD_ONS) ? (
        <SidebarItem
          id='sidebar-addons'
          icon='icon-extension'
          size={12}
          name={t('sidebar.add-ons')}
          pathname={ADDONS_STORE.ADDONS_STORE}
          isMini={isSidebarMini}
          hasCustomIcon
        />
      ) : null}

      <SidebarItem
        id='sidebar-settings'
        icon='icon-cog'
        size={12}
        name={t('topbar.settings')}
        pathname={SETTINGS.SETTINGS}
        isMini={isSidebarMini}
        hasCustomIcon
      />

      {hasAccessToTab(TABS.MY_USAGE) ? (
        <MyUsage isSidebarMini={isSidebarMini} />
      ) : null}

      <SidebarItem
        id='sidebar-integrations'
        icon='icon-plug'
        size={12}
        name={t('sidebar.integrations')}
        pathname={t('addons.store.e.commerce.faq.answer.3.link')}
        isMini={isSidebarMini}
        isExternal
        hasCustomIcon
      />
    </nav>
  )
}

SecondNavigationPart.displayName = 'SecondNavigationPart'
