import { Header } from '@components/Sidebar/components/Header'
import { Switcher } from '@components/Sidebar/components/Menu/Switcher'
import { SidebarNavigation } from '@components/Sidebar/components/SidebarNavigation'
import { Workspaces } from '@components/Sidebar/components/Workspaces'
import { BREAKPOINT } from '@constants/breakpoints'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useSidebarContext } from '@contexts/sidebar'
import { useUserContext } from '@contexts/user'
import { useBreakpoint } from '@hooks/useBreakpoint'
import { useHover } from '@hooks/useHover'
import { Backdrop, Spacer } from '@landingi/landingi-ui-kit'
import { AnimatePresence, motion } from 'framer-motion'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'

import { SidebarPromoBanner } from './components/SidebarPromoBanner'
import styles from './Sidebar.module.scss'

export const Sidebar = () => {
  const { t } = useTranslation()

  const isLowerThan1350 = useBreakpoint(BREAKPOINT[1350], 'max')
  const isLowerThan1130 = useBreakpoint(BREAKPOINT[1130], 'max')

  const { isSidebarMobileOpen, toggleSidebarMobileOpen } = useSidebarContext()

  const [sidebarProps, isHoveredSidebar] = useHover()

  const {
    accountName,
    isSubaccount,
    isTypeOwner,
    isTypeAdmin,
    isTypeClient,
    isTypeAccountant
  } = useUserContext()

  const hasAccessToSemrushIntegration = useFeatureFlags('SEMRUSH_INTEGRATION')

  const [sidebarKey, setSidebarKey] = useState(uuidv4())

  const hasAccessToSidebar =
    (isTypeOwner || isTypeAdmin) &&
    !isSubaccount &&
    !hasAccessToSemrushIntegration

  const isSidebarMini = isLowerThan1350 && !isHoveredSidebar && !isLowerThan1130

  const sidebarVariants = {
    mini: { width: 70, x: 0 },
    normal: { width: 220, x: 0 },
    close: { width: 220, x: -220 },
    open: { width: 220, x: 0 }
  }

  const animation = useCallback(() => {
    if (isSidebarMini) {
      return 'mini'
    }

    if (isLowerThan1130) {
      if (isSidebarMobileOpen) {
        return 'open'
      }

      return 'close'
    }

    return 'normal'
  }, [isSidebarMini, isLowerThan1130, isSidebarMobileOpen])

  const animateAndInitial = animation()

  const motionDivProps = !isLowerThan1130 ? sidebarProps : {}

  useEffect(() => {
    setSidebarKey(uuidv4())
  }, [])

  return hasAccessToSidebar || !isTypeAccountant ? (
    <Fragment>
      <Header
        sidebarMini={isSidebarMini || false}
        isToggleButtonVisible={isLowerThan1130}
        {...motionDivProps}
      />

      <AnimatePresence>
        <motion.div
          key={sidebarKey}
          {...motionDivProps}
          className={styles.sidebar}
          animate={animateAndInitial}
          variants={sidebarVariants}
          transition={{ duration: 0.5, ease: [0.65, 0, 0.3, 1] }}
          initial={animateAndInitial}
        >
          <div className={styles.sidebar__top}>
            <Spacer space='large' />

            {isTypeClient ? null : (
              <Fragment>
                <Switcher
                  text={accountName || t('word.main-account')}
                  icon={accountName ? 'icon-building' : 'icon-home'}
                  sidebarMini={isSidebarMini}
                />

                <Spacer />
              </Fragment>
            )}
          </div>

          <SidebarNavigation isSidebarMini={isSidebarMini || false} />
        </motion.div>
      </AnimatePresence>

      <SidebarPromoBanner
        isSidebarMini={isSidebarMini}
        isLaptop={isLowerThan1130}
        isSidebarMobileOpen={isSidebarMobileOpen}
      />

      <Workspaces />

      {isSidebarMobileOpen ? (
        <Backdrop onClick={toggleSidebarMobileOpen} zIndex='4' />
      ) : null}
    </Fragment>
  ) : null
}

Sidebar.displayName = 'Sidebar'
