import SurveyBox from '@components/SurveyBox'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useStyles } from '@hooks/useStyles'
import HalfCircleGreenImage from '@images/registration/half_circle_green.svg'
import SquareOrangeImage from '@images/registration/square_orange.svg'
import TriangleBlueImage from '@images/registration/triangle_blue.svg'
import { Heading, Image, Paragraph } from '@landingi/landingi-ui-kit'
import styles from '@pages/Authentication/components/SecondColumn/SecondColumn.module.scss'
import {
  shapePositionsByOrderAfterSurveyFinish,
  surveyBoxes
} from '@pages/Authentication/components/SecondColumn/Survey/surveyBoxes'
import { useSurveyContext } from '@pages/Authentication/contexts/survey'
import { AnimatePresence, motion } from 'framer-motion'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Column } from 'simple-flexbox'

const ShapesContainer = () => {
  const { hasSurveyStarted, hasSurveyFinished, activeSurveyBoxesIDs } =
    useSurveyContext()

  const { t } = useTranslation()

  const variants = {
    visible: { opacity: 1 },
    hidden: {
      opacity: 0,
      transitionEnd: {
        display: 'none'
      }
    }
  }

  const hasAccessToSurveyWithoutCommunicationQuestion = useFeatureFlags(
    'SURVEY_WITHOUT_COMMUNICATION_QUESTION'
  )

  const getShapePositionAfterSurveyStart = ids => {
    if (hasAccessToSurveyWithoutCommunicationQuestion) {
      if (ids.includes('direct-sales')) {
        return { top: '515px', left: '270px' }
      }

      if (ids.includes('instructions-for-marketing-stock')) {
        return { top: '510px', left: '205px' }
      }
    }

    return null
  }

  /**
   * maps surveyBoxes object to jsx elements
   * @return {array} array of SurveyBox components to render
   */
  const renderSurveyBoxes = () =>
    surveyBoxes.map(
      (
        {
          ids,
          textIDs,
          shapePositionBeforeSurveyStart,
          shapePositionAfterSurveyStart,
          shapeImageSrc,
          hasFadeIn
        },
        index
      ) => {
        if (
          hasAccessToSurveyWithoutCommunicationQuestion &&
          (ids.includes('newsletter') || ids.includes('product-updates'))
        ) {
          return null
        }

        const positionAfterSurveyStart =
          getShapePositionAfterSurveyStart(ids) || shapePositionAfterSurveyStart

        const id = ids.find(id => activeSurveyBoxesIDs.includes(id)) || ids[0]

        return (
          <SurveyBox
            hasSurveyStarted={hasSurveyStarted}
            hasSurveyFinished={hasSurveyFinished}
            shapePositionAfterSurveyFinish={
              shapePositionsByOrderAfterSurveyFinish[
                activeSurveyBoxesIDs.indexOf(id)
              ]
            }
            shapePositionBeforeSurveyStart={shapePositionBeforeSurveyStart}
            shapePositionAfterSurveyStart={positionAfterSurveyStart}
            textID={textIDs[id]}
            shapeImageSrc={shapeImageSrc}
            isActive={activeSurveyBoxesIDs.includes(id)}
            key={index}
            order={activeSurveyBoxesIDs.indexOf(id)}
            hasFadeIn={hasFadeIn}
          />
        )
      }
    )

  const wrapperStyles = useStyles({
    [styles['shapes-container']]: true,
    [styles['shapes-container--survey-started']]: hasSurveyStarted
  })

  return (
    <motion.div
      className={wrapperStyles}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        variants={variants}
        animate={hasSurveyStarted ? 'hidden' : 'visible'}
      >
        <Image
          className={styles['shapes-container__square-orange-2-1']}
          src={SquareOrangeImage}
        />
      </motion.div>

      <AnimatePresence>
        {hasSurveyStarted && (
          <div className={styles['shapes-container__header']}>
            <Column className={styles.header__content}>
              <Paragraph size={16} color='accent-3' align='center' line={24}>
                {t('registration.flow.survey.select.your.answers')}
              </Paragraph>
              <Heading level={3} bold align='center'>
                {t('registration.flow.survey.here.is.what.you.will.get')}
              </Heading>
            </Column>
          </div>
        )}
      </AnimatePresence>

      <div className={styles['shapes-container__survey-boxes-container']}>
        {renderSurveyBoxes()}
      </div>

      <motion.div>
        <motion.div
          variants={variants}
          animate={hasSurveyStarted ? 'hidden' : 'visible'}
        >
          <Image
            className={styles['shapes-container__half-circle-green-1-2']}
            src={HalfCircleGreenImage}
          />
        </motion.div>

        <motion.div
          variants={variants}
          animate={hasSurveyStarted ? 'hidden' : 'visible'}
        >
          <Image
            className={styles['shapes-container__triangle-blue']}
            src={TriangleBlueImage}
          />
        </motion.div>
      </motion.div>
    </motion.div>
  )
}

ShapesContainer.displayName = 'Shapes Container'

export default memo(ShapesContainer)
