import { NEW_LANDINGI_URL } from '@config/env'
import { BREAKPOINT } from '@constants/breakpoints'
import { discount12MForPlan } from '@constants/promotions'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useUserContext } from '@contexts/user'
import {
  getHas12MonthSaleDiscount,
  getIsPromotion12MonthUsed
} from '@helpers/payment'
import { promotionBlackFridayLinks } from '@helpers/promotions'
import { useBreakpoint } from '@hooks/useBreakpoint'
import { useStyles } from '@hooks/useStyles'
import { useSubscriptionInfoParams } from '@hooks/useSubscriptionInfoParams'
import { ACCOUNT } from '@routes/path'
import { Paragraph, Spreader } from '@ui-kit'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Column, Row } from 'simple-flexbox'

import { getVariantName } from './bannerVariant'
import styles from './BlackFriday.module.scss'

export const BlackFridayPromoBanner = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const {
    billingPeriod,
    subscriptionStatus,
    hasFreeTrial,
    isTypeClient,
    discounts,
    isLoading,
    planRecordKey,
    expiresAt,
    accountCurrency
  } = useUserContext()

  const discountValue =
    discount12MForPlan?.[planRecordKey as keyof typeof discount12MForPlan]?.[
      accountCurrency
    ] ?? 0

  const hasDiscount = getHas12MonthSaleDiscount(discounts)
  const usedDiscount = getIsPromotion12MonthUsed(discounts)

  const hasFreePlanRestrictions = useFeatureFlags('FREE_PLAN_RESTRICTIONS')

  const params = useSubscriptionInfoParams(hasDiscount, 'app-top-bar')

  const variantName = getVariantName({
    subscriptionStatus,
    hasFreeTrial,
    hasFreePlanRestrictions,
    billingPeriod,
    hasDiscount,
    usedDiscount,
    planRecordKey
  })

  const isVisible =
    useBreakpoint(BREAKPOINT[1130], 'min') &&
    !isTypeClient &&
    !usedDiscount &&
    !isLoading &&
    !!expiresAt?.formatted

  const titles = {
    'discount-applied': (
      <Trans i18nKey='black.friday.sale.banner.title.discount.applied' />
    ),
    'see-details': (
      <Trans i18nKey='black.friday.sale.banner.title.annual.plans' />
    ),
    'upgrade-plan': (
      <Trans i18nKey='black.friday.sale.banner.title.annual.plans' />
    ),
    'pay-now': (
      <Trans i18nKey='black.friday.sale.banner.title.discount.is.yours' />
    )
  }

  const contents = {
    'discount-applied': (
      <Paragraph size={12} color='white'>
        <span className={styles['box__content--badge']}>
          {discountValue} {accountCurrency}
        </span>

        <Spreader spread={10} />

        {t('black.friday.sale.banner.description.discount.applied')}
      </Paragraph>
    ),
    'see-details': (
      <Paragraph size={10} color='white'>
        <Trans
          i18nKey='black.friday.sale.banner.description.gifts'
          components={{
            br: <br />
          }}
        />
      </Paragraph>
    ),
    'upgrade-plan': (
      <Paragraph size={10} color='white'>
        <Trans
          i18nKey='black.friday.sale.banner.description.gifts'
          components={{
            br: <br />
          }}
        />
      </Paragraph>
    ),
    'pay-now': null
  }

  const links = {
    'discount-applied': null,
    'see-details': (
      <Paragraph
        className={styles['box__content--link']}
        size={10}
        color='warning'
      >
        {t('black.friday.sale.banner.link.see.details')}
      </Paragraph>
    ),
    'upgrade-plan': (
      <Paragraph
        className={styles['box__content--link']}
        size={10}
        color='warning'
      >
        {t('black.friday.sale.banner.link.upgrade.now')}
      </Paragraph>
    ),
    'pay-now': (
      <Paragraph
        className={styles['box__content--link']}
        size={12}
        color='white'
      >
        {t('black.friday.sale.banner.link.pay.now')}
      </Paragraph>
    )
  }

  const title = titles[variantName]

  const content = contents[variantName]

  const link = links[variantName]

  const handleOpenSeeMore = () =>
    window.open(
      `${t(promotionBlackFridayLinks(planRecordKey))}${params}`,
      '_blank'
    )

  const handleOpenUpgradePlan = () => {
    navigate(ACCOUNT.ACCOUNT_PACKAGE)
  }

  const handleOpenPayNow = () =>
    window.open(`https://${NEW_LANDINGI_URL}/payments`, '_self')

  const CTAs = {
    'discount-applied': () => null,
    'see-details': handleOpenSeeMore,
    'upgrade-plan': handleOpenUpgradePlan,
    'pay-now': handleOpenPayNow
  }

  const boxStyles = useStyles({
    [styles.box]: true,
    [styles['box--pointer']]: [
      'see-details',
      'upgrade-plan',
      'pay-now'
    ].includes(variantName)
  })

  return isVisible ? (
    <div className={boxStyles} onClick={CTAs[variantName]}>
      <Row className={styles.box__content} vertical='center'>
        <Column className={styles['box__content--column']}>
          <Paragraph color='white' size={16}>
            {title}
          </Paragraph>

          <Row vertical='end' horizontal='space-between'>
            {content} {link}
          </Row>
        </Column>
      </Row>
    </div>
  ) : null
}

BlackFridayPromoBanner.displayName = 'BlackFridayPromoBanner'
