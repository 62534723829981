import { BREAKPOINT } from '@constants/breakpoints'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useUserContext } from '@contexts/user'
import { useBreakpoint } from '@hooks/useBreakpoint'
import { PROMOTION_3M_END_DATE } from '@hooks/useSale'
import { CustomLink } from '@landingi/landingi-ui-kit'
import { ACCOUNT } from '@routes/path'
import {
  Badge,
  CountdownTimer,
  Paragraph,
  Spreader,
  useCountdown
} from '@ui-kit'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Column, Row } from 'simple-flexbox'

import styles from './Promo3MonthsBanner.module.scss'

const ThreeMonthsSale = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { isTypeClient } = useUserContext()

  const countDownArgs = { endDate: PROMOTION_3M_END_DATE }

  const { isFinished } = useCountdown(countDownArgs)

  const PROMO_BANNER_FF = useFeatureFlags('PROMO_BANNER')

  const isVisible =
    useBreakpoint(BREAKPOINT[1130], 'min') &&
    PROMO_BANNER_FF &&
    !isTypeClient &&
    !isFinished

  const handleNavigate = () => navigate(ACCOUNT.ACCOUNT_PACKAGE)

  return isVisible ? (
    <Row
      className={styles.promo3m__container}
      vertical='center'
      horizontal='center'
      onClick={handleNavigate}
    >
      <CountdownTimer {...countDownArgs} />

      <Column>
        <Paragraph size={12}>
          {t('topbar.promo-banner.3months.title')}
        </Paragraph>

        <Row vertical='baseline'>
          <Badge variant='warning-filled' weight={600} isTextUppercase={false}>
            {t('topbar.promo-banner.3months.badge')}
          </Badge>

          <Spreader spread={5} />

          <CustomLink
            label={t('topbar.promo-banner.3months.see.more')}
            size={12}
            underlined
            bold
          />
        </Row>
      </Column>
    </Row>
  ) : null
}

ThreeMonthsSale.displayName = 'ThreeMonthsSale'

export default ThreeMonthsSale
