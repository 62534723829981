import { LongLogo } from '@components/Logo'
import { useSidebarContext } from '@contexts/sidebar'
import { useUserContext } from '@contexts/user'
import { getLocalStorage, setLocalStorage } from '@helpers/storage'
import { useStyles } from '@hooks/useStyles'
import LANDINGS from '@routes/path/landings'
import { Button, Image } from '@ui-kit'
import { motion } from 'framer-motion'
import { FC, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { Row } from 'simple-flexbox'

import styles from './Header.module.scss'

interface HeaderProps {
  isSidebarMini: boolean
  isToggleButtonVisible?: boolean
}

export const Header: FC<HeaderProps> = ({
  isSidebarMini,
  isToggleButtonVisible
}) => {
  const { userLogo } = useUserContext()

  const { isSidebarMobileOpen, toggleSidebarMobileOpen } = useSidebarContext()

  const hasAnimation = getLocalStorage('disableLogoAnimation') !== 'true'

  const logoVariants = {
    mini: { maxWidth: 30, x: 0 },
    normal: { maxWidth: 175, x: 0 }
  }

  const headerStyles = useStyles({
    [styles.header]: true,
    [styles.header__toggle]: isToggleButtonVisible && isSidebarMobileOpen,
    [styles['header__toggle-hidden']]:
      isToggleButtonVisible && !isSidebarMobileOpen
  })

  useEffect(() => {
    if (hasAnimation) {
      setLocalStorage('disableLogoAnimation', true)
    }
  }, [hasAnimation])

  const animateAndInitial = isSidebarMini ? 'mini' : 'normal'

  const ToggleButton = (
    <Row flex='1' horizontal='end'>
      <Button
        icon='icon-remove'
        variant='text-neutral'
        onClick={toggleSidebarMobileOpen}
        data-testid='sidebar-toggle-button'
      />
    </Row>
  )

  const Logo = (
    <NavLink to={LANDINGS.LANDINGS}>
      <motion.div
        className={styles.header__logo}
        variants={logoVariants}
        initial={animateAndInitial}
        animate={animateAndInitial}
        transition={{ duration: 0.5, ease: [0.65, 0, 0.3, 1] }}
        style={{ width: '100%', overflow: 'hidden' }}
      >
        {userLogo === 'landingi' ? (
          <LongLogo hasAnimation={hasAnimation} />
        ) : (
          <Image className={styles.header__logo} src={userLogo || ''} />
        )}
      </motion.div>
    </NavLink>
  )

  return (
    <div className={headerStyles}>
      {isToggleButtonVisible ? ToggleButton : Logo}
    </div>
  )
}

Header.displayName = 'SidebarHeader'
