import { BREAKPOINT } from '@constants/breakpoints'
import { COMPANY, INDIVIDUAL } from '@constants/customerTypes'
import { postCodeMask } from '@helpers/masks'
import { isEuropeanUnionCountry } from '@helpers/payment'
import { useBreakpoint } from '@hooks/useBreakpoint'
import {
  BoxOutline,
  FieldGroup,
  Heading,
  InputForm,
  MaskedInputForm,
  PerfectDropdownSelect,
  Radio,
  Spacer,
  Spreader
} from '@landingi/landingi-ui-kit'
import { useCreditCardStepContext } from '@pages/Authentication/contexts/creditCardStep'
import styles from '@pages/Authentication/routes/CreditCardStep/CreditCardStep.module.scss'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

const BillingInformation = () => {
  const {
    formik: {
      values,
      errors,
      touched,
      setFieldValue,
      setFieldTouched,
      handleChange,
      handleBlur
    },
    is_european_union,
    customerTypeSelectOptions,
    mappedCountries
  } = useCreditCardStepContext()

  const isLowerThan720 = useBreakpoint(BREAKPOINT[720], 'max')

  const renderCountryCode = code =>
    isEuropeanUnionCountry(code) && code !== 'PL' && code

  /**
   * returns label for vat input depending on country code
   * @param {string} country - country code
   */
  const getVATLabel = country =>
    country === 'PL'
      ? 'registration.flow.credit.card.step.vat.pl'
      : 'registration.flow.credit.card.step.vat'

  /**
   * returns tooltip for vat input depending on country code
   * @param {string} country - country code
   */
  const getVATTooltip = country =>
    country === 'PL'
      ? 'creditcardstep.form.vat.tooltip.pl'
      : 'creditcardstep.form.vat.tooltip'

  const { t } = useTranslation()

  const {
    customerType,
    country,
    companyName,
    vat,
    nameSurname,
    address,
    city,
    postCode
  } = values

  return (
    <BoxOutline variant='background' disableHover padding='large'>
      <div className={styles['billing-information__content']}>
        <Heading level={3} bold>
          {t('registration.flow.credit.card.step.billing.informations')}
        </Heading>
        <FieldGroup id='method' name='method'>
          <Row className={styles['billing-information__customer-type-radios']}>
            {customerTypeSelectOptions.map(({ label, value }) => (
              <Radio
                field={{
                  name: 'customerType',
                  value: customerType,
                  onChange: handleChange
                }}
                label={label}
                color='color-1'
                size='small'
                id={value}
                form={{ errors }}
                key={value}
              />
            ))}
          </Row>
        </FieldGroup>

        <Spacer />

        <PerfectDropdownSelect
          formikKey='country.value'
          hasSearcher
          i18n={{ placeholder: t('creditcardstep.form.country') }}
          onChange={setFieldValue}
          onBlur={setFieldTouched}
          options={mappedCountries}
          value={country.value}
          error={errors}
          touched={touched}
          overflowStyle={{ maxHeight: '200px' }}
          label={t('creditcardstep.form.country')}
          liveChanges
          dropdownPlacement={isLowerThan720 ? 'top-center' : 'bottom-center'}
        />

        <Spacer space='small' />

        {customerType === COMPANY && (
          <div className={styles['company-inputs']}>
            <div className={styles['company-name']}>
              <InputForm
                className={styles['cc-input']}
                field={{
                  name: 'companyName',
                  value: companyName,
                  onChange: handleChange,
                  onBlur: handleBlur
                }}
                id='companyName'
                form={{
                  errors,
                  touched
                }}
                i18n={{
                  placeholder: t('creditcardstep.form.companyname'),
                  label: t('creditcardstep.form.companyname')
                }}
                allowHotjar
              />
            </div>

            {is_european_union && (
              <div className={styles.vat}>
                <Row flex='1'>
                  {values?.country?.value !== 'PL' ? (
                    <Fragment>
                      <Column
                        style={{ minWidth: 25, height: 39 }}
                        justifyContent='center'
                      >
                        {renderCountryCode(country.value)}
                      </Column>
                      <Spreader spread='tiny' />
                    </Fragment>
                  ) : null}

                  <Column flex='1' style={{ width: '80%' }}>
                    <InputForm
                      className={styles['cc-input']}
                      field={{
                        name: 'vat',
                        value: vat,
                        onChange: handleChange,
                        onBlur: handleBlur
                      }}
                      id='vat'
                      form={{
                        errors,
                        touched
                      }}
                      i18n={{
                        placeholder: t(getVATLabel(country.value)),
                        label: t(getVATLabel(country.value))
                      }}
                      tooltip={t(getVATTooltip(country.value))}
                      required={values?.country?.value === 'PL'}
                      allowHotjar
                    />
                  </Column>
                </Row>
              </div>
            )}
          </div>
        )}
        {customerType === INDIVIDUAL && (
          <InputForm
            className={styles['cc-input']}
            field={{
              name: 'nameSurname',
              value: nameSurname,
              onChange: handleChange,
              onBlur: handleBlur
            }}
            id='nameSurname'
            form={{
              errors,
              touched
            }}
            i18n={{
              placeholder: t('creditcardstep.form.namesurname'),
              label: t('creditcardstep.form.namesurname')
            }}
            allowHotjar
          />
        )}

        <Spacer space='small' />

        <InputForm
          className={styles['cc-input']}
          field={{
            name: 'address',
            value: address,
            onChange: handleChange,
            onBlur: handleBlur
          }}
          id='address'
          form={{
            errors,
            touched
          }}
          i18n={{
            placeholder: t('creditcardstep.form.address'),
            label: t('creditcardstep.form.address')
          }}
          allowHotjar
        />

        <Spacer space='small' />

        <div className={styles['city-and-postcode-inputs']}>
          <div className={styles['post-code']}>
            {values?.country?.value === 'PL' ? (
              <MaskedInputForm
                className={styles['cc-input']}
                id='postCode'
                field={{
                  name: 'postCode',
                  value: postCode,
                  onChange: handleChange,
                  onBlur: handleBlur
                }}
                form={{
                  errors,
                  touched
                }}
                mask={postCodeMask}
                type='text'
                i18n={{
                  placeholder: t('creditcardstep.form.postcode.placeholder'),
                  label: t('creditcardstep.form.postcode')
                }}
                focused='true'
              />
            ) : (
              <InputForm
                className={styles['cc-input']}
                field={{
                  name: 'postCode',
                  value: postCode,
                  onChange: handleChange,
                  onBlur: handleBlur
                }}
                id='postCode'
                form={{
                  errors,
                  touched
                }}
                i18n={{
                  placeholder: t('creditcardstep.form.postcode'),
                  label: t('creditcardstep.form.postcode')
                }}
                allowHotjar
              />
            )}
          </div>

          <div className={styles.city}>
            <InputForm
              className={styles['cc-input']}
              field={{
                name: 'city',
                value: city,
                onChange: handleChange,
                onBlur: handleBlur
              }}
              id='city'
              form={{
                errors,
                touched
              }}
              i18n={{
                placeholder: t('creditcardstep.form.city'),
                label: t('creditcardstep.form.city')
              }}
              allowHotjar
            />
          </div>
        </div>

        <Spacer space='mini' />

        <Spacer space='tiny' />
      </div>
    </BoxOutline>
  )
}

BillingInformation.displayName = 'BillingInformation'

export default BillingInformation
