import { Heading, Paragraph, Spacer } from '@landingi/landingi-ui-kit'
import { useWhitelabelPageContext } from '@pages/Authentication/Layouts/WhitelabelPage/Context'
import { REGISTRATION } from '@routes/path'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { Column } from 'simple-flexbox'

import ResetPasswordForm from './Form'
import styles from './Layout.module.scss'

const ResetPassword = () => {
  const { t } = useTranslation()
  const { clientName } = useParams()
  const { getWhitelabelPageConfig } = useWhitelabelPageContext()
  const { mainColor } = getWhitelabelPageConfig()

  return (
    <Column className={styles['register-cta']}>
      <Heading level={1} bold align='center'>
        {t('registration.flow.reset.your.password')}
      </Heading>

      {t('registration.flow.enter.your.account.email')}

      <Spacer space='medium' />

      <ResetPasswordForm />

      <Spacer space='mini' />

      <Spacer space='tiny' />

      <Paragraph size={12} color='accent-2' align='center' padding='none'>
        <Link
          to={`${REGISTRATION.LOGIN.DEFAULT}/${clientName}`}
          style={{ color: mainColor }}
        >
          <Trans i18nKey='registration.flow.go.back.to.sign.in' />
        </Link>
      </Paragraph>
    </Column>
  )
}

ResetPassword.displayName = 'Reset Password'

export default ResetPassword
