import { Acl } from '@components/Acl'
import { lazy } from '@helpers/lazy'
import CleanLayout from '@layouts/Clean'
import { FullLayout } from '@layouts/FullLayout'
import { SMART_SECTIONS } from '@routes/path'

import { CreateSmartSection } from './SmartSections/Create'

const SmartSectionsPage = lazy(() =>
  import('@pages/SmartSections/routes/SmartSections').then(
    ({ SmartSectionsPage }) => ({
      default: SmartSectionsPage
    })
  )
)

export const routes = [
  {
    path: SMART_SECTIONS.SMART_SECTIONS,
    element: (
      <Acl acl='landing_pages.smart_section.view_list'>
        <FullLayout />
      </Acl>
    ),
    children: [
      {
        path: SMART_SECTIONS.SMART_SECTIONS,
        element: <SmartSectionsPage />
      }
    ]
  },
  {
    path: SMART_SECTIONS.SMART_SECTIONS_GROUP,
    element: (
      <Acl acl='landing_pages.smart_section.view_list'>
        <FullLayout />
      </Acl>
    ),
    children: [
      {
        path: SMART_SECTIONS.SMART_SECTIONS_GROUP,
        element: <SmartSectionsPage />
      }
    ]
  },
  {
    path: SMART_SECTIONS.SMART_SECTIONS_CREATE,
    element: (
      <Acl acl='landing_pages.smart_section.create_smart_section'>
        <CleanLayout />
      </Acl>
    ),
    children: [
      {
        path: SMART_SECTIONS.SMART_SECTIONS_CREATE,
        element: <CreateSmartSection />
      }
    ]
  }
]
