import { Button } from '@ui-kit/Button'
import { Dropdown } from '@ui-kit/Dropdown'
import { DropdownElement } from '@ui-kit/DropdownList'
import { Icon } from '@ui-kit/Icon'
import { Spreader } from '@ui-kit/Spreader'
import { Fragment, useState } from 'react'

import type { Pagination as PaginationType } from './types'

export const PageSize = ({
  handlePageSizeChange,
  pageSize
}: Pick<PaginationType, 'handlePageSizeChange' | 'pageSize'>) => {
  const [value, setValue] = useState(pageSize)

  const trigger = ({ isOpen }: { isOpen: boolean }) => (
    <Button variant='text-neutral' size={12}>
      {value}

      <Spreader spread={5} />

      <Icon icon={isOpen ? 'icon-caret-up' : 'icon-caret-down'} size={12} />
    </Button>
  )

  return (
    <Dropdown size='auto' trigger={trigger} data-testid='table-page-size'>
      {({ close }) => {
        const handleChange = (size: number) => {
          setValue(size)
          handlePageSizeChange?.(size)
          close()
        }
        return (
          <Fragment>
            <DropdownElement
              data-testid='page-size-option-10'
              onClick={() => handleChange(10)}
            >
              10
            </DropdownElement>

            <DropdownElement
              data-testid='page-size-option-25'
              onClick={() => handleChange(25)}
            >
              25
            </DropdownElement>

            <DropdownElement
              data-testid='page-size-option-50'
              onClick={() => handleChange(50)}
            >
              50
            </DropdownElement>

            <DropdownElement
              data-testid='page-size-option-100'
              onClick={() => handleChange(100)}
            >
              100
            </DropdownElement>
          </Fragment>
        )
      }}
    </Dropdown>
  )
}
