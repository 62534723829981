import { getLocalStorage } from '@helpers/storage'
import FigmaPage from '@images/landings/wizard/figma.png'
import { LANDINGS } from '@routes/path'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ChooseMethodBoxOutline } from '../components/CreateLandingBoxOutline'
import { ImportButton } from '../ImportButton'
import { HIDE_CHECKBOX_LOCAL_STORAGE_KEY } from './data'

export const FigmaBox = () => {
  const navigate = useNavigate()

  const { t } = useTranslation()

  const handleFigmaInstructionClick = () => {
    navigate(LANDINGS.LANDINGS_CREATE_FIGMA)
  }

  const shouldShowAlwaysInstruction =
    getLocalStorage(HIDE_CHECKBOX_LOCAL_STORAGE_KEY) === null

  const Box = (
    <ChooseMethodBoxOutline
      imageSrc={FigmaPage}
      heading={<Trans i18nKey='create.landing.figma.box.heading' />}
      paragraph={t('create.landing.figma.box.description')}
      buttonContent={t('create.landing.figma.box.instruction.cta')}
      onClick={handleFigmaInstructionClick}
      secondaryButtonContent={t('create.landing.figma.box.instruction.button')}
      secondaryOnClick={handleFigmaInstructionClick}
    />
  )

  return shouldShowAlwaysInstruction ? Box : <ImportButton>{Box}</ImportButton>
}
