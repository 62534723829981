import { Heading, Panel, Paragraph, Spacer } from '@landingi/landingi-ui-kit'
import { REGISTRATION } from '@routes/path'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Column } from 'simple-flexbox'

import ResetPasswordForm from './Form'

const ResetPassword = () => {
  const { t } = useTranslation()

  return (
    <Column>
      <Panel borderRadius='10px'>
        <Heading level={1} bold>
          {t('registration.flow.reset.your.password')}
        </Heading>

        {t('registration.flow.enter.your.account.email')}

        <Spacer space='medium' />

        <ResetPasswordForm />
      </Panel>

      <Spacer space='mini' />

      <Spacer space='tiny' />

      <Paragraph size={12} color='accent-2' align='center'>
        <Link
          to={`${REGISTRATION.LOGIN_WEBTOLEARN}`}
          style={{ color: 'white' }}
        >
          <Trans i18nKey='registration.flow.go.back.to.sign.in' />
        </Link>
      </Paragraph>
    </Column>
  )
}

ResetPassword.displayName = 'Reset Password'

export default ResetPassword
