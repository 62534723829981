export const breakpoints = {
  'breakpoint-320': 320,
  'breakpoint-420': 420,
  'breakpoint-550': 550,
  'breakpoint-720': 720,
  'breakpoint-768': 768,
  'breakpoint-870': 870,
  'breakpoint-960': 960,
  'breakpoint-1024': 1024,
  'breakpoint-1070': 1070,
  'breakpoint-1130': 1130,
  'breakpoint-1200': 1200,
  'breakpoint-1350': 1350,
  'breakpoint-1920': 1920
} as const

export type Breakpoints = keyof typeof breakpoints
