import { useStyles } from '@hooks/useStyles'
import { StepNumber } from '@ui-kit/StepNumber'
import { FC } from 'react'
import { v4 } from 'uuid'

import styles from './Instruction.module.scss'

type InstructionStep = {
  content: JSX.Element | string
}

interface InstructionProps {
  data: InstructionStep[]
  className?: string | string[]
  'data-testid'?: string
}

export const Instruction: FC<InstructionProps> = ({
  className = '',
  'data-testid': dataTestId = 'instruction',
  data
}) => {
  const instructionStyle = useStyles({}, className)

  return (
    <div className={instructionStyle} data-testid={dataTestId}>
      {data.map(({ content }, index) => (
        <div className={styles.step} key={v4()}>
          <span className={styles.number}>
            <StepNumber step={index + 1} />
          </span>

          {content}
        </div>
      ))}
    </div>
  )
}

Instruction.displayName = 'Instruction'
