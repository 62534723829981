import { NEW_LANDINGI_URL } from '@config/env'
import { EMPTY_VALUE } from '@constants/index'
import { useUserContext } from '@contexts/user'
import { useChangeSubaccountToMain } from '@hooks/useChangeSubaccountToMain'
import { useSubaccounts } from '@services/subaccounts'
import { Button, Divider, Loader, Message, Search } from '@ui-kit'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

import styles from '../AccountDrawer.module.scss'
import { AgencyDrawerItem } from '../DrawerItem'

export const AgencyAccountContent = () => {
  const { t } = useTranslation()

  const [searchPhrase, setSearchPhrase] = useState(EMPTY_VALUE)

  const { data: accountsList, isLoading } = useSubaccounts()

  const { isTypeOwner, isTypeAdmin } = useUserContext()

  const searchedAccountsList =
    accountsList?.filter(
      ({ name }) => name?.toLowerCase().includes(searchPhrase) ?? ''
    ) ?? []

  const {
    changeToMainAccountIfNeeded,
    isTypeManager,
    areSubaccountsLoading,
    isRequestChangeUserAccountLoading
  } = useChangeSubaccountToMain()

  const hasAccessToManageSubaccounts =
    (isTypeOwner || isTypeAdmin) && !isTypeManager

  const handleManageSubaccounts = async () => {
    await changeToMainAccountIfNeeded()

    window.open(`https://${NEW_LANDINGI_URL}/agency/accounts`, '_self')
  }

  return (
    <Fragment>
      <div className={styles['account-drawer__searcher']}>
        <Search
          setSearchPhrase={setSearchPhrase}
          searchPhrase={searchPhrase}
          i18n={{ placeholder: t('word.search') }}
          liveChanges
        />
      </div>

      <Divider />

      <Column
        className={styles['account-drawer__agency']}
        data-testid='agency-account-content'
      >
        {isLoading ? <Loader /> : null}

        {!searchedAccountsList.length && searchPhrase ? (
          <Message
            type='not-found'
            sizes={{
              titleLevel: 4,
              imageHeight: 90
            }}
          />
        ) : null}

        {(searchPhrase ? searchedAccountsList : accountsList)?.map(
          ({ uuid, name, email, isActive, isMain, isDisabled }) => {
            const mainAccountEmail = accountsList?.find(
              account => account.isMain
            )?.email

            return (
              <AgencyDrawerItem
                key={uuid}
                uuid={uuid}
                name={name || t('word.main-account')}
                email={!isMain && email === mainAccountEmail ? '' : email}
                isActive={isActive}
                isMain={isMain}
                isDisabled={isDisabled}
              />
            )
          }
        )}
      </Column>

      {hasAccessToManageSubaccounts && (
        <Fragment>
          <Divider />

          <Row
            justifyContent='space-between'
            className={styles['account-drawer__bottom']}
          >
            <Button
              onClick={handleManageSubaccounts}
              disabled={isRequestChangeUserAccountLoading}
              isLoading={areSubaccountsLoading}
              data-testid='manage-agency-subaccounts'
            >
              {t('manage.subaccounts')}
            </Button>
          </Row>
        </Fragment>
      )}
    </Fragment>
  )
}

AgencyAccountContent.displayName = 'AgencyAccountContent'
