import { getTrimmedLabel } from '@helpers/getTrimmedLabel'
import { useStyles } from '@hooks/useStyles'
import Main from '@images/sidebar/icons/main.svg'
import { Image, Paragraph, Spreader } from '@ui-kit'
import { Column, Row } from 'simple-flexbox'

import styles from './DrawerItem.module.scss'

export interface DrawerItemProps {
  name: string
  email: string
}

export const BusinessDrawerItem = ({ name, email }: DrawerItemProps) => {
  const drawerItemStyles = useStyles({
    [styles['drawer-item']]: true
  })

  const {
    trimmedLabel: trimmedAccountName,
    TooltipWithFullLabel: TooltipWithFullLabelOfAccountName
  } = getTrimmedLabel({
    label: name,
    maxNumberOfCharacters: 30
  })

  const {
    trimmedLabel: trimmedAccountEmail,
    TooltipWithFullLabel: TooltipWithFullLabelOfAccountEmail
  } = getTrimmedLabel({
    label: email,
    maxNumberOfCharacters: 35
  })

  return (
    <Row className={drawerItemStyles} alignItems='center'>
      <Image src={Main} width='18px' height='18px' />

      <Spreader spread={10} />

      <Column>
        <TooltipWithFullLabelOfAccountName>
          <Paragraph weight={600} lineHeight='normal'>
            {trimmedAccountName}
          </Paragraph>
        </TooltipWithFullLabelOfAccountName>

        <TooltipWithFullLabelOfAccountEmail>
          <Paragraph size={12} color='neutral-6' lineHeight='normal'>
            {trimmedAccountEmail}
          </Paragraph>
        </TooltipWithFullLabelOfAccountEmail>
      </Column>
    </Row>
  )
}

BusinessDrawerItem.displayName = 'BusinessDrawerItem'
