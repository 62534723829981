import { useStyles } from '@hooks/useStyles'
import { FC, MouseEventHandler, ReactNode } from 'react'

import styles from './CustomLink.module.scss'

interface CustomLinkProps {
  className?: string | string[]
  children: ReactNode
  href?: string
  target?: string
  size?: 10 | 12 | 14 | 16 | 18
  weight?: 400 | 600
  variant?: 'primary' | 'neutral'
  hasUnderline?: boolean
  onClick?: MouseEventHandler<HTMLAnchorElement>
  isDisabled?: boolean
  'data-testid'?: string
}

export const CustomLink: FC<CustomLinkProps> = ({
  className = '',
  children,
  href,
  target = '_self',
  size = 14,
  weight = 400,
  variant = 'primary',
  hasUnderline = false,
  onClick = () => {},
  isDisabled = false,
  'data-testid': dataTestId = 'custom-link'
}) => {
  const customLinkStyles = useStyles(
    {
      [styles['custom-link']]: true,
      [styles[`custom-link--size-${size}`]]: size,
      [styles[`custom-link--weight-${weight}`]]: weight,
      [styles[`custom-link--variant-${variant}`]]: variant,
      [styles[`custom-link--variant-${variant}--disabled`]]: isDisabled,
      [styles[`custom-link--variant-${variant}--has-underline`]]: hasUnderline
    },
    className
  )

  return (
    <a
      className={customLinkStyles}
      href={href}
      target={target}
      onClick={onClick}
      data-testid={dataTestId}
    >
      {children}
    </a>
  )
}

CustomLink.displayName = 'CustomLink'
