import { ErrorBoundary } from '@components/ErrorBoundary'
import { ProductUpdates } from '@components/ProductUpdates'
import { Sidebar } from '@components/Sidebar'
import { Sidebar as SidebarNew } from '@components/SidebarNew'
import { Topbar } from '@components/Topbar'
import { Infobars } from '@components/Topbar/components/Infobars'
import { SupportMessage } from '@components/Topbar/components/SupportMessage'
import { Topbar as TopBarNew } from '@components/TopbarNew'
import { useFeatureFlags } from '@contexts/featureFlags'
import { SidebarProvider } from '@contexts/sidebar'
import { useStyles } from '@hooks/useStyles'
import { Loader } from '@landingi/landingi-ui-kit'
import { LANDINGS } from '@routes/path'
import { Fragment, Suspense } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import styles from './Full.module.scss'

export const FullLayout = () => {
  const location = useLocation()
  const isWelcomePage = location.pathname === LANDINGS.WELCOME

  const hasAccessToHideSidebarOnWelcomePage = useFeatureFlags(
    'HIDE_SIDEBAR_ON_WELCOME'
  )

  const hasAccessToNewTopbarAndSidebar = useFeatureFlags('NEW_TOPBAR_SIDEBAR')

  const contentWrapperStyles = useStyles({
    [styles['content-wrapper']]: true,
    [styles['content-wrapper__no-sidebar']]:
      isWelcomePage && hasAccessToHideSidebarOnWelcomePage
  })

  const shouldRenderSidebar =
    !hasAccessToHideSidebarOnWelcomePage || !isWelcomePage

  const renderFullLayoutElements = () => (
    <Fragment>
      <Infobars />

      <SidebarProvider>{shouldRenderSidebar && <Sidebar />}</SidebarProvider>

      <Topbar />

      <ProductUpdates />

      <div className={contentWrapperStyles}>
        <SupportMessage />

        <ErrorBoundary>
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </ErrorBoundary>
      </div>
    </Fragment>
  )

  const renderNewFullLayoutElements = () => (
    <div className={styles['full-layout']}>
      <div className={styles['full-layout__messages']}>
        <Infobars />

        <SupportMessage />
      </div>

      <div className={styles['full-layout__container']}>
        <SidebarProvider>
          {shouldRenderSidebar && (
            <div className={styles['full-layout__sidebar']}>
              <SidebarNew />
            </div>
          )}

          <TopBarNew />
        </SidebarProvider>

        <ProductUpdates />

        <div className={styles['full-layout__content']}>
          <div className='pinnable-top-bar' />

          <ErrorBoundary>
            <Suspense fallback={<Loader />}>
              <Outlet />
            </Suspense>
          </ErrorBoundary>
        </div>
      </div>
    </div>
  )

  return hasAccessToNewTopbarAndSidebar
    ? renderNewFullLayoutElements()
    : renderFullLayoutElements()
}

FullLayout.displayName = 'FullLayoutLayout'

export default FullLayout
