import { useToggle } from '@hooks/useToggle'
import { createContext, FC, ReactNode, useContext } from 'react'

interface SidebarContextProps {
  isSidebarMobileOpen: boolean
  toggleSidebarMobileOpen: () => void
  // TODO - 'workspaces' should be deleted after release of new sidebar
  isWorkspacesOpen: boolean
  toggleWorkspacesOpen: () => void
  isAccountDrawerOpen: boolean
  toggleAccountDrawerOpen: () => void
}

const SidebarContext = createContext<SidebarContextProps | null>(null)

const useSidebarState = () => {
  const [isSidebarMobileOpen, toggleSidebarMobileOpen] = useToggle(false)
  const [isWorkspacesOpen, toggleWorkspacesOpen] = useToggle(false)

  const [isAccountDrawerOpen, toggleAccountDrawerOpen] = useToggle(false)

  return {
    isSidebarMobileOpen,
    toggleSidebarMobileOpen,
    isWorkspacesOpen,
    toggleWorkspacesOpen,
    isAccountDrawerOpen,
    toggleAccountDrawerOpen
  }
}

type ProviderProps = {
  children: ReactNode
}

export const SidebarProvider: FC<ProviderProps> = ({ children }) => {
  const value = useSidebarState()

  return (
    <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
  )
}

export const useSidebarContext = (): SidebarContextProps => {
  const sidebar = useContext(SidebarContext)

  if (!sidebar) {
    throw new Error('useSidebarContext must be used inside SidebarProvider')
  }

  return sidebar
}
