import { useStyles } from '@hooks/useStyles'
import { Icon } from '@ui-kit/Icon'
import { Paragraph } from '@ui-kit/Paragraph'
import { FC } from 'react'

import styles from './Avatar.module.scss'

export interface AvatarProps {
  userId: string
  userName?: string
  isActive?: boolean
  isInteractive?: boolean
  className?: string | string[]
  'data-testid'?: string
}

const COLORS = [
  'almostcyan',
  'info',
  'warning',
  'warmpink',
  'success',
  'calmviolet'
]

export const Avatar: FC<AvatarProps> = ({
  userId,
  userName,
  isActive = false,
  isInteractive = true,
  className = '',
  'data-testid': dataTestId = 'avatar'
}) => {
  const getColorFromIdentifier = (userId: string): string => {
    if (!userId) return COLORS[0]

    const firstChar = userId[0].toLowerCase()

    const hexValue = Number.isNaN(Number(firstChar))
      ? firstChar.charCodeAt(0) - 87
      : Number(firstChar)

    return COLORS[hexValue % COLORS.length]
  }

  const avatarStyles = useStyles(
    {
      [styles.avatar]: true,
      [styles[`avatar--variant-${getColorFromIdentifier(userId)}`]]: true,
      [styles[`avatar--variant-${getColorFromIdentifier(userId)}__active`]]:
        isActive,
      [styles['avatar--non-interactive']]: !isInteractive
    },
    className
  )

  const getFirstSignOfUserName = () => {
    if (!userName)
      return (
        <Icon
          data-testid='avatar-icon'
          size={14}
          color='white'
          icon='icon-user'
        />
      )

    return (
      <Paragraph weight={600} color='white'>
        {userName.charAt(0).toUpperCase()}
      </Paragraph>
    )
  }

  return (
    <div className={avatarStyles} data-testid={dataTestId}>
      {getFirstSignOfUserName()}
    </div>
  )
}
