import { useStyles } from '@hooks/useStyles'
import { Button } from '@ui-kit/Button'
import { Heading } from '@ui-kit/Heading'
import { Image } from '@ui-kit/Image'
import { List } from '@ui-kit/List'
import { ListItem } from '@ui-kit/List/ListItem'
import { Spacer } from '@ui-kit/Spacer'
import { FC, Fragment, MouseEvent, ReactNode, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'

import styles from './InfoSection.module.scss'

interface InfoSectionProps {
  className?: string | string[]
  title: ReactNode
  list: string[]
  button?: string
  imageSrc: string
  onClick?: (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void
  'data-testid'?: string
}

export const InfoSection: FC<InfoSectionProps> = ({
  className = '',
  title,
  list,
  button,
  imageSrc,
  onClick,
  'data-testid': dataTestId = 'infoSection'
}) => {
  const infoSectionStyles = useStyles(
    { [styles['info-section']]: true },
    className
  )

  const renderList = useMemo(
    () => (
      <List type='checkmark'>
        {list.map(item => (
          <Fragment key={uuidv4()}>
            <ListItem size={18} description={item} />

            <Spacer space={15} />
          </Fragment>
        ))}
      </List>
    ),
    [list]
  )

  return (
    <div className={infoSectionStyles} data-testid={dataTestId}>
      <div>
        <Heading weight={600} level={1}>
          {title}
        </Heading>

        <Spacer space={25} />

        {renderList}

        <Spacer space={30} />

        {button && (
          <Button
            onClick={onClick}
            size={16}
            data-testid='infoSection-main-button'
          >
            {button}
          </Button>
        )}
      </div>

      <div>
        <Image src={imageSrc} width='100%' height='100%' />
      </div>
    </div>
  )
}

InfoSection.displayName = 'InfoSection'
