import { BR, EUR, GBP, PLN, USD } from '@constants/currencies'
import { useUserContext } from '@contexts/user'
import eventTracker30 from '@images/modals/event-tracker-30.png'
import eventTracker35 from '@images/modals/event-tracker-35.png'
import eventTracker43 from '@images/modals/event-tracker-43.png'

const otherPlansOldPrices = {
  [PLN]: 155,
  [USD]: 40,
  [EUR]: 40,
  [GBP]: 37,
  [BR]: 165
}

const agencyPlanOldPrices = {
  [PLN]: 310,
  [USD]: 82,
  [EUR]: 82,
  [GBP]: 75,
  [BR]: 330
}

const oldPrices = {
  lite: otherPlansOldPrices,
  create: otherPlansOldPrices,
  professional: otherPlansOldPrices,
  automate: otherPlansOldPrices,
  agency: agencyPlanOldPrices
}

const liteCreatePlansNewPrices = {
  [PLN]: 88,
  [USD]: 23,
  [EUR]: 23,
  [GBP]: 21,
  [BR]: 93
}

const professionalAutomatePlansNewPrices = {
  [PLN]: 99,
  [USD]: 26,
  [EUR]: 26,
  [GBP]: 24,
  [BR]: 105
}

const agencyNewPrices = {
  [PLN]: 214,
  [USD]: 57,
  [EUR]: 57,
  [GBP]: 51,
  [BR]: 228
}

const newPrices = {
  lite: liteCreatePlansNewPrices,
  create: liteCreatePlansNewPrices,
  professional: professionalAutomatePlansNewPrices,
  automate: professionalAutomatePlansNewPrices,
  agency: agencyNewPrices
}

const planValues = {
  lite: { percentage: 43, image: eventTracker43 },
  create: { percentage: 43, image: eventTracker43 },
  professional: { percentage: 35, image: eventTracker35 },
  automate: { percentage: 43, image: eventTracker43 },
  agency: { percentage: 30, image: eventTracker30 }
}

type PlanRecordKey = keyof typeof planValues

export const useGetValuesByPlan = () => {
  const { planRecordKey, accountCurrency } = useUserContext()

  const planName = planRecordKey?.split('_')[0] as PlanRecordKey

  return {
    percentage: planValues[planName]?.percentage,
    image: planValues[planName]?.image,
    oldPrice: oldPrices[planName][accountCurrency],
    newPrice: newPrices[planName][accountCurrency],
    currency: accountCurrency
  }
}
