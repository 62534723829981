import { PLANS } from '@constants/plans'
import { useCreditCardStepContext } from '@pages/Authentication/contexts/creditCardStep'
import styles from '@pages/Authentication/routes/ComparePlans/ComparePlans.module.scss'

import { Plan } from './components/Plan'
import { PlanRecordKey } from './types'

// TODO - in next PR plan unlimited will be replaced with plan enterprise_25
interface PlanType {
  recordKey: PlanRecordKey | 'enterprise_25'
  name: string
}

export const Plans = ({ billingPeriod }: { billingPeriod: number }) => {
  const { currentPlans } = useCreditCardStepContext() as {
    currentPlans: PlanType[]
  }

  return (
    <div className={styles.plans}>
      {currentPlans.map(plan => {
        if (plan.recordKey === PLANS.ENTERPRISE_25) {
          return null
        }

        const { recordKey, name } = plan

        return (
          <Plan
            key={recordKey}
            planName={name}
            planRecordKey={recordKey}
            billingPeriod={billingPeriod}
          />
        )
      })}
    </div>
  )
}
