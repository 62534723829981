import { BREAKPOINT } from '@constants/breakpoints'
import { useBreakpoint } from '@hooks/useBreakpoint'
import { Button } from '@ui-kit/Button'
import { Calendar } from '@ui-kit/Calendar'
import { MonthType } from '@ui-kit/Calendar/types'
import { Spreader } from '@ui-kit/Spreader'
import { FC, Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

import styles from './RangeDatePicker.module.scss'

interface RangeDatePickerProps {
  onChange: ({
    start,
    end
  }: {
    start: Date | undefined
    end: Date | undefined
  }) => void
  'data-testid'?: string
}

export const RangeDatePicker: FC<RangeDatePickerProps> = ({
  onChange,
  'data-testid': dataTestId = 'range-date-picker'
}) => {
  const { t } = useTranslation()

  const [firstDate, setFirstDate] = useState<Date | undefined>()
  const [tempSecondDate, setTempSecondDate] = useState<Date | undefined>()
  const [secondDate, setSecondDate] = useState<Date | undefined>()

  const isBiggerThan720 = useBreakpoint(BREAKPOINT[720], 'min')

  const fixInterval = (start?: Date, end?: Date) => {
    if (!start || !end) return { start, end }
    return start > end ? { start: end, end: start } : { start, end }
  }

  const handleClick = (date: Date) => {
    if (!firstDate) {
      setFirstDate(date)
    } else if (!secondDate) {
      setSecondDate(date)
    } else {
      setFirstDate(date)
      setSecondDate(undefined)
      setTempSecondDate(undefined)
    }
  }

  const handleHover = (date: Date) => {
    if (!firstDate) return
    if (secondDate) return

    setTempSecondDate(date)
  }

  const handleApply = () => onChange(fixInterval(firstDate, secondDate))

  return (
    <Column className={styles.wrapper}>
      <Row data-testid={dataTestId}>
        {isBiggerThan720 ? (
          <Fragment>
            <Calendar
              initialYear={
                (new Date().getMonth() + 11) % 12 === 11
                  ? new Date().getFullYear() - 1
                  : new Date().getFullYear()
              }
              initialMonth={((new Date().getMonth() + 11) % 12) as MonthType}
              handleClick={handleClick}
              handleHover={handleHover}
              activeRange={{
                start: firstDate,
                end: secondDate || tempSecondDate
              }}
            />

            <Spreader spread={10} />
          </Fragment>
        ) : null}

        <Calendar
          initialYear={new Date().getFullYear()}
          initialMonth={new Date().getMonth() as MonthType}
          handleClick={handleClick}
          handleHover={handleHover}
          activeRange={{
            start: firstDate,
            end: secondDate || tempSecondDate
          }}
        />
      </Row>

      <Row justifyContent='end'>
        <Button
          size={12}
          onClick={handleApply}
          disabled={!firstDate || !secondDate}
        >
          {t('word.apply')}
        </Button>
        <Spreader spread={10} />
      </Row>
    </Column>
  )
}

RangeDatePicker.displayName = 'RangeDatePicker'
