import { Acl } from '@components/Acl'
import { lazy } from '@helpers/lazy'
import CleanLayout from '@layouts/Clean'
import { FullLayout } from '@layouts/FullLayout'
import { LIGHTBOXES } from '@routes/path'

import { CreateLightbox } from './Lightboxes/Create'

const LigthboxesPage = lazy(() =>
  import('@pages/Lightboxes/routes/Lightboxes').then(({ LightboxesPage }) => ({
    default: LightboxesPage
  }))
)

export const routes = [
  {
    path: LIGHTBOXES.LIGHTBOXES,
    element: (
      <Acl acl='lightboxes.view_list'>
        <FullLayout />
      </Acl>
    ),
    children: [
      {
        path: LIGHTBOXES.LIGHTBOXES,
        element: <LigthboxesPage />
      }
    ]
  },
  {
    path: LIGHTBOXES.LIGHTBOXES_CREATE,
    element: (
      <Acl acl='lightboxes.lightbox.create_lightbox'>
        <CleanLayout />
      </Acl>
    ),
    children: [
      {
        path: LIGHTBOXES.LIGHTBOXES_CREATE,
        element: <CreateLightbox />
      }
    ]
  }
]
