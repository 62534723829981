import { useStyles } from '@hooks/useStyles'
import { Button } from '@ui-kit/Button'
import { Icon } from '@ui-kit/Icon'
import { Spreader } from '@ui-kit/Spreader'
import { Tooltip } from '@ui-kit/Tooltip'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './Back.module.scss'

export interface BackProps {
  onClick: () => void
  label?: string
  tooltipContent?: string
  size?: 12 | 14
  className?: string | string[]
  'data-testid'?: string
}

export const Back: FC<BackProps> = ({
  onClick,
  label,
  tooltipContent,
  size = 14,
  className = '',
  'data-testid': dataTestId = 'back'
}) => {
  const { t } = useTranslation()

  const backStyles = useStyles(
    {
      [styles.back]: true
    },
    className
  )

  return (
    <span className={backStyles} data-testid={dataTestId}>
      <Tooltip
        content={tooltipContent}
        disabled={!tooltipContent}
        placement='bottom'
      >
        {label ? (
          <Button variant='text-neutral' onClick={onClick} size={size}>
            <Icon icon='icon-arrow-left' />

            <Spreader spread={10} />

            {t(label)}
          </Button>
        ) : (
          <Button
            variant='text-neutral'
            onClick={onClick}
            size={size}
            icon='icon-arrow-left'
          />
        )}
      </Tooltip>
    </span>
  )
}
