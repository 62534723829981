import { NEW_LANDINGI_URL } from '@config/env'
import wordpress_instruction_1 from '@images/landings/publishingOptions/wordpress/wordpress_instruction_1.png'
import wordpress_instruction_1_semrush from '@images/landings/publishingOptions/wordpress/wordpress_instruction_1_semrush.png'
import wordpress_instruction_2 from '@images/landings/publishingOptions/wordpress/wordpress_instruction_2.png'
import wordpress_instruction_3 from '@images/landings/publishingOptions/wordpress/wordpress_instruction_3.png'
import wordpress_instruction_4 from '@images/landings/publishingOptions/wordpress/wordpress_instruction_4.png'
import wordpress_instruction_5 from '@images/landings/publishingOptions/wordpress/wordpress_instruction_5.png'
import wordpress_instruction_6 from '@images/landings/publishingOptions/wordpress/wordpress_instruction_6.png'
import wordpress_instruction_7 from '@images/landings/publishingOptions/wordpress/wordpress_instruction_7.png'
import wordpress_instruction_8 from '@images/landings/publishingOptions/wordpress/wordpress_instruction_8.png'
import wordpress_instruction_9 from '@images/landings/publishingOptions/wordpress/wordpress_instruction_9.png'
import { Image, Spacer } from '@landingi/landingi-ui-kit'
import { CustomLink } from '@ui-kit'
import { Fragment } from 'react'
import { Trans } from 'react-i18next'

const firstStep = {
  content: (
    <Fragment>
      <Trans
        i18nKey='publishing.options.wordpress.instruction.1'
        components={{
          a: (
            <CustomLink
              href={`https://${NEW_LANDINGI_URL}/account/tokens`}
              target='_blank'
              data-testid='wordpress-instruction-1-link'
            >
              LINK
            </CustomLink>
          )
        }}
      />

      <Spacer space='small' />

      <Image src={wordpress_instruction_1} size='100%' />
    </Fragment>
  )
}

const firstStepSemrush = {
  content: (
    <Fragment>
      <Trans i18nKey='publishing.options.wordpress.instruction.1.semrush' />

      <Spacer space='small' />

      <Image src={wordpress_instruction_1_semrush} size='100%' />
    </Fragment>
  )
}

const wordpressRestOfSteps = [
  {
    content: (
      <Fragment>
        <Trans
          i18nKey='publishing.options.wordpress.instruction.2'
          values={{
            b: (chunks: string) => <b>{chunks}</b>
          }}
        />

        <Spacer space='small' />

        <Image src={wordpress_instruction_2} size='100%' />
      </Fragment>
    )
  },
  {
    content: (
      <Fragment>
        <Trans
          i18nKey='publishing.options.wordpress.instruction.3'
          values={{
            b: (chunks: string) => <b>{chunks}</b>
          }}
        />

        <Spacer space='small' />

        <Image src={wordpress_instruction_3} size='100%' />
      </Fragment>
    )
  },
  {
    content: (
      <Fragment>
        <Trans
          i18nKey='publishing.options.wordpress.instruction.4'
          values={{
            b: (chunks: string) => <b>{chunks}</b>
          }}
        />

        <Spacer space='small' />

        <Image src={wordpress_instruction_4} size='100%' />
      </Fragment>
    )
  },
  {
    content: (
      <Fragment>
        <Trans
          i18nKey='publishing.options.wordpress.instruction.5'
          values={{
            b: (chunks: string) => <b>{chunks}</b>
          }}
        />

        <Spacer space='small' />

        <Image src={wordpress_instruction_5} size='100%' />
      </Fragment>
    )
  },
  {
    content: (
      <Fragment>
        <Trans
          i18nKey='publishing.options.wordpress.instruction.6'
          values={{
            b: (chunks: string) => <b>{chunks}</b>
          }}
        />

        <Spacer space='small' />

        <Image src={wordpress_instruction_6} size='100%' />
      </Fragment>
    )
  },
  {
    content: (
      <Fragment>
        <Trans
          i18nKey='publishing.options.wordpress.instruction.7'
          values={{
            b: (chunks: string) => <b>{chunks}</b>
          }}
        />

        <Spacer space='small' />

        <Image src={wordpress_instruction_7} size='100%' />
      </Fragment>
    )
  },
  {
    content: (
      <Fragment>
        <Trans
          i18nKey='publishing.options.wordpress.instruction.8'
          values={{
            b: (chunks: string) => <b>{chunks}</b>
          }}
        />

        <Spacer space='small' />

        <Image src={wordpress_instruction_8} size='100%' />
      </Fragment>
    )
  },
  {
    content: (
      <Fragment>
        <Trans
          i18nKey='publishing.options.wordpress.instruction.9'
          values={{
            b: (chunks: string) => <b>{chunks}</b>
          }}
        />

        <Spacer space='small' />

        <Image src={wordpress_instruction_9} size='100%' />
      </Fragment>
    )
  },
  {
    content: <Trans i18nKey='publishing.options.wordpress.instruction.10' />
  }
]

export const wordpressSteps = [firstStep, ...wordpressRestOfSteps]

export const wordpressStepsSemrush = [firstStepSemrush, ...wordpressRestOfSteps]
