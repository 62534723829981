import {
  TABS,
  useCheckAccessToTab
} from '@components/SidebarNew/hooks/useCheckAccessToTab'
import { useLogout } from '@hooks/useLogout'
import { MY_PROFILE } from '@routes/path'
import { Divider, SidebarItem, Spacer } from '@ui-kit'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ProductUpdates } from './components/ProductUpdates'
import { SidebarAvatar } from './components/SidebarAvatar'
import styles from './MobileNavigationPart.module.scss'

interface MobileNavigationPartProps {
  isSidebarMini: boolean
  isBiggerThan720: boolean
}

export const MobileNavigationPart: FC<MobileNavigationPartProps> = ({
  isSidebarMini,
  isBiggerThan720
}) => {
  const { t } = useTranslation()

  const hasAccessToProcutUpdates = useCheckAccessToTab(TABS.PRODUCT_UPDATES)

  const handleLogoutAccount = useLogout()

  return isBiggerThan720 ? null : (
    <nav className={styles.navigation} data-testid='mobile-navigation'>
      {hasAccessToProcutUpdates ? (
        <ProductUpdates isSidebarMini={isSidebarMini} />
      ) : null}

      <Spacer space={15} />

      <Divider />

      <Spacer space={15} />

      <SidebarAvatar />

      <Spacer space={10} />

      <SidebarItem
        id='sidebar-my-profile'
        icon='icon-users-circle'
        size={12}
        name={t('topbar.my-profile')}
        pathname={MY_PROFILE.MY_PROFILE}
        isMini={isSidebarMini}
        hasCustomIcon
      />

      <span onClick={handleLogoutAccount}>
        <SidebarItem
          id='sidebar-logout'
          icon='icon-exit'
          size={12}
          name={t('topbar.logout')}
          pathname='#'
          isMini={isSidebarMini}
          hasCustomIcon
        />
      </span>
    </nav>
  )
}

MobileNavigationPart.displayName = 'MobileNavigationPart'
