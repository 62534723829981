import { useStyles } from '@hooks/useStyles'
import { Panel } from '@ui-kit/Panel'
import { FC, ReactNode } from 'react'

import styles from './DropdownList.module.scss'

export type DropdownListSize =
  | 'small'
  | 'medium'
  | 'big'
  | 'large'
  | 'auto'
  | 'full-width'

interface DropdownListProps {
  className?: string | string[]
  children: ReactNode
  size?: DropdownListSize
  overflowStyle?: {
    overflowX?: 'scroll' | 'auto'
    overflowY?: 'scroll' | 'auto'
    maxHeight?: string
  }
  'data-testid'?: string
}

export const DropdownList: FC<DropdownListProps> = ({
  className,
  children,
  size = 'auto',
  overflowStyle = {
    overflowX: 'auto',
    overflowY: 'auto',
    maxHeight: 'auto'
  },
  'data-testid': dataTestId = 'dropdown-list'
}) => {
  const dropdownListStyles = useStyles(
    {
      [styles['dropdown-list']]: true,
      [styles[`dropdown-list--size-${size}`]]: true
    },
    className
  )

  return (
    <Panel
      data-testid={dataTestId}
      className={dropdownListStyles}
      surface={2}
      padding={{ y: 15, x: 0 }}
    >
      <div style={{ ...overflowStyle }}>{children}</div>
    </Panel>
  )
}
