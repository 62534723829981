import { useStyles } from '@hooks/useStyles'
import { getColumnWidth } from '@ui-kit/Table/helpers'
import { Column, ItemBase } from '@ui-kit/Table/types'

import styles from './Heading.module.scss'

export const Heading = <Item extends ItemBase>({
  columns,
  hasFullBorder,
  height,
  visibility,
  customPaddings
}: {
  columns: Column<Item>[]
  hasFullBorder?: boolean
  height?: `${number}px`
  visibility?: 'hidden'
  customPaddings?: string | ((column?: Column<Item>) => string)
}) => {
  const headingStyles = useStyles({
    [styles.heading]: true,
    [styles['heading--full-border']]: hasFullBorder,
    [styles['heading--hidden']]: visibility === 'hidden'
  })

  return (
    <div
      className={headingStyles}
      data-testid='table-heading'
      style={{
        gridTemplateColumns: columns
          .map(column => getColumnWidth(column.width))
          .join(' '),
        height
      }}
    >
      {columns.map(column => (
        <div
          className={styles.cell}
          key={column.id}
          style={{
            width: getColumnWidth(column.width),
            padding:
              typeof customPaddings === 'function'
                ? customPaddings(column)
                : customPaddings
          }}
        >
          {column.name}
        </div>
      ))}
    </div>
  )
}
