import GoogleSignUp from '@components/GoogleButtons/GoogleSignUp'
import LinkedInButton from '@components/LinkedInButtons/LinkedInButton'
import { MicrosoftSignUpButton } from '@components/MicrosoftButtons/MicrosoftSignUp'

const buttons = {
  google: <GoogleSignUp />,
  microsoft: <MicrosoftSignUpButton />,
  linkedin: <LinkedInButton />
}

const getRegistrationButton = name => buttons[name]

export default getRegistrationButton
