import { waitForElementVisibleInDOM } from '@helpers/dom'
import { useCallback, useEffect, useRef, useState } from 'react'

/**
 * hook that returns true or false depending if element with position: sticky
 * sticked to the top of the page, offset can be modified by rootMargin.
 * @param selector
 * @param rootMargin
 */
export const useIsPinned = (selector: string, rootMargin?: string) => {
  const calledOnce = useRef(false)
  const observerRef = useRef<IntersectionObserver | null>(null)

  const [isPinned, setIsPinned] = useState(false)

  const observeElementForPinned = useCallback(async () => {
    const element =
      document.querySelector(selector) ||
      (await waitForElementVisibleInDOM(selector))

    if (observerRef.current) {
      observerRef.current.disconnect()
    }

    observerRef.current = new IntersectionObserver(
      ([e]) => setIsPinned(e.intersectionRatio < 1),
      { threshold: [1], rootMargin }
    )

    observerRef.current.observe(element)
  }, [selector, rootMargin])

  useEffect(() => {
    if (calledOnce.current) {
      observeElementForPinned()
    }

    calledOnce.current = true

    return () => {
      observerRef.current?.disconnect()
    }
  }, [observeElementForPinned])

  return isPinned
}
