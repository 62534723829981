import { MODAL_UPGRADE_PLAN } from '@components/Modals/ModalUpgradePlan'
import { NEW_LANDINGI_APP_URL } from '@constants/index'
import NiceModal from '@ebay/nice-modal-react'
import { getTrimmedLabel } from '@helpers/getTrimmedLabel'
import { ColorIcon } from '@ui-kit/ColorIcon'
import { IconTypes } from '@ui-kit/ColorIcon/icons'
import { Icon } from '@ui-kit/Icon'
import { ElementAnimation } from '@ui-kit/SidebarItem/components/ElementAnimation'
import { MODALS } from '@ui-kit/SidebarItem/constants'
import { useGetStatusInfo } from '@ui-kit/SidebarItem/hooks/useGetStatusInfo'
import { Spreader } from '@ui-kit/Spreader'
import { Text } from '@ui-kit/Text'
import { Tooltip } from '@ui-kit/Tooltip'
import { FC, Fragment, ReactNode } from 'react'
import { Trans } from 'react-i18next'

import styles from './OldItem.module.scss'

interface OldItemProps {
  id: string
  name: string
  icon: IconTypes
  pathname: string
  hasAccess?: boolean
  children?: ReactNode
  isMini?: boolean
}

export const OldItem: FC<OldItemProps> = ({
  id,
  icon,
  name,
  pathname,
  children,
  hasAccess = true,
  isMini
}) => {
  const { status, statusColor } = useGetStatusInfo(pathname)

  const { trimmedLabel, TooltipWithFullLabel } = getTrimmedLabel({
    label: name,
    maxNumberOfCharacters: 15
  })

  const handleOnClick = () => {
    if (!hasAccess) {
      return NiceModal.show(MODAL_UPGRADE_PLAN, {
        upgradeType: MODALS[pathname]
      })
    }

    return window.open(`${NEW_LANDINGI_APP_URL}${pathname}`, '_self')
  }

  return (
    <span
      id={id}
      className={styles['sidebar-item']}
      onClick={handleOnClick}
      data-testid='old-item'
    >
      <ElementAnimation>
        <Spreader spread={5} />

        <ColorIcon icon={icon} status={status} size={12} />

        <Spreader spread={5} />
      </ElementAnimation>

      {isMini ? null : (
        <Fragment>
          <span className={styles['sidebar-item__name']}>
            <ElementAnimation>
              <TooltipWithFullLabel>
                <Text
                  size={14}
                  color={statusColor}
                  weight={600}
                  lineHeight='normal'
                >
                  {trimmedLabel}
                </Text>
              </TooltipWithFullLabel>
            </ElementAnimation>
          </span>

          {children ? <ElementAnimation>{children}</ElementAnimation> : null}

          {hasAccess ? null : (
            <ElementAnimation>
              <Tooltip
                content={<Trans i18nKey='sidebar.extension.tooltip' />}
                placement='right'
              >
                <Icon icon='icon-extension' color='neutral-4' size={12} />
              </Tooltip>
            </ElementAnimation>
          )}
        </Fragment>
      )}
    </span>
  )
}
