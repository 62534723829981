import { useStyles } from '@hooks/useStyles'
import { FC } from 'react'

interface ImageProps {
  src: string
  alt?: string
  className?: string | string[]
  width?: `${number}px` | `${string}%` | 'auto'
  height?: `${number}px` | `${string}%` | 'auto'
  loadingAttr?: 'lazy' | 'eager'
  'data-testid'?: string
}

export const Image: FC<ImageProps> = ({
  src,
  alt = '',
  className = '',
  width,
  height,
  loadingAttr = 'eager',
  'data-testid': dataTestId = 'image'
}) => {
  const elementStyles: string = useStyles({}, className)

  return (
    <img
      alt={alt}
      className={elementStyles}
      height={height}
      src={src}
      width={width}
      loading={loadingAttr}
      data-testid={dataTestId}
    />
  )
}

Image.displayName = 'Image'
