export const BREAKPOINT = {
  320: 'breakpoint-320',
  420: 'breakpoint-420',
  550: 'breakpoint-550',
  720: 'breakpoint-720',
  768: 'breakpoint-768',
  870: 'breakpoint-870',
  960: 'breakpoint-960',
  1024: 'breakpoint-1024',
  1070: 'breakpoint-1070',
  1130: 'breakpoint-1130',
  1200: 'breakpoint-1200',
  1350: 'breakpoint-1350',
  1920: 'breakpoint-1920'
} as const
