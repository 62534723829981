import { UPGRADE_MODALS } from '@constants/modals'
import {
  LIGHTBOXES,
  POPUPS,
  PRODUCTS,
  PROGRAMMATIC,
  SMART_SECTIONS
} from '@routes/path'

export const STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive'
} as const

export type StatusTypes = (typeof STATUS)[keyof typeof STATUS]

export const MODALS = {
  [SMART_SECTIONS.SMART_SECTIONS]: UPGRADE_MODALS.SMART_SECTIONS,
  [POPUPS.POPUPS_OLD]: UPGRADE_MODALS.POPUPS,
  [LIGHTBOXES.LIGHTBOXES]: UPGRADE_MODALS.LIGHTBOXES,
  [POPUPS.POPUPS]: UPGRADE_MODALS.POPUPS,
  [PRODUCTS.PRODUCTS]: UPGRADE_MODALS.ECOMMERCE,
  [PROGRAMMATIC.PROGRAMMATIC]: UPGRADE_MODALS.PROGRAMMATIC
}
