import { useStyles } from '@hooks/useStyles'
import React, { FC, ReactNode } from 'react'

import styles from './List.module.scss'

interface ListProps {
  children: ReactNode
  type?: 'disc' | 'checkmark'
  className?: string | string[]
}

export const List: FC<ListProps> = ({
  children,
  type = 'disc',
  className = ''
}) => {
  const elementClasses = useStyles(
    {
      [styles.list]: true,
      [styles[`list--type-${type}`]]: type
    },
    className
  )

  return (
    <ul className={elementClasses}>
      {React.Children.toArray(children).filter(item => item)}
    </ul>
  )
}

List.displayName = 'List'
