import { BREAKPOINT } from '@constants/breakpoints'
import { useAcl } from '@contexts/acl'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useUserContext } from '@contexts/user'
import { useBreakpoint } from '@hooks/useBreakpoint'
import { BLACK_FRIDAY, PROMOTION_3M, useSale } from '@hooks/useSale'
import { Spreader } from '@landingi/landingi-ui-kit'
import { Fragment } from 'react'
import { Column, Row } from 'simple-flexbox'

import { Account } from './components/Account'
import { Back } from './components/Back'
import { Help } from './components/Help'
import { HireUs } from './components/HireUs'
import { MyUsage } from './components/MyUsage'
import { MyUsage as MyUsageNew } from './components/MyUsageNew'
import { PricingUpdateAlert } from './components/PricingUpdateAlert'
import { ProductUpdates } from './components/ProductUpdates'
import { AddonsStoreBanner } from './components/PromoBanners/AddonsStore'
import { BlackFridayPromoBanner } from './components/PromoBanners/AnnualSale/BlackFriday'
import Promotion3M from './components/PromoBanners/Promotion3M'
import styles from './Topbar.module.scss'

export const Topbar = () => {
  const isBiggerThan720 = useBreakpoint(BREAKPOINT[720], 'min')
  const saleName = useSale()

  const { isSubaccount, isTypeReadOnly, isTypeClient } = useUserContext()

  const hasAccessToCredits = useFeatureFlags('CREDITS')
  const PRICING_CHANGES_INFO_FF = useFeatureFlags('PRICING_CHANGES_INFO')
  const hasAccessToPricingUpdateAlert = useAcl(
    'payments.pricing_info.view_alert'
  )

  const hasAccessToLimits =
    useFeatureFlags('CHARGE_V1') && !isSubaccount && !isTypeReadOnly

  const hasAccessToTopbarElement = !isTypeClient && !isTypeReadOnly

  const MyUsageElement = hasAccessToCredits ? MyUsageNew : MyUsage

  const renderElementMyUsage = () =>
    hasAccessToLimits ? <MyUsageElement /> : null

  const renderBanner = () => {
    // change on CHRISTMAS_SALE or SUMMER_SALE if needed
    if (saleName === BLACK_FRIDAY) {
      return <BlackFridayPromoBanner />
    }

    if (saleName === PROMOTION_3M) {
      return <Promotion3M />
    }

    return <AddonsStoreBanner />
  }

  const renderTopbarElementsGroup = () =>
    hasAccessToTopbarElement ? (
      <Fragment>
        <HireUs />

        <Help />

        <ProductUpdates />
      </Fragment>
    ) : null

  const renderElementHelp = () => (hasAccessToTopbarElement ? <Help /> : null)

  return (
    <Column id='spa-topbar' className={styles.topbar}>
      <Row horizontal='space-between' vertical='center'>
        <Row
          className={styles.topbar__content}
          vertical='center'
          horizontal='center'
        >
          <Back />
        </Row>

        <Row
          className={styles.topbar__content}
          vertical='center'
          horizontal='center'
        >
          {isBiggerThan720 ? (
            <Fragment>
              {PRICING_CHANGES_INFO_FF && hasAccessToPricingUpdateAlert && (
                <PricingUpdateAlert />
              )}

              {renderBanner()}

              <Spreader spread='tiny' />

              {renderElementMyUsage()}

              <Spreader spread='small' />

              {renderTopbarElementsGroup()}

              <Spreader spread='small' />

              <Account />
            </Fragment>
          ) : (
            renderElementHelp()
          )}
        </Row>
      </Row>
    </Column>
  )
}

Topbar.displayName = 'Topbar'
