import { useFeatureFlags } from '@contexts/featureFlags'
import { Back as BackOld } from '@landingi/landingi-ui-kit'
import { LANDINGS } from '@routes/path'
import { Back, Spacer } from '@ui-kit'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Row } from 'simple-flexbox'

import { Info } from './Info'

export const Header = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const hasAccessToLandingPagesListNew = useFeatureFlags(
    'LANDING_PAGES_LIST_NEW'
  )

  const handleBack = () => {
    navigate(LANDINGS.LANDINGS)
  }

  return (
    <Fragment>
      {hasAccessToLandingPagesListNew ? (
        <Back
          onClick={handleBack}
          label={t('dashboard.header.back.to.landing.pages')}
          size={12}
        />
      ) : (
        <BackOld onClick={handleBack} label={t('word.back')} />
      )}

      <Spacer />

      <Row alignItems='start'>
        <Info />
      </Row>

      <Spacer space={20} />
    </Fragment>
  )
}

Header.displayName = 'LandingsDashboardHeader'
