import { useEffect, useState } from 'react'

import { Breakpoints, breakpoints } from '../styles/helpers/breakpoints'

export const useBreakpoint = (name: Breakpoints, type: 'min' | 'max') => {
  const pixels = breakpoints[name]

  const getMatchMediaQuery = (mediaType: 'min' | 'max'): string => {
    if (mediaType === 'min') {
      return `(min-width: ${pixels}px)`
    }

    return `(max-width: ${pixels}px)`
  }

  const matchMediaQuery = getMatchMediaQuery(type)

  const [match, setMatch] = useState(
    typeof window !== 'undefined'
      ? window.matchMedia(matchMediaQuery).matches
      : false
  )

  useEffect(() => {
    const onMatch = (event: MediaQueryListEvent) => setMatch(event.matches)

    const matcher = window.matchMedia(matchMediaQuery)

    matcher.addEventListener('change', onMatch)

    return () => matcher.removeEventListener('change', onMatch)
  }, [matchMediaQuery])

  return match
}
