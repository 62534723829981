import HandEmoji from '@images/hand_emoji.png'
import { HorizontalBanner, Paragraph, Spreader } from '@ui-kit'

// THIS COMPONENT IS ONLY USED AS PLACEHOLDER FOR TESTING PURPOSES
export const PowerUpYourPlanBanner = () => {
  const mainAction = () => {
    // eslint-disable-next-line no-console
    console.log('mainAction')
  }

  const closeAction = () => {
    // eslint-disable-next-line no-console
    console.log('closeAction')
  }

  return (
    <HorizontalBanner
      variant='warning'
      imageSrc={HandEmoji}
      closeAction={closeAction}
      mainAction={mainAction}
      data-testid='power-up-your-plan-banner'
    >
      <Paragraph size={12} weight={600}>
        POWER UP YOUR PLAN
      </Paragraph>

      <Spreader spread={5} />

      <Paragraph size={12}>or order a design service.</Paragraph>
    </HorizontalBanner>
  )
}
