import { useSidebarContext } from '@contexts/sidebar'
import { useUserContext } from '@contexts/user'
import { useStyles } from '@hooks/useStyles'
import { Backdrop } from '@landingi/landingi-ui-kit'
import { Close, Heading } from '@ui-kit'
import { AnimatePresence, motion } from 'framer-motion'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

import styles from './AccountDrawer.module.scss'
import { AgencyAccountContent } from './AgencyAccountContent'
import { BusinessAccountContent } from './BusinessAccountContent'

const animate = {
  close: { x: -340 },
  open: { x: 0 }
}

export const AccountDrawer = () => {
  const { t } = useTranslation()

  const { isSubaccount, isAgency } = useUserContext()

  const accountDrawerStyles = useStyles({
    [styles['account-drawer']]: true
  })

  const { isAccountDrawerOpen, toggleAccountDrawerOpen } = useSidebarContext()

  return (
    <Fragment>
      <AnimatePresence>
        {isAccountDrawerOpen && (
          <motion.div
            key='accountDrawer'
            className={accountDrawerStyles}
            initial={animate.close}
            animate={animate.open}
            exit={animate.close}
            transition={{ duration: 0.5, ease: [0.65, 0, 0.3, 1] }}
          >
            <Row
              justifyContent='space-between'
              className={styles['account-drawer__top']}
            >
              <Heading level={2}>
                {isAgency || isSubaccount
                  ? t('account.subaccounts.label')
                  : t('word.main-account')}
              </Heading>

              <Close onClick={toggleAccountDrawerOpen} />
            </Row>

            {isAgency || isSubaccount ? (
              <AgencyAccountContent />
            ) : (
              <BusinessAccountContent />
            )}
          </motion.div>
        )}
      </AnimatePresence>

      {isAccountDrawerOpen ? (
        <Backdrop onClick={toggleAccountDrawerOpen} zIndex='6' />
      ) : null}
    </Fragment>
  )
}

AccountDrawer.displayName = 'AccountDrawer'
