import styles from '@ui-kit/SidebarItem/SidebarItem.module.scss'
import { motion } from 'framer-motion'
import { FC, ReactNode } from 'react'

interface ElementAnimationProps {
  children: ReactNode
}

export const ElementAnimation: FC<ElementAnimationProps> = ({ children }) => (
  <motion.span
    className={styles['sidebar-item__animation']}
    key='content'
    initial='collapsed'
    animate='open'
    exit='collapsed'
    variants={{
      open: { opacity: 1 },
      collapsed: { opacity: 0 }
    }}
    transition={{ duration: 1, ease: [0.65, 0, 0.3, 1] }}
  >
    {children}
  </motion.span>
)

ElementAnimation.displayName = 'SidebarItemElementAnimation'
