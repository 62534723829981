import { TOPBAR_HELP_CLICK_EVENT_NAME } from '@constants/mixpanel'
import { mixpanelEvent } from '@services/mixpanel'
import {
  Button,
  Dropdown,
  DropdownElement,
  Icon,
  Paragraph,
  Spreader,
  Tooltip
} from '@ui-kit'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

import { useGetDropdownItems } from './hooks/useGetDropdownItems'

export const Help = () => {
  const { t } = useTranslation()

  const dropdownItems = useGetDropdownItems()

  const customTrigger = ({ isOpen }: { isOpen: boolean }) => {
    return (
      <Tooltip content={t('topbar.help.tooltip.content')} placement='bottom'>
        <Button
          variant='text-neutral'
          icon='icon-help'
          isSquare
          active={isOpen}
        />
      </Tooltip>
    )
  }

  return (
    <Dropdown
      trigger={customTrigger}
      onOpen={() => mixpanelEvent({ name: TOPBAR_HELP_CLICK_EVENT_NAME })}
      data-testid='help-dropdown'
    >
      {({ close }) =>
        dropdownItems.map(({ key, icon, label, url }) => (
          <DropdownElement
            key={key}
            onClick={() => {
              close()

              window.open(url, '_blank')
            }}
          >
            <Row alignItems='center'>
              <Icon icon={icon} size={12} color='neutral-8' />

              <Spreader spread={10} />

              <Paragraph color='neutral-8'>{label}</Paragraph>
            </Row>
          </DropdownElement>
        ))
      }
    </Dropdown>
  )
}

Help.displayName = 'TopbarHelp'
