import { MODAL_UPGRADE_PLAN } from '@components/Modals/ModalUpgradePlan'
import { UPGRADE_MODALS } from '@constants/modals'
import { useSidebarContext } from '@contexts/sidebar'
import { useUserContext } from '@contexts/user'
import NiceModal from '@ebay/nice-modal-react'
import { Button, Divider } from '@ui-kit'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

import styles from '../AccountDrawer.module.scss'
import { BusinessDrawerItem } from '../DrawerItem'

export const BusinessAccountContent = () => {
  const { t } = useTranslation()

  const { ownerEmail } = useUserContext()

  const { toggleAccountDrawerOpen } = useSidebarContext()

  const handleManageSubaccounts = () => {
    toggleAccountDrawerOpen()

    NiceModal.show(MODAL_UPGRADE_PLAN, {
      upgradeType: UPGRADE_MODALS.AGENCY_HUB
    })

    return
  }

  return (
    <Fragment>
      <Divider />

      <Column
        className={styles['account-drawer__business']}
        data-testid='business-account-content'
      >
        <BusinessDrawerItem
          name={t('word.main-account')}
          email={ownerEmail || ''}
        />
      </Column>

      <Divider />

      <Row className={styles['account-drawer__bottom']}>
        <Button
          onClick={handleManageSubaccounts}
          data-testid='manage-business-subaccounts'
        >
          {t('add.subaccounts')}
        </Button>
      </Row>
    </Fragment>
  )
}

BusinessAccountContent.displayName = 'BusinessAccountContent'
