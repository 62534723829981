import { Heading, Paragraph, Spacer } from '@landingi/landingi-ui-kit'
import { useWhitelabelPageContext } from '@pages/Authentication/Layouts/WhitelabelPage/Context'
import { REGISTRATION } from '@routes/path'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { Column } from 'simple-flexbox'

import GrantNewPasswordForm from './Form'
import styles from './GrantNewPassword.module.scss'

const GrantNewPassword = () => {
  const { t } = useTranslation()
  const { clientName } = useParams()
  const { getWhitelabelPageConfig } = useWhitelabelPageContext()
  const { mainColor } = getWhitelabelPageConfig()

  return (
    <Column className={styles['register-cta']}>
      <Heading level={1} bold align='center'>
        {t('registration.flow.change.password')}
      </Heading>

      <Spacer space='large' />

      <GrantNewPasswordForm />

      <Spacer space='mini' />

      <Spacer space='tiny' />

      <Paragraph size={12} color='accent-2' align='center' padding='none'>
        <Link
          to={`${REGISTRATION.LOGIN.DEFAULT}/${clientName}`}
          style={{ color: mainColor }}
        >
          <Trans i18nKey='registration.flow.go.back.to.sign.in' />
        </Link>
      </Paragraph>
    </Column>
  )
}

GrantNewPassword.displayName = 'Grant New Password'

export default GrantNewPassword
