import { NEW_LANDINGI_APP_URL } from '@constants/index'
import { useFeatureFlags } from '@contexts/featureFlags'
import GenerateWithWizpage from '@images/landings/wizard/generate-with-wizpage.png'
import Templates from '@images/landings/wizard/select-template.png'
import { LANDINGS } from '@routes/path'
import { Badge, Button, Heading, Spacer, Spreader, Text } from '@ui-kit'
import { Close } from '@ui-kit/Close'
import { motion } from 'framer-motion'
import { Fragment } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Column, Row } from 'simple-flexbox'

import { ChooseMethodBoxOutline } from './components/CreateLandingBoxOutline'
import { MarketingBoxes } from './components/MarketingBoxes'
import styles from './CreateLanding.module.scss'
import { FigmaBox } from './figma/Box'
import { ImportButton } from './ImportButton'

const AlphaBadge = () => (
  <Badge weight={600} variant='almostcyan-filled'>
    ALFA
  </Badge>
)

export const CreateLandingWizard = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const UNBRANDED_CONTENT_FF = useFeatureFlags('UNBRANDED_CONTENT')

  const close = () => navigate(LANDINGS.LANDINGS)

  const handleWizardClick = () => {
    navigate(LANDINGS.LANDINGS_CREATE_WIZARD_FIRST)
  }

  const handleTemplatesClick = () =>
    window.open(`${NEW_LANDINGI_APP_URL}/templates/v1`, '_self')

  return (
    <motion.div
      initial={{ x: '-100vw' }}
      animate={{ x: 0 }}
      exit={{ x: '-100vw' }}
      transition={{ ease: [0.37, 0, 0.39, 1], duration: 0.85 }}
    >
      <Row className={styles['choose-method']}>
        <Close className={styles.close} onClick={close} />

        <Column className={styles['choose-method__content']}>
          <div className={styles['choose-method__left-column-content']}>
            <Row justifyContent='space-between'>
              <Heading>{t('landings.create.wizard.landing.modal')}</Heading>

              <ImportButton>
                <Button variant='outlined' icon='icon-cloud-upload' size={14}>
                  {t('create.landing.import')}
                </Button>
              </ImportButton>
            </Row>

            <Spacer space={20} />

            <Spacer space={15} />

            <div className={styles['choose-method__box-outlines-grid']}>
              <ChooseMethodBoxOutline
                onClick={handleWizardClick}
                imageSrc={GenerateWithWizpage}
                heading={
                  <Fragment>
                    <Trans i18nKey='landings.create.wizard.landing.modal.create.with.wizard' />
                    <br />
                    <Text color='primary' weight={600}>
                      {t('word.wizpage')}
                    </Text>
                    <Spreader spread={5} />
                    <AlphaBadge />
                  </Fragment>
                }
                paragraph={t(
                  'landings.create.wizard.landing.modal.create.campaign.specific'
                )}
                buttonContent={t(
                  'landings.create.wizard.landing.modal.generate.website'
                )}
                data-testid='wizard-box'
              />

              <ChooseMethodBoxOutline
                imageSrc={Templates}
                heading={t(
                  'landings.create.wizard.landing.modal.select.template'
                )}
                paragraph={t(
                  'landings.create.wizard.landing.modal.choose.one.of.400'
                )}
                onClick={handleTemplatesClick}
                buttonContent={t(
                  'landings.create.wizard.landing.modal.browse.templates'
                )}
              />

              <FigmaBox />
            </div>

            {!UNBRANDED_CONTENT_FF && (
              <Fragment>
                <Spacer space={50} />

                <Spacer space={10} />

                <div className={styles['choose-method__marketing-boxes-grid']}>
                  <MarketingBoxes />
                </div>
              </Fragment>
            )}
          </div>
        </Column>
      </Row>
    </motion.div>
  )
}
