import { Button } from '@ui-kit/Button'
import { Dropdown } from '@ui-kit/Dropdown'
import { DropdownElement } from '@ui-kit/DropdownList'
import { Icon } from '@ui-kit/Icon'
import { Spreader } from '@ui-kit/Spreader'
import { FC, Fragment } from 'react'

import { YearType } from './types'

interface YearDropdownProps {
  handleSelect: (year: YearType) => void
  year: YearType
}

export const YearDropdown: FC<YearDropdownProps> = ({ handleSelect, year }) => {
  const currentYear = new Date().getFullYear()
  const years = Array.from(
    { length: currentYear - 2013 + 1 },
    (_, i) => 2013 + i
  ).reverse()

  const trigger = ({ isOpen }: { isOpen: boolean }) => (
    <Button variant='text-neutral' size={12} data-testid='year-picker'>
      {year}
      <Spreader spread={10} />
      <Icon icon={isOpen ? 'icon-angle-up' : 'icon-angle-down'} />
    </Button>
  )

  return (
    <Dropdown
      size='small'
      trigger={trigger}
      placement='bottom-start'
      overflowStyle={{ maxHeight: '300px', overflowY: 'auto' }}
    >
      {({ close }) => (
        <Fragment>
          {years.map(y => (
            <DropdownElement
              key={y}
              onClick={() => {
                handleSelect(y)
                close()
              }}
            >
              {y}
            </DropdownElement>
          ))}
        </Fragment>
      )}
    </Dropdown>
  )
}
