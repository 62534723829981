import { useFeatureFlags } from '@contexts/featureFlags'
import { getLocalStorage, setLocalStorage } from '@helpers/storage'
import {
  SURVEY_ACTIVE_SURVEY_BOXES_IDS,
  SURVEY_QUESTIONS_SKIPPED_INFO
} from '@pages/Authentication/helpers/localStorage'
import PropTypes from 'prop-types'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'

import { useStepsContext } from '../steps'

const SurveyContext = createContext(null)

export const SurveyProvider = ({ children }) => {
  const { currentStep } = useStepsContext()
  const hasSurveyStarted = currentStep > 0
  const [activeSurveyBoxesIDs, setActiveSurveyBoxesIDs] = useState(
    JSON.parse(getLocalStorage(SURVEY_ACTIVE_SURVEY_BOXES_IDS)) || []
  )

  const hasAccessToSurveyWithoutCommunicationQuestion = useFeatureFlags(
    'SURVEY_WITHOUT_COMMUNICATION_QUESTION'
  )

  const [maxSteps, setMaxSteps] = useState(
    hasAccessToSurveyWithoutCommunicationQuestion ? 5 : 6
  )

  const [questionsSkippedInfo, setQuestionsSkippedInfo] = useState(
    JSON.parse(getLocalStorage(SURVEY_QUESTIONS_SKIPPED_INFO)) || {
      first: true,
      second: true,
      third: true,
      fourth: true,
      fifth: true,
      ...(!hasAccessToSurveyWithoutCommunicationQuestion && {
        sixth: true
      })
    }
  )
  const [hasSurveyFinished, setHasSurveyFinished] = useState(false)
  const [shouldPlayFinalAnimation, setShouldPlayFinalAnimation] =
    useState(false)

  useEffect(() => {
    setLocalStorage(
      SURVEY_QUESTIONS_SKIPPED_INFO,
      JSON.stringify(questionsSkippedInfo)
    )
  }, [questionsSkippedInfo])

  useEffect(() => {
    setLocalStorage(
      SURVEY_ACTIVE_SURVEY_BOXES_IDS,
      JSON.stringify(activeSurveyBoxesIDs)
    )
  }, [activeSurveyBoxesIDs])

  const value = useMemo(
    () => ({
      hasSurveyStarted,
      activeSurveyBoxesIDs,
      setActiveSurveyBoxesIDs,
      currentStep,
      questionsSkippedInfo,
      setQuestionsSkippedInfo,
      maxSteps,
      setMaxSteps,
      hasSurveyFinished,
      setHasSurveyFinished,
      shouldPlayFinalAnimation,
      setShouldPlayFinalAnimation
    }),
    [
      hasSurveyStarted,
      activeSurveyBoxesIDs,
      currentStep,
      maxSteps,
      hasSurveyFinished,
      shouldPlayFinalAnimation,
      questionsSkippedInfo
    ]
  )

  return (
    <SurveyContext.Provider value={value}>{children}</SurveyContext.Provider>
  )
}

SurveyProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const useSurveyContext = () => {
  const survey = useContext(SurveyContext)

  if (!survey) {
    throw new Error('useSurveyContext must be used inside SurveyProvider')
  }

  return survey
}
