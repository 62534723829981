import { EDITOR_DOMAIN } from '@config/env'
import { Ratio, wizardRatioMap } from '@constants/wizardRatioMap'
import { useFeatureFlags } from '@contexts/featureFlags'
import { getErrorMessage } from '@helpers/errorMessages'
import { generateRandomNumbers } from '@helpers/random'
import { openWindowWithJWT } from '@helpers/semrush'
import { setLocalStorage } from '@helpers/storage'
import { useLazyService } from '@hooks/useLazyService'
import {
  optionsWithAccessToThirdStep,
  pallets
} from '@pages/Landings/routes/Landings/CreateLandingWizard/helpers/constants'
import { validateLandingPageName } from '@services/landings/validate'
import {
  createLPWithWizard,
  createLPWithWizardDev
} from '@services/landings/wizard'
import { mixpanelEvent } from '@services/mixpanel'
import { emitTimingToast } from '@ui-kit'
import axios from 'axios'
import { useFormik } from 'formik'
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { object, string } from 'yup'

import {
  COMPANY_PRESENTATION,
  CONTEST_SIGNUP,
  EVENT_SIGNUP,
  LpGoal,
  MOBILE_APP_PROMOTION,
  NEWSLETTER_SIGNUP,
  OFFER_DOWNLOAD,
  PRODUCT_SALE,
  RECRUITMENT,
  RESOURCE_DOWNLOAD,
  SAAS_REGISTRATION,
  SCHEDULE_MEETING,
  SERVICE_SALE,
  WAITLIST,
  WEBINAR_SIGNUP
} from '../constants/lpGoals'
import {
  isSectionForGoal,
  randomizeHeroSection
} from '../helpers/randomizeHeroSection'
import { SectionType } from '../helpers/types'
import { extractResolution } from '../helpers/url'
import useSteps from '../helpers/useSteps'
import { TopicsImagesNumbers, TopicType } from '../steps/Sixth/topics'
import { LpStyle, WizardFormValues } from '../types'

const initialValues: WizardFormValues = {
  websiteName: '',
  websiteOffer: undefined,
  style: undefined,
  palette: 0,
  topGoal: undefined,
  topic: undefined,
  customPalette: {
    font: '',
    colors: [],
    mainColors: []
  }
}

type GenerationStatusType = 'in-progress' | 'finished' | 'error'

export type WizardProviderValue = {
  formik: ReturnType<typeof useFormik<typeof initialValues>>
  validators: {
    isFirstStepValid: boolean
    isSecondStepValid: boolean
    isThirdStepValid: boolean
    isFourthStepValid: boolean
    isSixthStepValid: boolean
  }
  setTopic: (topic: TopicType | undefined) => void
  setStyle: (style: LpStyle) => void
  setGoal: (goal: LpGoal) => void
  selectedHeroSection?: SectionType
  handleSubmit: (event?: React.FormEvent) => void
  selectedTopicImageNumbers?: number[]
  generationStatus?: GenerationStatusType
}

const WizardContext = createContext<WizardProviderValue | null>(null)

export const WizardProvider = () => {
  const [sendMixpanelEvent] = useLazyService(mixpanelEvent)

  const [generationStatus, setGenerationStatus] =
    useState<GenerationStatusType>()

  const { t } = useTranslation()
  // every style must be saved so sections are not randomized again when switching between styles
  const [selectedHeroSections, setSelectedHeroSections] = useState<{
    modern?: SectionType
    elegant?: SectionType
    dark?: SectionType
    minimal?: SectionType
  }>({
    modern: undefined,
    elegant: undefined,
    dark: undefined,
    minimal: undefined
  })

  const [selectedTopicsImagesNumbers, setSelectedTopicsImagesNumbers] =
    useState<TopicsImagesNumbers>({})

  const selectedImages = useRef<
    {
      size: Ratio
      url: string
    }[]
  >()

  const FormSchema = object().shape({
    websiteName: string()
      .trim()
      .max(250)
      .required(t('form.validation.isRequired')),
    topGoal: string()
      .oneOf([
        COMPANY_PRESENTATION,
        CONTEST_SIGNUP,
        EVENT_SIGNUP,
        MOBILE_APP_PROMOTION,
        NEWSLETTER_SIGNUP,
        OFFER_DOWNLOAD,
        PRODUCT_SALE,
        RECRUITMENT,
        RESOURCE_DOWNLOAD,
        SAAS_REGISTRATION,
        SCHEDULE_MEETING,
        SERVICE_SALE,
        WAITLIST,
        WEBINAR_SIGNUP
      ])
      .required(),
    websiteOffer: string().oneOf(['product', 'service']).required(),
    style: string().oneOf(['modern', 'dark', 'elegant', 'minimal']).required(),
    topic: string().required()
  })

  const { nextStep, stepNumber, shouldShowAdvertisementStep } = useSteps()
  const COMPOSER_AI_CONTENT_FF = useFeatureFlags('COMPOSER_AI_CONTENT')

  const formik = useFormik({
    initialValues: {
      websiteName: initialValues.websiteName,
      websiteOffer: initialValues.websiteOffer,
      style: initialValues.style,
      palette: initialValues.palette,
      topGoal: initialValues.topGoal,
      topic: initialValues.topic,
      customPalette: initialValues.customPalette,
      productName: undefined,
      productDescription: undefined,
      productBenefits: undefined,
      receiversDescription: undefined,
      contentTone: undefined,
      contentLanguage: undefined
    } as WizardFormValues,
    validationSchema: FormSchema,
    onSubmit: () => {},
    validateOnMount: true
  })

  const submit = useCallback(async () => {
    /*
    8 - generation view, when request failed and we need to run it again
    7 - ai text advertise view
    6 - topic view
    * */
    const stepsWhereGenerateIsPossible = [
      !shouldShowAdvertisementStep() && 6,
      7,
      8
    ]

    try {
      formik.setSubmitting(true)
      setGenerationStatus('in-progress')

      if (stepNumber === 1) {
        await validateLandingPageName(formik.values.websiteName)
        sendMixpanelEvent({
          name: '[Wizard] Landing page Name'
        })
      }

      if (
        stepNumber === 2 &&
        !optionsWithAccessToThirdStep.includes(formik.values.topGoal!)
      ) {
        formik.setFieldValue('websiteOffer', undefined)

        sendMixpanelEvent({
          name: '[Wizard] Landing page Goal',
          properties: {
            goal: formik.values.topGoal
          }
        })
      }

      if (stepNumber === 4) {
        sendMixpanelEvent({
          name: '[Wizard] Landing page Style',
          properties: {
            style: formik.values.style
          }
        })
      }

      if (stepNumber === 5) {
        sendMixpanelEvent({
          name: '[Wizard] Landing page Palette',
          properties: {
            pallete: `${formik.values.style} ${formik.values.palette}`
          }
        })
      }

      if (stepNumber === 6 && formik.values.topic) {
        // collect photos, if user selected topic
        const hero_bg_images_nodes = document.querySelectorAll<HTMLDivElement>(
          '[data-wizard-bg-image="true"]'
        )

        const backgroundImagesSrc = Array.from(hero_bg_images_nodes)
          .map((element: HTMLDivElement) => {
            const backgroundStyle = element.style.backgroundImage
            const urlMatch = backgroundStyle.match(/url\(["']?([^"']*)["']?\)/)

            return urlMatch ? urlMatch[1] : undefined
          })
          .filter((element): element is string => Boolean(element))

        const backgroundImages = backgroundImagesSrc.map(src => ({
          size: 'background' as const,
          url: src
        }))

        const hero_images_nodes = document.querySelectorAll<HTMLImageElement>(
          '[data-wizard-image="true"]'
        )

        const heroImages = Array.from(hero_images_nodes).map(
          (image: HTMLImageElement) => ({
            size: wizardRatioMap[extractResolution(image.src)],
            url: image.src
          })
        )

        selectedImages.current = [...heroImages, ...backgroundImages]
      }
      if (stepsWhereGenerateIsPossible.includes(stepNumber)) {
        const {
          websiteName,
          style,
          topGoal,
          topic,
          websiteOffer,
          customPalette,
          palette,
          productName,
          productDescription,
          productBenefits,
          receiversDescription,
          contentTone,
          contentLanguage
        } = formik.values

        const paletteObject =
          palette === 'custom' ? customPalette : pallets[style!][palette]

        const { mainColors, font, colors } = paletteObject

        const endpointValues = {
          name: websiteName,
          style_code: style,
          goal_code: topGoal,
          topic_code: !topic ? undefined : topic,
          offer: websiteOffer,
          hero_code: selectedHeroSections[style as LpStyle]?.name,
          hero_images: topic ? selectedImages.current : undefined,
          palette: {
            main_colors: mainColors,
            font,
            hues: {
              primary: colors[0],
              secondary: colors[1],
              background: colors[2]
            }
          },
          product_name: productName,
          product_description: productDescription,
          product_benefits: productBenefits,
          receivers_description: receiversDescription,
          content_tone: contentTone,
          content_language: contentLanguage
        }

        nextStep(formik.values)

        if (COMPOSER_AI_CONTENT_FF) {
          await createLPWithWizardDev({
            ...endpointValues,
            description: productDescription
          })

          setGenerationStatus('finished')

          setLocalStorage('used_wizard', 'true')

          emitTimingToast({ message: 'Success!', type: 'success' }) // TODO ask endpoint if LP is generated, redirect to editor, no toast will be shown

          return
        }
        const {
          data: { data }
        } = await createLPWithWizard(endpointValues)

        const { hash } = data

        setGenerationStatus('finished')

        setLocalStorage('used_wizard', 'true')

        setTimeout(() => {
          openWindowWithJWT(`${EDITOR_DOMAIN}/${hash}?composer=true`)
        }, 1000)

        return
      }

      nextStep(formik.values)
    } catch (error) {
      if (stepsWhereGenerateIsPossible.includes(stepNumber)) {
        setGenerationStatus('error')
        return
      }

      if (axios.isAxiosError(error)) {
        const { response } = error
        const code = response?.data.error.code

        emitTimingToast({
          type: 'error',
          message: t(getErrorMessage(code))
        })
      } else {
        emitTimingToast({
          type: 'error',
          message: t(getErrorMessage())
        })
      }
    } finally {
      formik.setSubmitting(false)
    }
  }, [
    formik,
    nextStep,
    selectedHeroSections,
    stepNumber,
    t,
    shouldShowAdvertisementStep,
    selectedImages,
    sendMixpanelEvent
  ])

  const handleSubmit = useCallback(
    async (event?: React.FormEvent) => {
      event?.preventDefault()
      submit()
    },
    [submit]
  )

  const randomizeImage = useCallback(
    (topic: string) => {
      if (
        selectedTopicsImagesNumbers[
          topic as keyof typeof selectedTopicsImagesNumbers
        ] === undefined
      ) {
        const numbers = generateRandomNumbers({ count: 4, min: 1, max: 15 })

        setSelectedTopicsImagesNumbers(prev => {
          const newImages = { ...prev, [topic]: numbers }

          return newImages
        })
      }
    },
    [selectedTopicsImagesNumbers]
  )

  const updateHeroSections = useCallback(
    (style: LpStyle, goal: LpGoal) => {
      const section = randomizeHeroSection(style, goal)

      if (formik.values.topic) {
        randomizeImage(formik.values.topic)
      }

      setSelectedHeroSections(prev => ({
        ...prev,
        [style]: section
      }))
    },
    [formik.values.topic, randomizeImage]
  )

  const setStyle = useCallback(
    (style: LpStyle) => {
      formik.setFieldValue('palette', 0)
      formik.setFieldValue('customPalette.font', pallets[style][0].font)
      formik.setFieldValue('customPalette.colors', pallets[style][0].colors)
      formik.setFieldValue('customPalette.mainColors', [
        pallets[style][0].colors[0][2],
        pallets[style][0].colors[1][2],
        pallets[style][0].colors[2][2]
      ])
      formik.setFieldValue('style', style)

      if (formik.values.topGoal && selectedHeroSections[style] === undefined) {
        updateHeroSections(style, formik.values.topGoal)
      }
    },
    [formik, updateHeroSections, selectedHeroSections]
  )

  const setGoal = useCallback(
    (goal: LpGoal) => {
      const previousSection =
        formik.values.style && selectedHeroSections[formik.values.style]

      formik.setFieldValue('topGoal', goal)

      if (
        formik.values.style &&
        !isSectionForGoal(formik.values.style, goal, previousSection)
      ) {
        updateHeroSections(formik.values.style, goal)
      }
    },
    [formik, updateHeroSections, selectedHeroSections]
  )

  const setTopic = useCallback(
    (topic: string | undefined) => {
      formik.setFieldValue('topic', topic)

      if (topic) {
        randomizeImage(topic)
      }
    },
    [formik, randomizeImage]
  )

  const validators = useMemo(
    () => ({
      isFirstStepValid: formik.errors.websiteName === undefined,
      isSecondStepValid: formik.errors.topGoal === undefined,
      isThirdStepValid: formik.errors.websiteOffer === undefined,
      isFourthStepValid: formik.errors.style === undefined,
      isSixthStepValid: formik.errors.topic === undefined
    }),
    [formik.errors]
  )

  const ctxValue = useMemo(
    () => ({
      formik,
      validators,
      setStyle,
      setGoal,
      setTopic,
      selectedHeroSection:
        formik.values.style && selectedHeroSections[formik.values.style],
      selectedTopicImageNumbers:
        selectedTopicsImagesNumbers[
          formik.values.topic as keyof typeof selectedTopicsImagesNumbers
        ],
      handleSubmit,
      submit,
      generationStatus
    }),
    [
      setTopic,
      selectedTopicsImagesNumbers,
      formik,
      validators,
      setStyle,
      selectedHeroSections,
      setGoal,
      handleSubmit,
      submit,
      generationStatus
    ]
  )

  return (
    <WizardContext.Provider value={ctxValue}>
      <Outlet />
    </WizardContext.Provider>
  )
}

export const useWizardContext = () => {
  const ctxValue = useContext(WizardContext)

  if (!ctxValue) {
    throw new Error('useWizardContext must be used inside WizardProvider')
  }

  return ctxValue
}
