import useSWRImmutable from 'swr/immutable'

export interface GetLastCreatedLandingResponse {
  landing_identifier: string
}

export const useGetLastCreatedLanding = () => {
  return useSWRImmutable<GetLastCreatedLandingResponse>(
    `landing-page/landings/last_created`
  )
}
