import useQuery from '@hooks/useQuery'
import BonduelleLogo from '@images/registration/bonduelle_logo.png'
import CBRELogo from '@images/registration/cbre_logo.png'
import CreditAgricoleLogo from '@images/registration/credit_agricole_logo.png'
import DekraLogo from '@images/registration/dekra_logo.png'
import OperaLogo from '@images/registration/opera_logo.png'
import WarnerBrosLogo from '@images/registration/warner_bros_logo.png'
import {
  Heading,
  Icon,
  List,
  ListItem,
  Paragraph,
  Spacer,
  Spreader
} from '@landingi/landingi-ui-kit'
import { SalePromoBanner } from '@pages/Authentication/components/SalePromoBanner'
import ShapesContainer from '@pages/Authentication/components/SecondColumn/ShapesContainer'
import { useAnimationContext } from '@pages/Authentication/contexts/animation'
import { Image } from '@ui-kit'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import { Fragment, memo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

import styles from './RefreshedColumnContent.module.scss'

const RefreshedVariant = ({ title }) => {
  const { secondColumnControls } = useAnimationContext()
  const queryParams = useQuery()
  const hasOctoberSale = queryParams.get('promotion') === 'os3m'
  const has1MonthSale = queryParams.get('promotion') === 'p1m'

  const { t } = useTranslation()

  return (
    <Fragment>
      <ShapesContainer />
      <motion.div
        className={styles['column-content']}
        animate={secondColumnControls}
      >
        <Heading level={1}>
          <Trans i18nKey={title} />
        </Heading>

        <List>
          <ListItem>
            <Row>
              <Icon
                icon='icon-check-circle'
                color='primary'
                className={styles['refreshed-registration-list-icon']}
              />

              <Spreader spread='small' />

              <Paragraph size={16} line={24} padding='none'>
                <Trans i18nKey='registration.flow.refreshed.1st.paragraph' />
              </Paragraph>
            </Row>

            {hasOctoberSale && (
              <Fragment>
                <Spacer space='tiny' />

                <SalePromoBanner
                  promoPeriodText={t('october.sale.promo.for.3.months')}
                />
              </Fragment>
            )}

            {has1MonthSale && (
              <Fragment>
                <Spacer space='tiny' />

                <SalePromoBanner
                  promoPeriodText={t('october.sale.promo.for.1.month')}
                />
              </Fragment>
            )}
          </ListItem>

          <Spacer space='mini' />

          <Spacer space='tiny' />

          <ListItem>
            <Row>
              <Icon
                icon='icon-check-circle'
                color='primary'
                className={styles['refreshed-registration-list-icon']}
              />

              <Spreader spread='small' />

              <Paragraph size={16} line={24} padding='none'>
                <Trans i18nKey='registration.flow.refreshed.2nd.paragraph' />
              </Paragraph>
            </Row>
          </ListItem>

          <Spacer space='mini' />

          <Spacer space='tiny' />

          <ListItem>
            <Row>
              <Icon
                icon='icon-check-circle'
                color='primary'
                className={styles['refreshed-registration-list-icon']}
              />

              <Spreader spread='small' />

              <Paragraph size={16} line={24} padding='none'>
                <Trans i18nKey='registration.flow.refreshed.3rd.paragraph' />
              </Paragraph>
            </Row>
          </ListItem>

          <Spacer space='mini' />

          <Spacer space='tiny' />

          <ListItem>
            <Row>
              <Icon
                icon='icon-check-circle'
                color='primary'
                className={styles['refreshed-registration-list-icon']}
              />

              <Spreader spread='small' />

              <Paragraph size={16} line={24} padding='none'>
                <Trans i18nKey='registration.flow.refreshed.4th.paragraph' />
              </Paragraph>
            </Row>
          </ListItem>
        </List>

        <Spacer />

        <div className={styles['column-content__logos']}>
          <Image src={BonduelleLogo} height='20px' />

          <Image src={CreditAgricoleLogo} height='20px' />

          <Image src={CBRELogo} height='20px' />

          <Image src={DekraLogo} height='20px' />

          <Image src={OperaLogo} height='20px' />

          <Image src={WarnerBrosLogo} height='20px' />
        </div>
      </motion.div>
    </Fragment>
  )
}

RefreshedVariant.displayName = 'RefreshedVariant'

RefreshedVariant.propTypes = {
  title: PropTypes.string.isRequired
}

export default memo(RefreshedVariant)
