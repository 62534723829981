import ErrorImage from '@images/error_image.svg'
import { CustomLink, Image, Paragraph, Spacer, Spreader, Text } from '@ui-kit'
import { useTranslation } from 'react-i18next'
import { Column } from 'simple-flexbox'

import styles from './ErrorBoundaryFallback.module.scss'

export const ErrorBoundaryFallback = () => {
  const { t } = useTranslation()

  return (
    <Column
      className={styles['error-fallback']}
      alignItems='center'
      data-testid='error-boundary-fallback'
    >
      <Image src={ErrorImage} />

      <Spacer space={10} />

      <Paragraph size={16} weight={600} color='neutral' lineHeight='normal'>
        {t('error.component.title')}
      </Paragraph>

      <Spacer space={5} />

      <div className={styles['error-fallback__message']}>
        <Text size={14} color='neutral-8' lineHeight='normal'>
          {t('error.component.description')}
        </Text>

        <Spreader spread={5} />

        <CustomLink size={14} href={t('word.create-support-ticket.url')}>
          {t('error.component.link')}
        </CustomLink>
      </div>
    </Column>
  )
}
