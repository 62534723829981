import NiceModal from '@ebay/nice-modal-react'

import { ModalEventTrackerBlackFridayPromo } from './ModalEventTrackerBlackFridayPromo'

export const MODAL_EVENT_TRACKER_BLACK_FRIDAY_PROMO =
  'MODAL_EVENT_TRACKER_BLACK_FRIDAY_PROMO'

NiceModal.register(
  MODAL_EVENT_TRACKER_BLACK_FRIDAY_PROMO,
  ModalEventTrackerBlackFridayPromo
)
