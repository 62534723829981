import { DEV } from '@config/env'
import client from '@lib/http/client'
import { AxiosResponse } from 'axios'
import useSWRImmutable from 'swr/immutable'

type LandingPageWizard = {
  name: string
  style_code?: string
  goal_code?: string
  topic_code?: string
  offer?: string
  hero_code?: string
  palette: {
    main_colors: string[]
    font: string
    hues: {
      primary: string[]
      secondary: string[]
      background: string[]
    }
  }
  description?: string
}

export type CreateLPWithWizardResponse = {
  hash: string
  name: string
  isPublished: boolean
}

export const createLPWithWizard = (wizard: LandingPageWizard) =>
  client.post<AxiosResponse<CreateLPWithWizardResponse>>(
    `landing-page/wizard`,
    wizard,
    { withCredentials: true, timeout: DEV ? 600000 : 400000 } // Set timeout to 3 minutes, as AI content generation takes time
  )

export const createLPWithWizardDev = (wizard: LandingPageWizard) =>
  client.post<AxiosResponse<CreateLPWithWizardResponse>>(
    `landing-page/composer`,
    wizard,
    { withCredentials: true }
  )

type TopicsResponse = {
  results: string[]
}

export const useSearchTopics = (search: string) =>
  useSWRImmutable<TopicsResponse>(`landing-page/topics?search=${search}`)
