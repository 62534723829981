import { useFeatureFlags } from '@contexts/featureFlags'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { Modal } from '@landingi/landingi-ui-kit'
import { mixpanelEvent } from '@services/mixpanel'
import { Heading } from '@ui-kit/Heading'
import { Image } from '@ui-kit/Image'
import { Paragraph } from '@ui-kit/Paragraph'
import { Spacer } from '@ui-kit/Spacer'
import { Spreader } from '@ui-kit/Spreader'
import { Fragment, useEffect } from 'react'
import { Column, Row } from 'simple-flexbox'

import { useUpgradeModalContent } from './hooks/useUpgradeModalContent'
import styles from './ModalUpgradePlan.module.scss'

interface ModalUpgradePlanProps {
  upgradeType: keyof ReturnType<typeof useUpgradeModalContent>
}

export const ModalUpgradePlan = NiceModal.create<ModalUpgradePlanProps>(
  ({ upgradeType }) => {
    const content = useUpgradeModalContent()[upgradeType]

    const hasAccessToAddonsStore = useFeatureFlags('ADDONS_STORE')

    const { remove } = useModal()

    useEffect(() => {
      if (content?.featureNameForMixpanel) {
        mixpanelEvent({
          name: 'Access to feature - display modal',
          properties: {
            feature: content.featureNameForMixpanel
          }
        })
      }
    }, [content?.featureNameForMixpanel])

    return (
      <Modal
        isActive
        i18n={{
          title: content?.modalTitle
        }}
        onClick={remove}
        isBodyPadding='none'
        headingAlign='center'
        size='small'
        className={hasAccessToAddonsStore ? styles['upgrade-modal'] : undefined}
      >
        <Column>
          <Spacer space={20} />

          <Image src={content?.imageSrc} />

          <Spacer space={30} />

          <Column className={styles['upgrade-modal__content']}>
            {content.featureHeading && (
              <Fragment>
                <Heading level={3} align='center'>
                  {content.featureHeading}
                </Heading>

                <Spacer space={15} />
              </Fragment>
            )}

            {content.featureDescription && (
              <Fragment>
                <Paragraph size={14} align='center' weight={400}>
                  {content.featureDescription}
                </Paragraph>

                <Spacer space={15} />
              </Fragment>
            )}
          </Column>

          <Spacer space={15} />

          {!content.hideFooter && (
            <Fragment>
              <Row
                className={styles['upgrade-modal__footer']}
                justifyContent='center'
                alignItems='center'
              >
                {content?.secondaryButton && (
                  <Fragment>
                    {content.secondaryButton}

                    <Spreader spread={30} />
                  </Fragment>
                )}

                {content?.primaryButton && content.primaryButton}
              </Row>

              <Spacer space={30} />
            </Fragment>
          )}
        </Column>
      </Modal>
    )
  }
)

ModalUpgradePlan.displayName = 'UpgradePlanModal'
