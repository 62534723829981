import { useUserContext } from '@contexts/user'
import { getTrimmedLabel } from '@helpers/getTrimmedLabel'
import { Avatar, Spreader, Text } from '@ui-kit'
import { ElementAnimation } from '@ui-kit/SidebarItem/components/ElementAnimation'
import { Column, Row } from 'simple-flexbox'

import styles from './SidebarAvatar.module.scss'

export const SidebarAvatar = () => {
  const { userName, userID, userEmail } = useUserContext()

  const {
    trimmedLabel: trimmedUserName,
    TooltipWithFullLabel: TooltipWithFullLabelOfUserName
  } = getTrimmedLabel({
    label: userName || '',
    maxNumberOfCharacters: 21
  })

  const {
    trimmedLabel: trimmedUserEmail,
    TooltipWithFullLabel: TooltipWithFullLabelOfUserEmail
  } = getTrimmedLabel({
    label: userEmail || '',
    maxNumberOfCharacters: 28
  })

  return userID ? (
    <ElementAnimation>
      <Row className={styles.avatar}>
        <Avatar userId={userID} userName={userName} isInteractive={false} />

        <Spreader spread={10} />

        <Column vertical='center'>
          <TooltipWithFullLabelOfUserName>
            <Text size={12} weight={600} lineHeight='normal'>
              {trimmedUserName}
            </Text>
          </TooltipWithFullLabelOfUserName>

          <TooltipWithFullLabelOfUserEmail>
            <Text size={10} color='neutral-6' lineHeight='normal'>
              {trimmedUserEmail}
            </Text>
          </TooltipWithFullLabelOfUserEmail>
        </Column>
      </Row>
    </ElementAnimation>
  ) : null
}

SidebarAvatar.displayName = 'SidebarAvatar'
