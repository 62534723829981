import { useGetTopBarPosition } from '@hooks/useGetTopBarPosition'
import { useStyles } from '@hooks/useStyles'
import { FC, ReactNode } from 'react'

import styles from './StickyContainer.module.scss'

export interface StickyContainerProps {
  className?: string | string[]
  children: ReactNode
  isPinned?: boolean
  'data-testid'?: string
}

export const StickyContainer: FC<StickyContainerProps> = ({
  className = '',
  children,
  isPinned,
  'data-testid': dataTestId = 'sticky-container'
}) => {
  const topbarPosition = useGetTopBarPosition()

  const elementStyles = useStyles(
    {
      [styles['sticky-container']]: true,
      [styles['sticky-container__pinned']]: isPinned
    },
    className
  )

  return (
    <div
      className={elementStyles}
      data-testid={dataTestId}
      style={{ top: topbarPosition }}
    >
      {children}
    </div>
  )
}

StickyContainer.displayName = 'StickyContainer'
