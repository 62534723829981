import { ErrorBoundaryFallback } from '@components/ErrorBoundaryFallback'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useUserContext } from '@contexts/user'
import { LimitBar } from '@landingi/landingi-ui-kit'
import { useGetCredits } from '@services/credits/useGetCredits'
import { useGetAccountLimits } from '@services/limits'
import {
  Badge,
  Divider,
  Dropdown,
  Icon,
  SidebarItem,
  Spacer,
  Spreader,
  Text
} from '@ui-kit'
import { FC, Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BuyCreditsButton } from './components/Buttons/BuyCreditsButton'
import { ExtendLink } from './components/Buttons/ExtendLink'
import { UpgradeButton } from './components/Buttons/UpgradeButton'
import { Credits } from './components/Credits'
import styles from './MyUsage.module.scss'

interface MyUsageProps {
  isSidebarMini: boolean
}

export const MyUsage: FC<MyUsageProps> = ({ isSidebarMini }) => {
  const { t } = useTranslation()

  const [dropdownIsOpen, setDropdownOpen] = useState(false)

  const {
    landingPages,
    uniqueVisits,
    domains,
    subaccounts,
    isWarning,
    isAlert,
    error
  } = useGetAccountLimits()

  const { credits, error: creditsError } = useGetCredits()

  const { isAgency, isNormal, hasFreeTrial } = useUserContext()

  const hasAccessToSubaccountsLimit = isAgency && subaccounts
  const hasAccessToUniqueUsersLimit = (isAgency || isNormal) && uniqueVisits
  const hasAccessToDomainsLimit = (isAgency || isNormal) && domains
  const hasAccessToLandingPagesLimit =
    useFeatureFlags('PRICING_2022') && landingPages

  const freeTrialRegularLimit = {
    regularLimit: uniqueVisits?.regularLimit,
    tooltipInQuantity: t('topbar.unique-visitors.tooltip')
  }

  const isLimitBadge = isWarning || isAlert

  const badgeVariant = () => {
    if (isAlert) {
      return 'error-filled'
    }

    if (isWarning) {
      return 'warning-filled'
    }

    return undefined
  }

  const myUsageFakeTrigger = () => (
    <div className={styles['my-usage__trigger']} />
  )

  return (
    <div className={styles['my-usage']}>
      <SidebarItem
        className={styles['my-usage__item']}
        id='sidebar-my-usage'
        icon='icon-speed'
        size={12}
        pathname='#'
        name={t('topbar.my-usage')}
        isMini={isSidebarMini}
        isActive={dropdownIsOpen}
        hasCustomIcon
      >
        {isLimitBadge ? (
          <Fragment>
            <Badge
              variant={badgeVariant()}
              weight={600}
              radius={250}
              data-testid='limit-badge'
            >
              <Text size={10} weight={600} color='white' lineHeight='0.8'>
                LIMIT
              </Text>
            </Badge>

            <Spreader spread={5} />
          </Fragment>
        ) : null}

        <Icon
          icon={`icon-chevron-${dropdownIsOpen ? 'left' : 'right'}`}
          size={10}
          color='neutral-6'
        />
      </SidebarItem>

      <Dropdown
        trigger={myUsageFakeTrigger}
        size='auto'
        placement='bottom-end'
        data-testid='my-usage'
        onOpen={() => setDropdownOpen(true)}
        onClose={() => setDropdownOpen(false)}
      >
        {({ close }) => (
          <div className={styles['my-usage__content']}>
            {error || creditsError ? (
              <ErrorBoundaryFallback />
            ) : (
              <Fragment>
                {hasAccessToDomainsLimit ? (
                  <Fragment>
                    <LimitBar
                      limitText={t('topbar.custom-domains')}
                      limit={domains.domainsLimit}
                      quantity={domains.activeOwnDomainsCount}
                      tooltipInQuantity={t('topbar.custom-domains.tooltip')}
                      padding='none'
                    />

                    <Spacer space={20} />
                  </Fragment>
                ) : null}

                {hasAccessToUniqueUsersLimit ? (
                  <Fragment>
                    <LimitBar
                      limitText={t('topbar.unique-visitors')}
                      limit={uniqueVisits.uniqueVisitsLimit}
                      quantity={uniqueVisits.uniqueVisitsCount}
                      padding='none'
                      {...(hasFreeTrial && freeTrialRegularLimit)}
                    />

                    <Spacer space={20} />
                  </Fragment>
                ) : null}

                {hasAccessToLandingPagesLimit ? (
                  <Fragment>
                    <LimitBar
                      limitText={t('topbar.published-landing-pages')}
                      limit={landingPages.landingPagePublicationLimit}
                      quantity={landingPages.landingPagePublicationCount}
                      padding='none'
                    />

                    <Spacer space={20} />
                  </Fragment>
                ) : null}

                {hasAccessToSubaccountsLimit ? (
                  <Fragment>
                    <LimitBar
                      limitText={t('topbar.active-subaccounts')}
                      limit={subaccounts.subaccountsActiveLimit}
                      quantity={subaccounts.subaccountsActiveCount}
                      padding='none'
                    />

                    <Spacer space={20} />
                  </Fragment>
                ) : null}

                <ExtendLink />

                <Divider />

                <Spacer space={20} />

                {credits !== undefined ? <Credits credits={credits} /> : null}

                <BuyCreditsButton close={close} />

                <UpgradeButton />
              </Fragment>
            )}
          </div>
        )}
      </Dropdown>
    </div>
  )
}

MyUsage.displayName = 'MyUsage'
