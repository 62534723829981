import { useStyles } from '@hooks/useStyles'
import { Spacer } from '@ui-kit/Spacer'
import { Spreader } from '@ui-kit/Spreader'
import React, { FC, Fragment, ReactElement, ReactNode } from 'react'
import { Column, Row } from 'simple-flexbox'

import styles from './VerticalBanner.module.scss'

type VerticalBannerVariant =
  | 'info'
  | 'calmviolet'
  | 'warning'
  | 'almostcyan'
  | 'warmpink'
  | 'success'

export interface VerticalBannerProps {
  children: ReactNode
  variant: VerticalBannerVariant
  closeButton?: ReactElement
  ctaButton?: ReactElement
  className?: string | string[]
  'data-testid'?: string
}

export const VerticalBanner: FC<VerticalBannerProps> = ({
  children,
  variant,
  closeButton,
  ctaButton,
  className = '',
  'data-testid': dataTestId = 'verticalBanner'
}) => {
  const verticalBannerStyles = useStyles(
    {
      [styles['vertical-banner']]: true,
      [styles[`vertical-banner--variant-${variant}`]]: variant
    },
    className
  )

  const shouldRenderButtons = closeButton || ctaButton

  return (
    <Column className={verticalBannerStyles} data-testid={dataTestId}>
      <Row>{children}</Row>

      <Spacer space={5} />

      {shouldRenderButtons && (
        <Row justifyContent='flex-end'>
          {closeButton && (
            <Fragment>
              {React.cloneElement(closeButton, {
                size: 12,
                variant: 'text-primary'
              })}

              <Spreader spread={5} />
            </Fragment>
          )}

          {ctaButton &&
            React.cloneElement(ctaButton, {
              size: 12,
              variant: 'text-primary'
            })}
        </Row>
      )}
    </Column>
  )
}
