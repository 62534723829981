import { Acl } from '@components/Acl'
import { lazy } from '@helpers/lazy'
import { FullLayout } from '@layouts/FullLayout'
import { ORDERS } from '@routes/path'

const OrdersPage = lazy(() =>
  import('@pages/Orders/routes/Orders').then(({ OrdersPage }) => ({
    default: OrdersPage
  }))
)

export const routes = [
  {
    path: ORDERS.ORDERS,
    element: (
      <Acl acl='ecommerce.sidebar_orders'>
        <FullLayout />
      </Acl>
    ),
    children: [
      {
        path: ORDERS.ORDERS,
        element: <OrdersPage />
      }
    ]
  }
]
