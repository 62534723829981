import { Button } from '@ui-kit/Button'
import { Dropdown } from '@ui-kit/Dropdown'
import { DropdownElement } from '@ui-kit/DropdownList'
import { Icon } from '@ui-kit/Icon'
import { Spreader } from '@ui-kit/Spreader'
import { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { MonthType } from './types'

interface MonthDropdownProps {
  handleSelect: (month: MonthType) => void
  month: MonthType
}

export const MonthDropdown: FC<MonthDropdownProps> = ({
  handleSelect,
  month
}) => {
  const { t } = useTranslation()
  const monthValues = [
    { value: 0 as const, label: t('month.january') },
    { value: 1 as const, label: t('month.february') },
    { value: 2 as const, label: t('month.march') },
    { value: 3 as const, label: t('month.april') },
    { value: 4 as const, label: t('month.may') },
    { value: 5 as const, label: t('month.june') },
    { value: 6 as const, label: t('month.july') },
    { value: 7 as const, label: t('month.august') },
    { value: 8 as const, label: t('month.september') },
    { value: 9 as const, label: t('month.october') },
    { value: 10 as const, label: t('month.november') },
    { value: 11 as const, label: t('month.december') }
  ]

  const trigger = ({ isOpen }: { isOpen: boolean }) => (
    <Button variant='text-neutral' size={12} data-testid='month-picker'>
      {monthValues[month].label}
      <Spreader spread={10} />
      <Icon icon={isOpen ? 'icon-angle-up' : 'icon-angle-down'} />
    </Button>
  )

  return (
    <Dropdown
      size='small'
      trigger={trigger}
      placement='bottom-start'
      overflowStyle={{ maxHeight: '300px', overflowY: 'auto' }}
    >
      {({ close }) => (
        <Fragment>
          {monthValues.map(({ value, label }) => (
            <DropdownElement
              key={value}
              onClick={() => {
                handleSelect(value)
                close()
              }}
            >
              {label}
            </DropdownElement>
          ))}
        </Fragment>
      )}
    </Dropdown>
  )
}
