import MicrosoftIcon from '@images/registration/microsoft_icon.svg'
import { Button, Image, Spreader, Text } from '@ui-kit'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

export const MicrosoftSignUpButton = () => {
  const { t } = useTranslation()

  const onClick = useCallback(() => {
    // TODO - sign up with Microsoft
  }, [])

  return (
    <Button variant='outlined' onClick={onClick} size={16}>
      <Row alignItems='center' justifyContent='center'>
        <Image src={MicrosoftIcon} width='16px' height='16px' />

        <Spreader spread={10} />

        <Text size={16} weight={600} color='primary' lineHeight='normal'>
          {t('registration.flow.sign.up.microsoft')}
        </Text>
      </Row>
    </Button>
  )
}

MicrosoftSignUpButton.displayName = 'MicrosoftSignUpButton'
