import { OPEN_PRODUCT_UPDATES_DRAWER } from '@components/ProductUpdates/constants/constants'
import { TOPBAR_PRODUCT_UPDATES_CLICK_EVENT_NAME } from '@constants/mixpanel'
import { useUserContext } from '@contexts/user'
import { mixpanelEvent } from '@services/mixpanel'
import { Badge, Button, Icon, Spreader, Tooltip } from '@ui-kit'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const ProductUpdates = () => {
  const { t } = useTranslation()

  const { isNewNotification } = useUserContext()

  const [isAlert, setAlert] = useState(isNewNotification)

  const handleOpenProductUpdates = () => {
    document.dispatchEvent(new Event(OPEN_PRODUCT_UPDATES_DRAWER))

    setAlert(false)

    mixpanelEvent({ name: TOPBAR_PRODUCT_UPDATES_CLICK_EVENT_NAME })
  }

  return (
    <Tooltip
      content={t('topbar.productupdates.tooltip.content')}
      placement='bottom'
    >
      <Button
        variant='text-neutral'
        onClick={handleOpenProductUpdates}
        data-testid='product-update-button'
        isSquare={!isAlert}
      >
        <Icon icon='icon-bullhorn' />

        {isAlert && (
          <Fragment>
            <Spreader spread={10} />

            <Badge weight={600} radius={250}>
              NEW
            </Badge>
          </Fragment>
        )}
      </Button>
    </Tooltip>
  )
}

ProductUpdates.displayName = 'TopbarProductUpdates'
