export const contentGenerationLanguages = [
  {
    en: 'English',
    pl: 'angielski',
    pt: 'inglês'
  },
  {
    en: 'Polish',
    pl: 'polski',
    pt: 'polonês'
  },
  {
    en: 'Afrikaans',
    pl: 'afrykański',
    pt: 'africâner'
  },
  {
    en: 'Albanian',
    pl: 'albański',
    pt: 'albanês'
  },
  {
    en: 'Amharic',
    pl: 'amharski',
    pt: 'amárico'
  },
  {
    en: 'Arabic',
    pl: 'arabski',
    pt: 'árabe'
  },
  {
    en: 'Armenian',
    pl: 'armeński',
    pt: 'armênio'
  },
  {
    en: 'Assamese',
    pl: 'assamski',
    pt: 'assamês'
  },
  {
    en: 'Azerbaijani',
    pl: 'azerski',
    pt: 'azerbaijano'
  },
  {
    en: 'Basque',
    pl: 'baskijski',
    pt: 'basco'
  },
  {
    en: 'Belarusian',
    pl: 'białoruski',
    pt: 'bielorrusso'
  },
  {
    en: 'Bengali',
    pl: 'bengalski',
    pt: 'bengali'
  },
  {
    en: 'Bhojpuri',
    pl: 'bhojpuri',
    pt: 'bhojpuri'
  },
  {
    en: 'Bosnian',
    pl: 'bośniacki',
    pt: 'bósnio'
  },
  {
    en: 'Bulgarian',
    pl: 'bułgarski',
    pt: 'búlgaro'
  },
  {
    en: 'Burmese',
    pl: 'birmański',
    pt: 'birmanês'
  },
  {
    en: 'Cantonese',
    pl: 'kantoński',
    pt: 'cantonês'
  },
  {
    en: 'Catalan',
    pl: 'kataloński',
    pt: 'catalão'
  },
  {
    en: 'Cebuano',
    pl: 'cebuański',
    pt: 'cebuano'
  },
  {
    en: 'Chewa',
    pl: 'chewa',
    pt: 'chewa'
  },
  {
    en: 'Chhattisgarhi',
    pl: 'chhattisgarhi',
    pt: 'chhattisgarhi'
  },
  {
    en: 'Chinese',
    pl: 'chiński',
    pt: 'chinês'
  },
  {
    en: 'Corsican',
    pl: 'korsykański',
    pt: 'córsico'
  },
  {
    en: 'Croatian',
    pl: 'chorwacki',
    pt: 'croata'
  },
  {
    en: 'Czech',
    pl: 'czeski',
    pt: 'tcheco'
  },
  {
    en: 'Danish',
    pl: 'duński',
    pt: 'dinamarquês'
  },
  {
    en: 'Dari',
    pl: 'dari',
    pt: 'dari'
  },
  {
    en: 'Dutch',
    pl: 'niderlandzki',
    pt: 'holandês'
  },
  {
    en: 'Dzongkha',
    pl: 'dzongkha',
    pt: 'dzongkha'
  },
  {
    en: 'Esperanto',
    pl: 'esperanto',
    pt: 'esperanto'
  },
  {
    en: 'Estonian',
    pl: 'estoński',
    pt: 'estoniano'
  },
  {
    en: 'Filipino (Tagalog)',
    pl: 'filipiński (tagalog)',
    pt: 'filipino (tagalo)'
  },
  {
    en: 'Finnish',
    pl: 'fiński',
    pt: 'finlandês'
  },
  {
    en: 'French',
    pl: 'francuski',
    pt: 'francês'
  },
  {
    en: 'Frisian',
    pl: 'fryzyjski',
    pt: 'frísio'
  },
  {
    en: 'Galician',
    pl: 'galicyjski',
    pt: 'galego'
  },
  {
    en: 'Georgian',
    pl: 'gruziński',
    pt: 'georgiano'
  },
  {
    en: 'German',
    pl: 'niemiecki',
    pt: 'alemão'
  },
  {
    en: 'Greek',
    pl: 'grecki',
    pt: 'grego'
  },
  {
    en: 'Gujarati',
    pl: 'gudźaracki',
    pt: 'guzerate'
  },
  {
    en: 'Haitian Creole',
    pl: 'haitański kreolski',
    pt: 'crioulo haitiano'
  },
  {
    en: 'Hausa',
    pl: 'hausa',
    pt: 'hausa'
  },
  {
    en: 'Hawaiian',
    pl: 'hawajski',
    pt: 'havaiano'
  },
  {
    en: 'Hebrew',
    pl: 'hebrajski',
    pt: 'hebraico'
  },
  {
    en: 'Hindi',
    pl: 'hindi',
    pt: 'hindi'
  },
  {
    en: 'Hmong',
    pl: 'hmong',
    pt: 'hmong'
  },
  {
    en: 'Hungarian',
    pl: 'węgierski',
    pt: 'húngaro'
  },
  {
    en: 'Icelandic',
    pl: 'islandzki',
    pt: 'islandês'
  },
  {
    en: 'Igbo',
    pl: 'igbo',
    pt: 'igbo'
  },
  {
    en: 'Ilocano',
    pl: 'ilokano',
    pt: 'ilocano'
  },
  {
    en: 'Indonesian',
    pl: 'indonezyjski',
    pt: 'indonésio'
  },
  {
    en: 'Irish',
    pl: 'irlandzki',
    pt: 'irlandês'
  },
  {
    en: 'Italian',
    pl: 'włoski',
    pt: 'italiano'
  },
  {
    en: 'Japanese',
    pl: 'japoński',
    pt: 'japonês'
  },
  {
    en: 'Javanese',
    pl: 'jawajski',
    pt: 'javanês'
  },
  {
    en: 'Kannada',
    pl: 'kannada',
    pt: 'kannada'
  },
  {
    en: 'Kashmiri',
    pl: 'kaszmirski',
    pt: 'caxemira'
  },
  {
    en: 'Kazakh',
    pl: 'kazachski',
    pt: 'cazaque'
  },
  {
    en: 'Khmer',
    pl: 'khmerski',
    pt: 'khmer'
  },
  {
    en: 'Kinyarwanda',
    pl: 'kinyarwanda',
    pt: 'kinyarwanda'
  },
  {
    en: 'Kirundi',
    pl: 'kirundi',
    pt: 'kirundi'
  },
  {
    en: 'Korean',
    pl: 'koreański',
    pt: 'coreano'
  },
  {
    en: 'Kurdish',
    pl: 'kurdyjski',
    pt: 'curdo'
  },
  {
    en: 'Kyrgyz',
    pl: 'kirgiski',
    pt: 'quirguiz'
  },
  {
    en: 'Lao',
    pl: 'laotański',
    pt: 'lao'
  },
  {
    en: 'Latin',
    pl: 'łacina',
    pt: 'latim'
  },
  {
    en: 'Latvian',
    pl: 'łotewski',
    pt: 'letão'
  },
  {
    en: 'Lithuanian',
    pl: 'litewski',
    pt: 'lituano'
  },
  {
    en: 'Luxembourgish',
    pl: 'luksemburski',
    pt: 'luxemburguês'
  },
  {
    en: 'Macedonian',
    pl: 'macedoński',
    pt: 'macedônio'
  },
  {
    en: 'Malagasy',
    pl: 'malgaski',
    pt: 'malgaxe'
  },
  {
    en: 'Malay',
    pl: 'malajski',
    pt: 'malaio'
  },
  {
    en: 'Malayalam',
    pl: 'malajalam',
    pt: 'malaiala'
  },
  {
    en: 'Maltese',
    pl: 'maltański',
    pt: 'maltês'
  },
  {
    en: 'Maori',
    pl: 'maoryski',
    pt: 'maori'
  },
  {
    en: 'Marathi',
    pl: 'marathi',
    pt: 'marathi'
  },
  {
    en: 'Mongolian',
    pl: 'mongolski',
    pt: 'mongol'
  },
  {
    en: 'Nepali',
    pl: 'nepalski',
    pt: 'nepalês'
  },
  {
    en: 'Norwegian',
    pl: 'norweski',
    pt: 'norueguês'
  },
  {
    en: 'Oriya',
    pl: 'orija',
    pt: 'oriá'
  },
  {
    en: 'Oromo',
    pl: 'oromo',
    pt: 'oromo'
  },
  {
    en: 'Pashto',
    pl: 'paszto',
    pt: 'pashto'
  },
  {
    en: 'Persian',
    pl: 'perski',
    pt: 'persa'
  },
  {
    en: 'Portuguese',
    pl: 'portugalski',
    pt: 'português'
  },
  {
    en: 'Punjabi',
    pl: 'pendżabski',
    pt: 'punjabi'
  },
  {
    en: 'Romanian',
    pl: 'rumuński',
    pt: 'romeno'
  },
  {
    en: 'Russian',
    pl: 'rosyjski',
    pt: 'russo'
  },
  {
    en: 'Samoan',
    pl: 'samoański',
    pt: 'samoano'
  },
  {
    en: 'Scots Gaelic',
    pl: 'szkocki gaelicki',
    pt: 'gaélico escocês'
  },
  {
    en: 'Serbian',
    pl: 'serbski',
    pt: 'sérvio'
  },
  {
    en: 'Sesotho',
    pl: 'sesotho',
    pt: 'sesotho'
  },
  {
    en: 'Shona',
    pl: 'shona',
    pt: 'shona'
  },
  {
    en: 'Sindhi',
    pl: 'sindhi',
    pt: 'sindi'
  },
  {
    en: 'Sinhala',
    pl: 'singaleski',
    pt: 'cingalês'
  },
  {
    en: 'Slovak',
    pl: 'słowacki',
    pt: 'eslovaco'
  },
  {
    en: 'Slovenian',
    pl: 'słoweński',
    pt: 'esloveno'
  },
  {
    en: 'Somali',
    pl: 'somalijski',
    pt: 'somali'
  },
  {
    en: 'Spanish',
    pl: 'hiszpański',
    pt: 'espanhol'
  },
  {
    en: 'Sundanese',
    pl: 'sundajski',
    pt: 'sundanês'
  },
  {
    en: 'Swahili',
    pl: 'suahili',
    pt: 'suaíli'
  },
  {
    en: 'Swedish',
    pl: 'szwedzki',
    pt: 'sueco'
  },
  {
    en: 'Tajik',
    pl: 'tadżycki',
    pt: 'tajique'
  },
  {
    en: 'Tamil',
    pl: 'tamilski',
    pt: 'tâmil'
  },
  {
    en: 'Tatar',
    pl: 'tatarski',
    pt: 'tártaro'
  },
  {
    en: 'Telugu',
    pl: 'telugu',
    pt: 'telugu'
  },
  {
    en: 'Thai',
    pl: 'tajski',
    pt: 'tailandês'
  },
  {
    en: 'Tibetan',
    pl: 'tybetański',
    pt: 'tibetano'
  },
  {
    en: 'Tigrinya',
    pl: 'tigrinia',
    pt: 'tigrínia'
  },
  {
    en: 'Tongan',
    pl: 'tongański',
    pt: 'tonganês'
  },
  {
    en: 'Turkish',
    pl: 'turecki',
    pt: 'turco'
  },
  {
    en: 'Turkmen',
    pl: 'turkmeński',
    pt: 'turcomeno'
  },
  {
    en: 'Ukrainian',
    pl: 'ukraiński',
    pt: 'ucraniano'
  },
  {
    en: 'Urdu',
    pl: 'urdu',
    pt: 'urdu'
  },
  {
    en: 'Uyghur',
    pl: 'ujgurski',
    pt: 'uigur'
  },
  {
    en: 'Uzbek',
    pl: 'uzbecki',
    pt: 'uzbeque'
  },
  {
    en: 'Vietnamese',
    pl: 'wietnamski',
    pt: 'vietnamita'
  },
  {
    en: 'Welsh',
    pl: 'walijski',
    pt: 'galês'
  },
  {
    en: 'Wolof',
    pl: 'wolof',
    pt: 'wolof'
  },
  {
    en: 'Xhosa',
    pl: 'xhosa',
    pt: 'xhosa'
  },
  {
    en: 'Yiddish',
    pl: 'jidysz',
    pt: 'iídiche'
  },
  {
    en: 'Yoruba',
    pl: 'joruba',
    pt: 'iorubá'
  },
  {
    en: 'Zulu',
    pl: 'zulu',
    pt: 'zulu'
  }
]

export const toneOfVoiceOptions = [
  {
    value: 'professional',
    label: 'professional'
  },
  {
    value: 'casual',
    label: 'casual'
  },
  {
    value: 'humorous',
    label: 'humorous'
  },
  {
    value: 'friendly',
    label: 'friendly'
  },
  {
    value: 'helpful',
    label: 'helpful'
  },
  {
    value: 'informative',
    label: 'informative'
  },
  {
    value: 'convincing',
    label: 'convincing'
  }
]
