import { useCopyToClipboard } from '@hooks/useCopyToClipboard'
import { Button } from '@ui-kit/Button'
import { Icon } from '@ui-kit/Icon'
import { Spreader } from '@ui-kit/Spreader'
import createTheme from '@uiw/codemirror-themes'
import ReactCodeMirror from '@uiw/react-codemirror'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './CodeSnippet.module.scss'

interface CodeSnippetProps {
  value: string
  'data-testid'?: string
}

export const CodeSnippet: FC<CodeSnippetProps> = ({
  value,
  'data-testid': dataTestId = 'code-snippet'
}) => {
  const { t } = useTranslation()

  const [handleCopy] = useCopyToClipboard(value)

  const currentTheme = createTheme({
    theme: 'light',
    settings: {
      background: '#F9F9F9'
    },
    styles: []
  })

  return (
    <ReactCodeMirror
      className={styles['code-snippet']}
      value={value}
      theme={currentTheme}
      width='100%'
      editable={false}
      readOnly
      basicSetup={{
        lineNumbers: false,
        foldGutter: false,
        highlightActiveLine: false
      }}
      data-testid={dataTestId}
    >
      <Button
        className={styles['code-snippet__copy-button']}
        variant='text-neutral'
        size={12}
        onClick={handleCopy}
        data-testid={`${dataTestId}-copy-button`}
      >
        <Icon icon='icon-duplicate' />

        <Spreader spread={5} />

        {t('word.copy.code')}
      </Button>
    </ReactCodeMirror>
  )
}

CodeSnippet.displayName = 'CodeSnippet'
