import { LongLogo } from '@components/Logo'
import { useSidebarContext } from '@contexts/sidebar'
import { useUserContext } from '@contexts/user'
import { getLocalStorage, setLocalStorage } from '@helpers/storage'
import { useStyles } from '@hooks/useStyles'
import { LANDINGS } from '@routes/path'
import { Button, Spreader } from '@ui-kit'
import { AnimatePresence, motion } from 'framer-motion'
import { NavLink } from 'react-router-dom'
import { Row } from 'simple-flexbox'

import styles from './Logo.module.scss'

export const Logo = () => {
  const { userLogo } = useUserContext()

  const { isSidebarMobileOpen, toggleSidebarMobileOpen } = useSidebarContext()

  const hasAnimation = !getLocalStorage('lp-disableAnimation')

  if (hasAnimation) {
    setLocalStorage('lp-disableAnimation', true)
  }

  const customLogoHiddenStyles = useStyles({
    [styles['customLogo__toggle-hidden']]: isSidebarMobileOpen
  })

  return (
    <Row alignItems='center'>
      <Button
        className={customLogoHiddenStyles}
        icon='icon-menu'
        variant='text-neutral'
        onClick={toggleSidebarMobileOpen}
        data-testid='sidebar-toggle-button'
      />

      <Spreader spread={20} />

      <NavLink to={LANDINGS.LANDINGS}>
        {userLogo === 'landingi' ? (
          <LongLogo hasAnimation={hasAnimation} />
        ) : (
          <AnimatePresence>
            <motion.img
              className={styles.customLogo}
              src={userLogo || ''}
              variants={{
                normal: { maxWidth: 175, x: 0 }
              }}
              initial='normal'
              animate='normal'
              transition={{ duration: 0.5, ease: [0.65, 0, 0.3, 1] }}
              data-testid='user-logo'
            />
          </AnimatePresence>
        )}
      </NavLink>
    </Row>
  )
}
