import { removeLocalStorage, setLocalStorage } from '@helpers/storage'
import { Checkbox } from '@ui-kit'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { HIDE_CHECKBOX_LOCAL_STORAGE_KEY } from './data'

export const DoNotShowAgainCheckbox = () => {
  const { t } = useTranslation()
  const [isChecked, setIsChecked] = useState(false)

  const handleCheckboxState = () => {
    if (isChecked) {
      setIsChecked(false)
      removeLocalStorage(HIDE_CHECKBOX_LOCAL_STORAGE_KEY)
    } else {
      setIsChecked(true)
      setLocalStorage(HIDE_CHECKBOX_LOCAL_STORAGE_KEY, 'true')
    }
  }

  return (
    <Checkbox
      id='figmaInstructionCheckbox'
      checked={isChecked}
      onChange={handleCheckboxState}
      i18n={{ label: t('create.landing.figma.instruction.hide') }}
    />
  )
}
