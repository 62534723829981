import { NeutralColor, PrimaryColor } from '@ui-kit/types/color'
import { useLocation } from 'react-router-dom'

import { STATUS } from '../constants'

type ColorsType = PrimaryColor | NeutralColor

const { ACTIVE, INACTIVE } = STATUS

export const useGetStatusInfo = (pathname: string, isActive?: boolean) => {
  const location = useLocation()

  const isActivePathname = isActive ?? location.pathname.startsWith(pathname)

  const status = isActivePathname ? ACTIVE : INACTIVE
  const statusColor = (isActivePathname ? 'primary' : 'neutral-6') as ColorsType

  return { status, statusColor }
}
