import i18next, { t } from 'i18next'

const allowedLanguages = ['pl', 'en', 'pt', 'es']

export const getBrowserLanguage = () => {
  const browserLang = window.navigator?.language?.trim()

  const lang = browserLang?.split(/-|_/)[0]

  if (allowedLanguages.includes(lang)) {
    return lang
  }

  return 'en'
}

export const intlFormatDate = (date: string | Date | number, params = {}) =>
  t('intlDateTime', {
    val: new Date(date),
    formatParams: {
      val: params
    }
  })

export const intlFormatDateRange = (
  startDate: Date | number,
  endDate: Date | number,
  params = {}
) => {
  const dateTimeFormat = new Intl.DateTimeFormat(i18next.language, params)

  return dateTimeFormat.formatRange(startDate, endDate)
}
