import { Tooltip, TooltipProps } from '@ui-kit/Tooltip'

export const getTrimmedLabel = ({
  label,
  maxNumberOfCharacters
}: {
  label: string
  maxNumberOfCharacters: number
}) => {
  const trimmedLabel =
    label.length > maxNumberOfCharacters
      ? `${label.slice(0, maxNumberOfCharacters).trim()}...`
      : label

  const TooltipWithFullLabel = ({
    children,
    ...props
  }: Omit<TooltipProps, 'disabled' | 'content'>) => (
    <Tooltip
      content={label}
      disabled={label.length <= maxNumberOfCharacters}
      {...props}
    >
      {children}
    </Tooltip>
  )

  return { trimmedLabel, TooltipWithFullLabel }
}
