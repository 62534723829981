import BonduelleLogo from '@images/registration/bonduelle_logo.png'
import CBRELogo from '@images/registration/cbre_logo.png'
import CreditAgricoleLogo from '@images/registration/credit_agricole_logo.png'
import DekraLogo from '@images/registration/dekra_logo.png'
import GetLandyXLandingiImage from '@images/registration/getlandy/get_landy_x_landingi.png'
import OperaLogo from '@images/registration/opera_logo.png'
import WarnerBrosLogo from '@images/registration/warner_bros_logo.png'
import { useAnimationContext } from '@pages/Authentication/contexts/animation'
import { Heading, Image, List, Spacer, Spreader } from '@ui-kit'
import { ListItem } from '@ui-kit/List/ListItem'
import { motion } from 'framer-motion'
import { Fragment, memo } from 'react'
import { Trans } from 'react-i18next'
import { Row } from 'simple-flexbox'
import { v4 as uuidv4 } from 'uuid'

import styles from './RefreshedColumnContent.module.scss'

const GetLandy = () => {
  const { secondColumnControls } = useAnimationContext()

  const listItems = [
    'registration.flow.getlandy.list.item1',
    'registration.flow.getlandy.list.item2',
    'registration.flow.getlandy.list.item3',
    'registration.flow.getlandy.list.item4'
  ]

  return (
    <motion.div
      className={styles['column-content']}
      animate={secondColumnControls}
    >
      <Image src={GetLandyXLandingiImage} width='300px' />

      <Spacer space={40} />

      <Heading level={1}>
        <Trans i18nKey='registration.flow.getlandy.title' />
      </Heading>

      <Spacer space={25} />

      <List type='checkmark'>
        {listItems.map(item => {
          return (
            <Fragment key={uuidv4()}>
              <ListItem size={16} description={<Trans i18nKey={item} />} />

              <Spacer space={15} />
            </Fragment>
          )
        })}
      </List>

      <Spacer space={25} />

      <Row>
        <Image src={BonduelleLogo} height='20px' />

        <Spreader spread={20} />

        <Image src={CreditAgricoleLogo} height='20px' />

        <Spreader spread={20} />

        <Image src={CBRELogo} height='20px' />
      </Row>

      <Spacer space={25} />

      <Row>
        <Image src={DekraLogo} height='20px' />

        <Spreader spread={20} />

        <Image src={OperaLogo} height='20px' />

        <Spreader spread={20} />

        <Image src={WarnerBrosLogo} height='20px' />
      </Row>
    </motion.div>
  )
}

GetLandy.displayName = 'GetLandy'

export default memo(GetLandy)
