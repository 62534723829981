import { Button, Input, Select, Spacer, Textarea } from '@ui-kit'
import { ChangeEvent, ChangeEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

import { useWizardContext } from '../contexts'
import { contentGenerationLanguages, toneOfVoiceOptions } from './constants'
import styles from './ContentGenerationPoC.module.scss'

export const ContentGenerationPoC = () => {
  const { t } = useTranslation()
  const { formik, handleSubmit } = useWizardContext()
  const { values, setFieldValue, isSubmitting } = formik
  const {
    productName,
    productDescription,
    productBenefits,
    receiversDescription,
    contentTone,
    contentLanguage
  } = values

  const handleClick = () => {
    handleSubmit()
  }

  const handleChangeProductName: ChangeEventHandler<HTMLInputElement> = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setFieldValue('productName', event.target.value)
  }

  const handleChangeProductDescription: ChangeEventHandler<
    HTMLTextAreaElement
  > = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setFieldValue('productDescription', event.target.value)
  }

  const handleChangeProductBenefits: ChangeEventHandler<HTMLTextAreaElement> = (
    event: ChangeEvent<HTMLTextAreaElement>
  ) => {
    setFieldValue('productBenefits', event.target.value)
  }

  const handleChangeReceiversDescription: ChangeEventHandler<
    HTMLTextAreaElement
  > = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setFieldValue('receiversDescription', event.target.value)
  }

  const handleChangeContentTone = (value: string) => {
    setFieldValue('contentTone', value)
  }

  const handleChangeContentLanguage = (value: string) => {
    setFieldValue('contentLanguage', value)
  }

  return (
    <Column alignItems='center' justifyContent='center'>
      <div className={styles['content-generation-poc-container']}>
        <Spacer space={100} />

        <Input
          size={16}
          value={productName}
          onChange={handleChangeProductName}
          id='productName'
          autoFocus
          i18n={{
            label: 'Product name'
          }}
        />

        <Spacer space={30} />

        <Textarea
          value={productDescription}
          onChange={handleChangeProductDescription}
          id='productDescription'
          i18n={{
            label: 'Product description'
          }}
        />

        <Spacer space={30} />

        <Textarea
          value={productBenefits}
          onChange={handleChangeProductBenefits}
          id='productBenefits'
          i18n={{
            label: 'Product benefits'
          }}
        />

        <Spacer space={30} />

        <Textarea
          value={receiversDescription}
          onChange={handleChangeReceiversDescription}
          id='receiversDescription'
          autoFocus
          i18n={{
            label: 'Receivers description'
          }}
        />

        <Spacer space={30} />

        <Select
          options={toneOfVoiceOptions}
          value={contentTone}
          i18n={{
            placeholder: 'Content tone'
          }}
          onChange={handleChangeContentTone}
          border={{ bottom: 'neutral-2' }}
        />

        <Spacer space={30} />

        <Select
          options={contentGenerationLanguages.map(language => ({
            label: language.en,
            value: language.en
          }))}
          value={contentLanguage}
          i18n={{
            placeholder: 'Content language'
          }}
          onChange={handleChangeContentLanguage}
          border={{ bottom: 'neutral-2' }}
        />

        <Spacer space={30} />

        <Row justifyContent='end'>
          <Button
            icon='icon-arrow-right'
            onClick={handleClick}
            isLoading={isSubmitting}
          >
            {t('landings.create.wizard.next.step')}
          </Button>
        </Row>
      </div>
    </Column>
  )
}
