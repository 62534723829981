import { useEffect, useState } from 'react'

export const useGetTopBarPosition = () => {
  const [topbarPosition, setTopbarPosition] = useState(0)

  const updateTopbarPosition = () => {
    const topbar = document
      .getElementById('spa-topbar')
      ?.getBoundingClientRect()

    if (topbar) {
      setTopbarPosition(topbar.bottom || 0)
    }
  }

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      updateTopbarPosition()
    })

    resizeObserver.observe(document.documentElement)

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  return topbarPosition
}
