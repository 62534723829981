import { useStyles } from '@hooks/useStyles'
import { Close } from '@ui-kit/Close'
import { Image } from '@ui-kit/Image'
import { Spreader } from '@ui-kit/Spreader'
import { FC, Fragment, ReactNode } from 'react'
import { Row } from 'simple-flexbox'

import styles from './HorizontalBanner.module.scss'

type HorizontalBannerVariant =
  | 'info'
  | 'calmviolet'
  | 'warning'
  | 'almostcyan'
  | 'warmpink'
  | 'success'
  | 'primary'

export interface HorizontalBannerProps {
  children: ReactNode
  variant: HorizontalBannerVariant
  closeAction?: () => void
  mainAction: () => void
  imageSrc: string
  className?: string | string[]
  'data-testid'?: string
}

export const HorizontalBanner: FC<HorizontalBannerProps> = ({
  children,
  variant = 'info',
  closeAction,
  mainAction,
  imageSrc,
  className = '',
  'data-testid': dataTestId = 'horizontalBanner'
}) => {
  const horizontalBannerStyles = useStyles(
    {
      [styles['horizontal-banner']]: true,
      [styles[`horizontal-banner--variant-${variant}`]]: variant
    },
    className
  )

  return (
    <Row
      className={horizontalBannerStyles}
      data-testid={dataTestId}
      alignItems='center'
      justifyContent='space-between'
    >
      <Image
        src={imageSrc}
        height='46px'
        width='46px'
        className={styles['horizontal-banner--image']}
      />

      <Row
        alignItems='baseline'
        onClick={mainAction}
        data-testid='main-horizontal-banner'
      >
        {children}
      </Row>

      {closeAction && (
        <Fragment>
          <Spreader spread={10} />

          <Close
            onClick={closeAction}
            size={12}
            data-testid='close-horizontal-banner'
          />
        </Fragment>
      )}
    </Row>
  )
}
