export const SOURCES_NAMES = {
  DEFAULT: 'default',
  CC: 'cc',
  TEMPLATES: 'templates',
  WORDPRESS: 'wordpress',
  AGENCY: 'agency',
  DT60: '60dt',
  AIP: 'aip',
  DSIM: 'dsim',
  SOSV: 'sosv',
  VC: 'vc',
  DT1: '1dt',
  CYFROWA_WYPRAWKA: 'cyfrowawyprawka',
  FAST_TONY: 'amft',
  GET_LANDY: 'getlandy'
}

const {
  DEFAULT,
  CC,
  TEMPLATES,
  WORDPRESS,
  AGENCY,
  DT60,
  AIP,
  DSIM,
  SOSV,
  VC,
  DT1,
  CYFROWA_WYPRAWKA,
  FAST_TONY,
  GET_LANDY
} = SOURCES_NAMES

export const sources = [
  DEFAULT,
  CC,
  TEMPLATES,
  WORDPRESS,
  AGENCY,
  DT60,
  AIP,
  DSIM,
  SOSV,
  VC,
  DT1,
  CYFROWA_WYPRAWKA,
  FAST_TONY,
  GET_LANDY
]
