import reverse_proxy_instruction_6_en from '@images/landings/publishingOptions/reverse_proxy/reverse_proxy_instruction_6_en.png'
import reverse_proxy_instruction_6_pl from '@images/landings/publishingOptions/reverse_proxy/reverse_proxy_instruction_6_pl.png'
import reverse_proxy_instruction_8_en from '@images/landings/publishingOptions/reverse_proxy/reverse_proxy_instruction_8_en.png'
import reverse_proxy_instruction_8_pl from '@images/landings/publishingOptions/reverse_proxy/reverse_proxy_instruction_8_pl.png'
import { CodeSnippet, Image, Spacer } from '@ui-kit'
import i18next, { TFunction } from 'i18next'
import { Fragment } from 'react'
import { Trans } from 'react-i18next'

export const reverseProxySteps = (t: TFunction<'translation', undefined>) => [
  {
    content: t('publishing.options.proxy.instruction.1')
  },
  {
    content: (
      <Trans
        i18nKey='publishing.options.proxy.instruction.2'
        components={{
          br: <Spacer space={15} />
        }}
      />
    )
  },
  {
    content: (
      <Trans
        i18nKey='publishing.options.proxy.instruction.3'
        components={{
          br: <Spacer space={15} />
        }}
      />
    )
  },
  {
    content: (
      <Trans
        i18nKey='publishing.options.proxy.instruction.4'
        components={{
          br: <Spacer space={15} />
        }}
      />
    )
  },
  {
    content: t('publishing.options.proxy.instruction.5')
  },
  {
    content: (
      <Fragment>
        <Trans i18nKey='publishing.options.proxy.instruction.6' />

        <Spacer space={20} />

        <Image
          src={
            i18next.language === 'pl'
              ? reverse_proxy_instruction_6_pl
              : reverse_proxy_instruction_6_en
          }
          width='100%'
        />
      </Fragment>
    )
  },
  {
    content: (
      <Fragment>
        <Trans i18nKey='publishing.options.proxy.instruction.7' />

        <Spacer space={20} />

        <CodeSnippet
          value={t('publishing.options.proxy.instruction.7.code.buttons')}
        />

        <Spacer space={20} />

        <CodeSnippet
          value={t('publishing.options.proxy.instruction.7.code.forms')}
        />
      </Fragment>
    )
  },
  {
    content: (
      <Fragment>
        <Trans i18nKey='publishing.options.proxy.instruction.8' />

        <Spacer space={20} />

        <Image
          src={
            i18next.language === 'pl'
              ? reverse_proxy_instruction_8_pl
              : reverse_proxy_instruction_8_en
          }
          width='100%'
        />
      </Fragment>
    )
  },
  {
    content: t('publishing.options.proxy.instruction.9')
  },
  {
    content: t('publishing.options.proxy.instruction.10')
  }
]
