import { OPEN_PRODUCT_UPDATES_DRAWER } from '@components/ProductUpdates/constants/constants'
import { TOPBAR_PRODUCT_UPDATES_CLICK_EVENT_NAME } from '@constants/mixpanel'
import { useUserContext } from '@contexts/user'
import { mixpanelEvent } from '@services/mixpanel'
import { Badge, Icon, SidebarItem, Spreader, Text } from '@ui-kit'
import { FC, Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface ProductUpdatesProps {
  isSidebarMini: boolean
}

export const ProductUpdates: FC<ProductUpdatesProps> = ({ isSidebarMini }) => {
  const { t } = useTranslation()

  const { isNewNotification } = useUserContext()

  const [isAlert, setAlert] = useState(isNewNotification)

  const handleOpenProductUpdates = () => {
    document.dispatchEvent(new Event(OPEN_PRODUCT_UPDATES_DRAWER))

    setAlert(false)

    mixpanelEvent({ name: TOPBAR_PRODUCT_UPDATES_CLICK_EVENT_NAME })
  }

  return (
    <span onClick={handleOpenProductUpdates} data-testid='product-updates'>
      <SidebarItem
        id='sidebar-product-updates'
        icon='icon-bullhorn'
        size={12}
        name={t('word.product-updates')}
        pathname='#'
        isMini={isSidebarMini}
        hasCustomIcon
        maxNameCharacters={isAlert ? 10 : 15}
      >
        {isAlert && (
          <Fragment>
            <Spreader spread={10} />

            <Badge weight={600} radius={250} data-testid='new-notification'>
              <Text size={10} weight={600} color='white' lineHeight='normal'>
                NEW
              </Text>
            </Badge>

            <Spreader spread={5} />
          </Fragment>
        )}

        <Icon icon='icon-chevron-right' size={10} color='neutral-6' />
      </SidebarItem>
    </span>
  )
}

ProductUpdates.displayName = 'TopbarProductUpdates'
