import { useFeatureFlags } from '@contexts/featureFlags'
import { usePublishingOptionsContext } from '@pages/Landings/routes/LandingsPublishingOptions/context'
import { LANDINGS } from '@routes/path'
import {
  Alert,
  Button,
  Heading,
  Icon,
  Instruction,
  Paragraph,
  Spacer,
  Spreader
} from '@ui-kit'
import { Fragment } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { Row } from 'simple-flexbox'

import { reverseProxySteps } from './reverseProxySteps'

export const ReverseProxy = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { uuid, landingName, handleClose } = usePublishingOptionsContext()

  const handleBackToMainView = () =>
    navigate(
      generatePath(LANDINGS.LANDINGS_PUBLISHING_OPTIONS.MAIN, {
        identifier: uuid
      })
    )
  const isUnbrandedContent = useFeatureFlags('UNBRANDED_CONTENT')

  return (
    <Fragment>
      <Row>
        <Paragraph size={16} color='neutral-6'>
          {landingName}
        </Paragraph>
      </Row>

      <Spacer space={10} />

      <Row alignItems='center'>
        <Icon size={26} icon='icon-reverse-proxy' color='primary' />

        <Spreader spread={10} />

        <Heading level={1}>{t('publishing.options.proxy.title')}</Heading>
      </Row>

      <Spacer space={30} />

      <Paragraph size={16}>
        <Trans i18nKey='publishing.options.proxy.description' />
      </Paragraph>

      <Spacer space={30} />

      <Instruction data={reverseProxySteps(t)} />

      <Spacer space={50} />

      <Alert variant='info'>{t('publishing.options.embed.alert')}</Alert>

      <Spacer space={30} />

      <Row justifyContent={isUnbrandedContent ? 'flex-end' : 'space-between'}>
        {isUnbrandedContent ? null : (
          <Button
            variant='text-neutral'
            onClick={() =>
              window.open(
                t('publishing.options.proxy.learn.more.url'),
                '_blank'
              )
            }
            data-testid='learn-more'
          >
            {t('learn.more')}
          </Button>
        )}

        <Row>
          <Button
            variant='text-primary'
            onClick={handleBackToMainView}
            data-testid='cancel'
          >
            {t('word.cancel')}
          </Button>

          <Spreader spread={10} />

          <Button onClick={handleClose} data-testid='close'>
            {t('publishing.options.footer.button.close')}
          </Button>
        </Row>
      </Row>
    </Fragment>
  )
}

ReverseProxy.displayName = 'PublishingOptionsViewsReverseProxy'
