import { MODAL_UPGRADE_PLAN } from '@components/Modals/ModalUpgradePlan'
import NiceModal from '@ebay/nice-modal-react'
import { getTrimmedLabel } from '@helpers/getTrimmedLabel'
import { useStyles } from '@hooks/useStyles'
import { ColorIcon } from '@ui-kit/ColorIcon'
import { IconTypes } from '@ui-kit/ColorIcon/icons'
import { Icon } from '@ui-kit/Icon'
import { Spreader } from '@ui-kit/Spreader'
import { Text } from '@ui-kit/Text'
import { Tooltip } from '@ui-kit/Tooltip'
import { FC, Fragment, ReactNode } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ElementAnimation } from './components/ElementAnimation'
import { MODALS } from './constants'
import { useGetStatusInfo } from './hooks/useGetStatusInfo'
import styles from './SidebarItem.module.scss'

interface SidebarItemBaseProps {
  className?: string | string[]
  id: string
  name: string
  maxNameCharacters?: number
  pathname: string
  children?: ReactNode
  size?: 12 | 14
  isMini?: boolean
  isActive?: boolean
}

type SidebarCustomIconProps =
  | { hasCustomIcon: true; icon: string }
  | { hasCustomIcon?: false; icon: IconTypes }

type SidebarExternalLinkProps =
  | { isExternal?: boolean; hasAccess?: never }
  | { isExternal?: never; hasAccess?: boolean }

export type SidebarItemProps = SidebarItemBaseProps &
  SidebarExternalLinkProps &
  SidebarCustomIconProps

export const SidebarItem: FC<SidebarItemProps> = ({
  className,
  id,
  icon,
  hasCustomIcon,
  name,
  maxNameCharacters = 15,
  pathname,
  isExternal,
  children,
  hasAccess = true,
  size = 14,
  isMini,
  isActive
}) => {
  const navigate = useNavigate()

  const { status, statusColor } = useGetStatusInfo(pathname, isActive)

  const { trimmedLabel, TooltipWithFullLabel } = getTrimmedLabel({
    label: name,
    maxNumberOfCharacters: maxNameCharacters
  })

  const sizeReducedByTwo = (size - 2) as 10 | 12

  const sidebarItemStyles = useStyles(
    {
      [styles['sidebar-item']]: true,
      [styles[`sidebar-item__size-${size}`]]: size
    },
    className
  )

  const handleOnClick = () => {
    if (isExternal) {
      return window.open(pathname, '_blank')
    }

    if (!hasAccess) {
      return NiceModal.show(MODAL_UPGRADE_PLAN, {
        upgradeType: MODALS[pathname]
      })
    }

    return navigate(pathname)
  }

  return (
    <span
      id={id}
      className={sidebarItemStyles}
      onClick={handleOnClick}
      data-testid='sidebar-item'
    >
      <ElementAnimation>
        <Spreader spread={5} />

        {hasCustomIcon ? (
          <Icon icon={icon} color={statusColor} size={size} />
        ) : (
          <ColorIcon icon={icon} status={status} size={sizeReducedByTwo} />
        )}

        <Spreader spread={5} />
      </ElementAnimation>

      {isMini ? null : (
        <Fragment>
          <span className={styles['sidebar-item__name']}>
            <ElementAnimation>
              <TooltipWithFullLabel>
                <Text
                  size={size}
                  color={statusColor}
                  weight={600}
                  lineHeight='normal'
                >
                  {trimmedLabel}
                </Text>
              </TooltipWithFullLabel>
            </ElementAnimation>
          </span>

          {isExternal ? (
            <ElementAnimation>
              <Icon
                icon='icon-external-link-alt'
                color={statusColor}
                size={sizeReducedByTwo}
              />
            </ElementAnimation>
          ) : null}

          {children ? <ElementAnimation>{children}</ElementAnimation> : null}

          {hasAccess ? null : (
            <ElementAnimation>
              <Tooltip
                content={<Trans i18nKey='sidebar.extension.tooltip' />}
                placement='right'
              >
                <Icon
                  icon='icon-extension'
                  color='neutral-4'
                  size={sizeReducedByTwo}
                />
              </Tooltip>
            </ElementAnimation>
          )}
        </Fragment>
      )}
    </span>
  )
}
