import { getLocalStorage } from '@helpers/storage'
import FigmaLogo from '@images/landings/wizard/figma/logo.svg'
import { LANDINGS } from '@routes/path'
import {
  Button,
  ButtonLink,
  Close,
  Heading,
  Image,
  Instruction,
  Spacer,
  Spreader
} from '@ui-kit'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Column, Row } from 'simple-flexbox'

import { DoNotShowAgainCheckbox } from './Checkbox'
import { HIDE_CHECKBOX_LOCAL_STORAGE_KEY, instructionData } from './data'
import styles from './FigmaInstruction.module.scss'

export const FigmaInstruction = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleClose = () => navigate(LANDINGS.LANDINGS)

  const handleBack = () => navigate(LANDINGS.LANDINGS_CREATE)

  const shouldRenderCheckbox =
    getLocalStorage(HIDE_CHECKBOX_LOCAL_STORAGE_KEY) === null

  return (
    <Fragment>
      <Close onClick={handleClose} className={styles.close} />

      <Button
        onClick={handleBack}
        className={styles.back}
        variant='text-neutral'
        isSquare
        icon='icon-arrow-left'
      />

      <Spacer space={30} />

      <Column
        style={{
          width: 800,
          margin: '0 auto'
        }}
      >
        <Row>
          <Image src={FigmaLogo} />
          <Heading level={1}>
            {t('create.landing.figma.instruction.title')}
          </Heading>
        </Row>

        <Spacer space={40} />

        <Instruction data={instructionData} />

        <Spacer space={50} />

        <Row justifyContent='space-between'>
          {shouldRenderCheckbox ? <DoNotShowAgainCheckbox /> : <div />}

          <Row>
            <ButtonLink
              variant='text-primary'
              target='_blank'
              href={t('create.landing.figma.instruction.more.link')}
            >
              {t('create.landing.figma.instruction.more')}
            </ButtonLink>

            <Spreader />

            <Button onClick={handleClose}>
              {t('create.landing.figma.instruction.close')}
            </Button>
          </Row>
        </Row>

        <Spacer space={50} />
      </Column>
    </Fragment>
  )
}
