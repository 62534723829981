import { BREAKPOINT } from '@constants/breakpoints'
import { TOPBAR_PROMO_BANNER_CLICK_EVENT_NAME } from '@constants/mixpanel'
import { useAcl } from '@contexts/acl'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useBreakpoint } from '@hooks/useBreakpoint'
import { useGetTopBarPosition } from '@hooks/useGetTopBarPosition'
import { useIsPinned } from '@hooks/useIsPinned'
import { useStyles } from '@hooks/useStyles'
import { mixpanelEvent } from '@services/mixpanel'
import { Spreader } from '@ui-kit'
import { Fragment } from 'react'
import { Row } from 'simple-flexbox'

import { AccountDropdown } from './components/AccountDropdown'
import { Help } from './components/Help'
import { Logo } from './components/Logo'
import { ProductUpdates } from './components/ProductUpdates'
import { PowerUpYourPlanBanner } from './components/PromoBanners/PowerUpYourPlanBanner'
import styles from './Topbar.module.scss'

export const Topbar = () => {
  const shouldShowHelp = useAcl('top_bar.help.view')

  const shouldShowProductUpdates = useAcl('top_bar.product_updates.view')

  const sholudShowPromoBannerACL = useAcl('top_bar.promo_banner.view')
  const sholudShowPromoBannerFeatureFlag = useFeatureFlags('PROMO_BANNER')
  const shouldShowPromoBanner =
    sholudShowPromoBannerACL && sholudShowPromoBannerFeatureFlag

  const isBiggerThan720 = useBreakpoint(BREAKPOINT[720], 'min')

  const isLowerThan1130 = useBreakpoint(BREAKPOINT[1130], 'max')

  const isLowerThan1350 = useBreakpoint(BREAKPOINT[1350], 'max')

  const bannerStyles = useStyles({
    [styles.banner]: !isLowerThan1350,
    [styles['banner__lower-than-desktop']]: isLowerThan1350
  })

  const topbarPosition = useGetTopBarPosition()

  const isPinned = useIsPinned(
    `.pinnable-top-bar`,
    `-${topbarPosition}px 0px 0px 0px`
  )

  const topbarStyles = useStyles({
    [styles.topbar]: true,
    [styles['topbar--pinned']]: isPinned
  })

  return (
    <Row
      id='spa-topbar'
      className={topbarStyles}
      justifyContent='space-between'
      alignItems='center'
    >
      <Row>
        {isLowerThan1130 && <Logo />}

        {!isLowerThan1130 && shouldShowPromoBanner && (
          <Row
            className={bannerStyles}
            onClick={() =>
              mixpanelEvent({ name: TOPBAR_PROMO_BANNER_CLICK_EVENT_NAME })
            }
          >
            <PowerUpYourPlanBanner />
          </Row>
        )}
      </Row>

      <Row vertical='center'>
        {shouldShowHelp && <Help />}

        {isBiggerThan720 ? (
          <Fragment>
            {shouldShowProductUpdates && (
              <Fragment>
                <ProductUpdates />

                <Spreader spread={10} />
              </Fragment>
            )}

            <AccountDropdown />
          </Fragment>
        ) : null}
      </Row>
    </Row>
  )
}

Topbar.displayName = 'Topbar'
