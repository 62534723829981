import MarketersCalendarEN from '@images/landings/marketing/calendar-en.svg'
import MarketersCalendarPL from '@images/landings/marketing/calendar-pl.svg'
import InspirationZoneEN from '@images/landings/marketing/inspirations-en.svg'
import InspirationZonPL from '@images/landings/marketing/inspirations-pl.svg'
import { BoxOutline, BoxOutlineProvider, Image } from '@ui-kit'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

export const MarketingBoxes = () => {
  const { t, i18n } = useTranslation()

  const MarketersCalendar =
    i18n.language === 'pl' ? MarketersCalendarPL : MarketersCalendarEN
  const InspirationZone =
    i18n.language === 'pl' ? InspirationZonPL : InspirationZoneEN

  return (
    <Fragment>
      <BoxOutlineProvider>
        <BoxOutline
          padding={5}
          onClick={() => window.open(t('marketers.calendar.link'))}
        >
          <Image src={MarketersCalendar} />
        </BoxOutline>
      </BoxOutlineProvider>

      <BoxOutlineProvider disabled>
        <BoxOutline padding={5}>
          <Image src={InspirationZone} />
        </BoxOutline>
      </BoxOutlineProvider>
    </Fragment>
  )
}
