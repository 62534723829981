import { Loader } from '@ui-kit/Loader'
import { Spacer } from '@ui-kit/Spacer'
import { Fragment } from 'react'

import { Heading } from './components/Heading'
import { Row } from './components/Row'
import { ItemBase, TableProps } from './types'

export const Table = <Item extends ItemBase>({
  columns,
  data,
  rowActions,
  emptyMessage,
  hasFullBorder = false,
  hasHeading = true,
  isLoading,
  customPaddings
}: TableProps<Item>) => {
  return (
    <Fragment>
      {hasHeading && (
        <Heading
          columns={columns}
          hasFullBorder={hasFullBorder}
          customPaddings={customPaddings?.heading}
        />
      )}

      {isLoading && <Loader data-testid='table-loader' />}

      {data?.length === 0 && !isLoading && (
        <Fragment>
          <Spacer space={20} />

          {emptyMessage}
        </Fragment>
      )}

      {data?.map((row: Item, index) => (
        <Row
          id={row?.id || `table-row-${index}`}
          key={row.identifier || row.id || index}
          columns={columns}
          data={row}
          rowActions={rowActions}
          hasFullBorder={hasFullBorder}
          index={index}
        />
      ))}
    </Fragment>
  )
}
