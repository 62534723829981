import { useFeatureFlags } from '@contexts/featureFlags'
import { useUserContext } from '@contexts/user'
import { useTranslation } from 'react-i18next'

export const useGetDropdownItems = () => {
  const { t } = useTranslation()

  const { hasFreeTrial: isFreeTrial, planRecordKey } = useUserContext()

  const isFreePlan = useFeatureFlags('FREE_PLAN_RESTRICTIONS')

  const isFreeTrialWithPaidPlan = isFreeTrial && !isFreePlan

  const isNotFreeTrialWithPaidPlan = !isFreeTrial && !isFreePlan

  const doesPlanNameIncludeAny = (planSubstrings: string[]) => {
    if (!planRecordKey) {
      return false
    }

    return planSubstrings.some(substring => planRecordKey.includes(substring))
  }

  const consultationURL = () => {
    if (doesPlanNameIncludeAny(['professional', 'lite'])) {
      return t('topbar.consultations.professional.lite.url')
    }

    if (
      doesPlanNameIncludeAny([
        'agency',
        'unlimited',
        'enterprise',
        'core',
        'create',
        'automate',
        'BR_'
      ])
    ) {
      return t('topbar.consultations.other.plans.url')
    }

    return t('topbar.consultations.url')
  }

  const dropdownItems = [
    {
      key: 'help-center',
      icon: 'icon-help-center',
      label: t('topbar.help-center'),
      url: t('topbar.help-center.url')
    },
    {
      key: 'hire-an-expert',
      icon: 'icon-user-tie',
      label: t('topbar.hireus.tooltip.content'),
      url: t('topbar.hireus.url')
    }
  ]

  if (isFreeTrialWithPaidPlan) {
    dropdownItems.push({
      key: 'free-onboarding',
      icon: 'icon-consultation',
      label: t('topbar.free-onboarding-session'),
      url: t('topbar.free-onboarding-session.url')
    })
  }

  if (isNotFreeTrialWithPaidPlan) {
    dropdownItems.push({
      key: 'support-ticket',
      icon: 'icon-envelope',
      label: t('topbar.create-support-ticket'),
      url: t('topbar.create-support-ticket.url')
    })
  }

  if (!isFreeTrial) {
    dropdownItems.push({
      key: 'consultations',
      icon: 'icon-consultation',
      label: t('topbar.consultations'),
      url: consultationURL()
    })
  }

  dropdownItems.push(
    {
      key: 'video-tutorials',
      icon: 'icon-play-lesson',
      label: t('topbar.video-tutorials'),
      url: t('topbar.video-tutorials.url')
    },
    {
      key: 'webinars',
      icon: 'icon-video-alt',
      label: t('topbar.webinars'),
      url: t('topbar.webinars.url')
    },
    {
      key: 'academy',
      icon: 'icon-academy',
      label: t('topbar.landing-page-academy'),
      url: t('topbar.landing-page-academy.url')
    },
    {
      key: 'system-status',
      icon: 'icon-server',
      label: t('topbar.system-status'),
      url: t('topbar.system-status.url')
    }
  )

  return dropdownItems
}
