import { PLN } from '@constants/currencies'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { setSessionStorage } from '@helpers/session'
import { setLocalStorage } from '@helpers/storage'
import { contactSupport } from '@helpers/support'
import { LANDINGS } from '@routes/path'
import { useGetLastCreatedLanding } from '@services/landings/useGetLastCreatedLanding'
import {
  CustomLink,
  Image,
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
  Panel,
  Paragraph,
  Spacer,
  Text
} from '@ui-kit'
import { Trans, useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { useGetValuesByPlan } from './hooks/useGetValuesByPlan'
import styles from './ModalEventTrackerBlackFridayPromo.module.scss'

export const DISABLE_EVENT_TRACKER_BF_MODAL = 'disableEventTrackerBFModal'

export const ModalEventTrackerBlackFridayPromo = NiceModal.create(() => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { remove } = useModal()

  const { data } = useGetLastCreatedLanding()

  const landingIdentifier = data?.landing_identifier

  const { percentage, image, oldPrice, newPrice, currency } =
    useGetValuesByPlan()

  const handleContactSupport = () => {
    contactSupport(t('modal.event.tracker.bf.support'))

    remove()
  }

  const handleCloseForSession = () => {
    setSessionStorage(DISABLE_EVENT_TRACKER_BF_MODAL, true)

    remove()
  }

  const handleClosePermanently = () => {
    setLocalStorage(DISABLE_EVENT_TRACKER_BF_MODAL, true)

    remove()
  }

  const handleOpenOverview = () => {
    const path = landingIdentifier
      ? generatePath(LANDINGS.LANDINGS_DASHBOARD, {
          identifier: landingIdentifier
        })
      : LANDINGS.LANDINGS

    navigate(path)

    remove()
  }

  return (
    <Modal onClose={handleCloseForSession} isCentered>
      <ModalHeader
        heading={
          <Trans
            i18nKey='modal.event.tracker.bf.title'
            values={{ percentage }}
          />
        }
      />

      <ModalBody image={<Image src={image} />}>
        <Paragraph size={18} align='center'>
          <Trans
            i18nKey='modal.event.tracker.bf.description'
            values={{
              oldPrice,
              newPrice,
              currency,
              net: currency === PLN ? ` ${t('word.net')}` : ''
            }}
            components={{
              // @ts-ignore
              old: <Text className={styles['old-price']} color='neutral-6' />,
              // @ts-ignore
              new: <Text color='primary' weight={600} />
            }}
          />
        </Paragraph>

        <Spacer space={20} />

        <Panel surface={0} border='neutral-2' padding={15}>
          <Paragraph size={12} color='neutral-6'>
            <Trans
              i18nKey='modal.event.tracker.bf.panel'
              components={{
                // @ts-ignore
                a: <CustomLink size={12} onClick={handleOpenOverview} />
              }}
            />
          </Paragraph>
        </Panel>
      </ModalBody>

      <ModalActions
        primaryAction={{
          i18nTitle: t('modal.event.tracker.bf.button.primary'),
          onClick: handleContactSupport
        }}
        secondaryAction={{
          i18nTitle: t('modal.event.tracker.bf.button.secondary'),
          onClick: handleClosePermanently
        }}
      />
    </Modal>
  )
})
