import moment from 'moment-timezone'

// A custom hook that checks if the `referenceDate` is before the `targetDate`.
// This hook is useful when you need to determine whether a specific event or feature should be visible based on a date condition.

export const useShouldSeeItem = ({
  referenceDate,
  targetDate
}: {
  referenceDate?: string
  targetDate?: string
}) => {
  if (!referenceDate || !targetDate) {
    return false
  }

  moment.tz.setDefault('UTC')

  const normalizeDate = (dateString: string) => {
    return moment(dateString)
      .set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      })
      .format('x')
  }

  return normalizeDate(referenceDate) < normalizeDate(targetDate)
}
