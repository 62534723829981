import { Heading, Panel, Paragraph, Spacer } from '@landingi/landingi-ui-kit'
import { THRIVE_COACH_COLOR } from '@pages/Authentication/routes/WhitelabelPages/constants'
import { REGISTRATION } from '@routes/path'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Column } from 'simple-flexbox'

import LoginForm from './Form'

const Login = () => {
  const { t } = useTranslation()

  return (
    <Column>
      <Panel borderRadius='10px' hasShadow={false}>
        <Heading level={1} bold align='center'>
          {t('registration.flow.sign.in.to.account')}
        </Heading>

        <Spacer space='tiny' />

        <Spacer />

        <LoginForm />
      </Panel>

      <Paragraph size={12} color='accent-2' align='center'>
        <Link
          to={`${REGISTRATION.FORGOT_PASSWORD_THRIVE_COACH}`}
          style={{ color: THRIVE_COACH_COLOR }}
        >
          <Trans i18nKey='registration.flow.forgotten.password' />
        </Link>
      </Paragraph>
    </Column>
  )
}

Login.displayName = 'WhitelabelLogin'

export default Login
