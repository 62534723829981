import GoogleSignIn from '@components/GoogleButtons/GoogleSignIn'
import { MicrosoftSignInButton } from '@components/MicrosoftButtons/MicrosoftSignIn'
import { useCookieFeature } from '@hooks/useCookieFeature'
import useQuery from '@hooks/useQuery'
import { Heading, Paragraph, Separator } from '@landingi/landingi-ui-kit'
import { StartTrialCTA } from '@pages/Authentication/components/RedirectedCTA'
import Form from '@pages/Authentication/routes/Login/Form'
import { REGISTRATION } from '@routes/path'
import { Spacer } from '@ui-kit'
import { Fragment, Suspense } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Column } from 'simple-flexbox'

import styles from './Login.module.scss'

const Login = () => {
  const { t } = useTranslation()

  const queryParams = useQuery()

  const hasAccessToMicrosftSignupCookie = useCookieFeature('MICROSOFT_SIGNUP')

  return (
    <Suspense fallback={<>...</>}>
      <Column className={styles.layout}>
        <Heading level={1} bold align='center'>
          {t('registration.flow.sign.in.to.account', {
            days: 14
          })}
        </Heading>

        <Spacer space={5} />

        <Spacer space={10} />

        <Form />

        <Spacer space={5} />

        <Spacer space={10} />

        <Paragraph size={12} color='accent-2' align='center' padding='none'>
          <Link
            to={`${
              REGISTRATION.FORGOT_PASSWORD.DEFAULT
            }?${queryParams.toString()}`}
          >
            <Trans i18nKey='registration.flow.forgotten.password' />
          </Link>
        </Paragraph>

        <Spacer space={15} />

        <Separator>
          <Paragraph
            color='accent-6'
            size={12}
            weight={700}
            padding='none'
            uppercase
          >
            {t('word.or')}
          </Paragraph>
        </Separator>

        <Spacer space={15} />

        <GoogleSignIn />

        <Spacer space={15} />

        {hasAccessToMicrosftSignupCookie && (
          <Fragment>
            <MicrosoftSignInButton />

            <Spacer space={15} />
          </Fragment>
        )}

        <StartTrialCTA />
      </Column>
    </Suspense>
  )
}

export default Login
