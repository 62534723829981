import { MODAL_EVENT_TRACKER_BLACK_FRIDAY_PROMO } from '@components/Modals/ModalEventTrackerBlackFridayPromo'
import { DISABLE_EVENT_TRACKER_BF_MODAL } from '@components/Modals/ModalEventTrackerBlackFridayPromo/ModalEventTrackerBlackFridayPromo'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useUserContext } from '@contexts/user'
import NiceModal from '@ebay/nice-modal-react'
import { getSessionStorage } from '@helpers/session'
import { getLocalStorage, parseStorage } from '@helpers/storage'
import { LANDINGS } from '@routes/path'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const useShowEventTrackerBlackFridayModal = () => {
  const { pathname } = useLocation()

  const isLandingsPage = pathname === LANDINGS.LANDINGS

  const { isLoading, user, isSupportMode, expiresAt } = useUserContext()

  const eventTrackerIsDisabledBySession = parseStorage(
    getSessionStorage(DISABLE_EVENT_TRACKER_BF_MODAL)
  )

  const eventTrackerIsDisabledPermanently = parseStorage(
    getLocalStorage(DISABLE_EVENT_TRACKER_BF_MODAL)
  )

  const hasAccessToEventTrackerPromoModal = useFeatureFlags(
    'EVENT_TRACKER_BLACK_FRIDAY_PROMO'
  )

  useEffect(() => {
    if (
      !user ||
      isLoading ||
      isSupportMode ||
      !expiresAt?.formatted ||
      !isLandingsPage ||
      !hasAccessToEventTrackerPromoModal ||
      eventTrackerIsDisabledBySession ||
      eventTrackerIsDisabledPermanently
    )
      return

    const today = new Date()
    const expiresAtDate = new Date(expiresAt.formatted?.replace(/-/g, '/'))

    if (today > expiresAtDate) {
      return
    }

    NiceModal.show(MODAL_EVENT_TRACKER_BLACK_FRIDAY_PROMO)
  }, [
    isLoading,
    user,
    isSupportMode,
    expiresAt,
    pathname,
    isLandingsPage,
    hasAccessToEventTrackerPromoModal,
    eventTrackerIsDisabledBySession,
    eventTrackerIsDisabledPermanently
  ])
}
