import { Text, TextColor } from '@ui-kit/Text'
import { FC, ReactNode } from 'react'

export type ParagraphSize = 10 | 12 | 14 | 16 | 18

export interface ParagraphProps {
  children: ReactNode
  className?: string | string[]
  size?: ParagraphSize
  color?: TextColor
  weight?: 300 | 400 | 600
  lineHeight?: '1.7' | 'normal'
  align?: 'left' | 'center' | 'right' | 'justify'
  'data-testid'?: string
}

export const Paragraph: FC<ParagraphProps> = ({
  children,
  className,
  size = 14,
  color = 'neutral',
  weight = 400,
  align = 'left',
  lineHeight = '1.7',
  'data-testid': dataTestId = 'paragraph'
}) => {
  return (
    <Text
      data-testid={dataTestId}
      size={size}
      color={color}
      weight={weight}
      align={align}
      className={className}
      lineHeight={lineHeight}
      tag='p'
    >
      {children}
    </Text>
  )
}

Paragraph.displayName = 'Paragraph'
