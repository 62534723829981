import { BREAKPOINT } from '@constants/breakpoints'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useSidebarContext } from '@contexts/sidebar'
import { useUserContext } from '@contexts/user'
import { useBreakpoint } from '@hooks/useBreakpoint'
import { useHover } from '@hooks/useHover'
import { Backdrop } from '@landingi/landingi-ui-kit'
import { Spacer } from '@ui-kit'
import { AnimatePresence, motion } from 'framer-motion'
import { Fragment, useCallback } from 'react'

import { AccountDrawer } from './components/AccountDrawer'
import { AccountSwitcher } from './components/AccountSwitcher'
import { FirstNavigationPart } from './components/FirstNavigationPart'
import { Header } from './components/Header'
import { MobileNavigationPart } from './components/MobileNavigationPart'
import { SecondNavigationPart } from './components/SecondNavigationPart'
import { SidebarBanner } from './components/SidebarBanner'
import styles from './Sidebar.module.scss'

export const Sidebar = () => {
  const isLowerThan1350 = useBreakpoint(BREAKPOINT[1350], 'max')
  const isLowerThan1130 = useBreakpoint(BREAKPOINT[1130], 'max')
  const isBiggerThan720 = useBreakpoint(BREAKPOINT[720], 'min')

  const { isSidebarMobileOpen, toggleSidebarMobileOpen } = useSidebarContext()

  const [sidebarProps, isHoveredSidebar] = useHover()

  const {
    isSubaccount,
    isTypeOwner,
    isTypeAdmin,
    isTypeAccountant,
    isTypeClient
  } = useUserContext()

  const hasAccessToSemrushIntegration = useFeatureFlags('SEMRUSH_INTEGRATION')

  const hasAccessToSidebar =
    (isTypeOwner || isTypeAdmin) &&
    !isSubaccount &&
    !hasAccessToSemrushIntegration

  const isSidebarMini = isLowerThan1350 && !isHoveredSidebar && !isLowerThan1130

  const sidebarVariants = {
    mini: { width: 62, x: 0 },
    normal: { width: 210, x: 0 },
    close: { width: 210, x: -210 },
    open: { width: 210, x: 0 }
  }

  const animation = useCallback(() => {
    if (isSidebarMini) {
      return 'mini'
    }

    if (isLowerThan1130) {
      if (isSidebarMobileOpen) {
        return 'open'
      }

      return 'close'
    }

    return 'normal'
  }, [isSidebarMini, isLowerThan1130, isSidebarMobileOpen])

  const animateAndInitial = animation()

  const motionDivProps = !isLowerThan1130 ? sidebarProps : {}

  return hasAccessToSidebar || !isTypeAccountant ? (
    <Fragment>
      <AnimatePresence>
        <motion.div
          {...motionDivProps}
          className={styles.sidebar}
          animate={animateAndInitial}
          variants={sidebarVariants}
          transition={{ duration: 0.5, ease: [0.65, 0, 0.3, 1] }}
          initial={animateAndInitial}
          data-testid='sidebar'
        >
          <Header
            isSidebarMini={isSidebarMini}
            isToggleButtonVisible={isLowerThan1130}
          />

          {isTypeClient ? null : (
            <Fragment>
              <AccountSwitcher isMini={isSidebarMini} />

              <Spacer space={15} />
            </Fragment>
          )}

          <FirstNavigationPart isSidebarMini={isSidebarMini} />

          <SidebarBanner isSidebarMini={isSidebarMini} />

          <Spacer space={20} />

          <SecondNavigationPart isSidebarMini={isSidebarMini} />

          <MobileNavigationPart
            isSidebarMini={isSidebarMini}
            isBiggerThan720={isBiggerThan720}
          />
        </motion.div>
      </AnimatePresence>

      <AccountDrawer />

      {isSidebarMobileOpen ? (
        <Backdrop onClick={toggleSidebarMobileOpen} zIndex='4' />
      ) : null}
    </Fragment>
  ) : null
}

Sidebar.displayName = 'Sidebar'
