import { useTouchscreen } from '@hooks/useTouchscreen'
import { Button } from '@ui-kit/Button'
import { Spacer } from '@ui-kit/Spacer'
import { Text } from '@ui-kit/Text'
import {
  addDays,
  eachDayOfInterval,
  endOfMonth,
  format,
  getDay,
  isSameDay,
  isSameMonth,
  isToday,
  isWithinInterval,
  startOfMonth,
  subDays
} from 'date-fns'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

import styles from './Calendar.module.scss'
import { MonthDropdown } from './MonthDropdown'
import { MonthType, YearType } from './types'
import { YearDropdown } from './YearDropdown'

interface CalendarProps {
  initialMonth: MonthType
  initialYear: YearType
  handleClick: (date: Date) => void
  handleHover: (date: Date) => void
  activeRange: { start: Date | undefined; end: Date | undefined }
}

export const Calendar: FC<CalendarProps> = ({
  initialMonth,
  initialYear,
  handleClick,
  handleHover,
  activeRange
}) => {
  const { t } = useTranslation()
  const isTouchDevice = useTouchscreen()

  const weekDays = [
    t('day.short.monday'),
    t('day.short.tuesday'),
    t('day.short.wednesday'),
    t('day.short.thursday'),
    t('day.short.friday'),
    t('day.short.saturday'),
    t('day.short.sunday')
  ]

  const [month, setMonth] = useState<MonthType>(initialMonth)
  const [year, setYear] = useState(initialYear)

  const generateCalendar = (year: YearType, month: MonthType) => {
    const start = startOfMonth(new Date(year, month))
    const end = endOfMonth(new Date(year, month))
    const days = eachDayOfInterval({ start, end })

    // Days from previous month
    const prevDaysCount = (getDay(start) + 6) % 7 // Monday as first day of week
    const prevDays = Array.from({ length: prevDaysCount }, (_, i) =>
      subDays(start, prevDaysCount - i)
    )

    // Days from next month
    const nextDaysCount = (7 - ((days.length + prevDaysCount) % 7)) % 7
    const nextDays = Array.from({ length: nextDaysCount }, (_, i) =>
      addDays(end, i + 1)
    )

    return [...prevDays, ...days, ...nextDays]
  }

  const handleNextMonth = () => {
    setMonth(prev => ((prev + 1) % 12) as MonthType)
    setYear(prev => ((month + 1) % 12 === 0 ? prev + 1 : prev))
  }

  const handlePrevMonth = () => {
    setMonth((prev: number) => ((prev - 1 + 12) % 12) as MonthType)
    setYear((prev: number) => (month % 12 === 0 ? prev - 1 : prev))
  }

  const days = generateCalendar(year, month)

  const fixInterval = (start?: Date, end?: Date) => {
    if (!start || !end) return { start, end }
    return start > end ? { start: end, end: start } : { start, end }
  }

  const { start, end } = fixInterval(activeRange.start, activeRange.end)

  return (
    <Column className={styles.wrapper}>
      <Row justifyContent='space-between'>
        <Button
          variant='text-neutral'
          size={12}
          onClick={handlePrevMonth}
          icon='icon-arrow-left'
          data-testid='prev-month-button'
        />
        <Row>
          <MonthDropdown handleSelect={setMonth} month={month} />
          <YearDropdown handleSelect={setYear} year={year} />
        </Row>

        <Button
          variant='text-neutral'
          size={12}
          onClick={handleNextMonth}
          icon='icon-arrow-right'
          data-testid='next-month-button'
        />
      </Row>

      <Spacer space={15} />

      <div className={styles.grid}>
        {weekDays.map(day => (
          <div key={day}>
            <Text color='neutral-6' size={12}>
              {day}
            </Text>
          </div>
        ))}

        {days.map(day => (
          <div
            key={day.toDateString() + month}
            onClick={() => handleClick(day)}
            onMouseOver={isTouchDevice ? undefined : () => handleHover(day)}
            className={`${styles.day} ${isSameMonth(day, new Date(year, month)) ? '' : styles.inactive}
            ${start && end && isWithinInterval(day, { start, end }) ? styles.hover : ''}
            ${start && isSameDay(day, start) ? styles.active : ''} ${end && isSameDay(day, end) ? styles.active : ''}
            ${isToday(day) ? styles.today : ''}`}
          >
            {format(day, 'd')}
          </div>
        ))}
      </div>
    </Column>
  )
}

Calendar.displayName = 'Calendar'
