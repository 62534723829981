import CyfrowaWyprawkaImage from '@images/registration/variants/cyfrowa_wyprawka.png'
import FastTonyImage from '@images/registration/variants/fast_tony.png'
import LeadsImage from '@images/registration/variants/leads.png'
import SalesImage from '@images/registration/variants/sales.png'
import SosvImage from '@images/registration/variants/sosv.png'
import TemplatesImage from '@images/registration/variants/templates.png'
import TestsImage from '@images/registration/variants/tests.png'
import WordpressImage from '@images/registration/variants/wordpress.png'

import BlackFriday from './BlackFriday'
import Christmas from './Christmas'
import EventTrackerLogin from './EventTrackerLogin'
import GetLandy from './GetLandy'
import Login from './Login'
import Refreshed from './Refreshed'
import SummerSale from './SummerSale'
import Variant from './Variant'

export const Leads = () => (
  <Variant
    title='registration.flow.leads.title'
    subtitle='registration.flow.leads.subtitle'
    imageSrc={LeadsImage}
  />
)

export const Sales = () => (
  <Variant
    title='registration.flow.sales.title'
    subtitle='registration.flow.sales.subtitle'
    imageSrc={SalesImage}
  />
)

export const Sosv = () => (
  <Variant
    title='registration.flow.sosv.title'
    subtitle='registration.flow.sosv.subtitle'
    imageSrc={SosvImage}
  />
)

export const Tests = () => (
  <Variant
    title='registration.flow.tests.title'
    subtitle='registration.flow.tests.subtitle'
    imageSrc={TestsImage}
  />
)

export const Wordpress = () => (
  <Variant
    title='registration.flow.wordpress.title'
    subtitle='registration.flow.wordpress.subtitle'
    imageSrc={WordpressImage}
  />
)

export const Templates = () => (
  <Variant
    title='registration.flow.templates.title'
    subtitle='registration.flow.templates.subtitle'
    imageSrc={TemplatesImage}
  />
)

export const FastTony = () => (
  <Variant
    title='registration.flow.fast.tony.title'
    subtitle='registration.flow.fast.tony.subtitle'
    imageSrc={FastTonyImage}
  />
)

export const CyfrowaWyprawka = () => (
  <Variant
    title='registration.flow.cyfrowawyprawka.title'
    subtitle='registration.flow.cyfrowawyprawka.subtitle'
    imageSrc={CyfrowaWyprawkaImage}
  />
)

export const RefreshedVariant = () => (
  <Refreshed title='registration.flow.refreshed.title' />
)

export const LoginVariant = () => (
  <Login title='registration.flow.refreshed.title' />
)

export const EventTrackerLoginVariant = EventTrackerLogin

export const BlackFridayVariant = BlackFriday

export const ChristmasVariant = Christmas

export const SummerSaleVariant = SummerSale

export const GetLandyVariant = GetLandy
