import { ACCOUNT_DROPDOWN_VERTICAL_BANNER_INFO } from '@components/TopbarNew/constants'
import { NEW_LANDINGI_APP_URL } from '@constants/index'
import { useUserContext } from '@contexts/user'
import { getTrimmedLabel } from '@helpers/getTrimmedLabel'
import { getLocalStorage, setLocalStorage } from '@helpers/storage'
import { useLogout } from '@hooks/useLogout'
import { useShouldSeeItem } from '@hooks/useShouldSeeItem'
import { PROFILE } from '@routes/path'
import {
  Avatar,
  Button,
  Divider,
  Dropdown,
  Paragraph,
  Spacer,
  Spreader,
  VerticalBanner
} from '@ui-kit'
import { Fragment, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

import styles from './AccountDropdown.module.scss'

export const AccountDropdown = () => {
  const { t } = useTranslation()

  const RELEASE_DATE = '2025-03-13'

  const { createdAt } = useUserContext()

  const shouldSeeItem = useShouldSeeItem({
    referenceDate: createdAt?.formatted,
    targetDate: RELEASE_DATE
  })

  const [isClosed, setClosed] = useState(
    !!getLocalStorage(ACCOUNT_DROPDOWN_VERTICAL_BANNER_INFO)
  )

  const { userName, userID, userEmail } = useUserContext()

  const handleClosePanel = () => {
    setLocalStorage(ACCOUNT_DROPDOWN_VERTICAL_BANNER_INFO, 'true')

    setClosed(true)
  }

  const handleOpenMyProfile = () => {
    window.open(`${NEW_LANDINGI_APP_URL}${PROFILE.PROFILES}`, '_self')
  }

  const handleLogoutAccount = useLogout()

  if (!userID) return null

  const customTrigger = ({ isOpen }: { isOpen: boolean }) => (
    <Avatar
      userId={userID}
      userName={userName}
      isActive={isOpen}
      data-testid='topbar-avatar'
    />
  )

  const {
    trimmedLabel: trimmedUserName,
    TooltipWithFullLabel: TooltipWithFullLabelOfUserName
  } = getTrimmedLabel({
    label: userName || '',
    maxNumberOfCharacters: 23
  })

  const { TooltipWithFullLabel: TooltipWithFullLabelOfUserEmail } =
    getTrimmedLabel({
      label: userEmail || '',
      maxNumberOfCharacters: 30
    })

  return (
    <Dropdown trigger={customTrigger} placement='bottom-start'>
      {() => (
        <Column className={styles['account-dropdown']}>
          <Column className={styles['account-dropdown__info']}>
            <Row alignItems='center'>
              <Avatar
                userId={userID}
                userName={userName}
                isInteractive={false}
              />

              <Spreader spread={10} />

              <Column>
                <TooltipWithFullLabelOfUserName>
                  <Paragraph size={16} weight={600} lineHeight='normal'>
                    {trimmedUserName}
                  </Paragraph>
                </TooltipWithFullLabelOfUserName>

                <TooltipWithFullLabelOfUserEmail>
                  <Paragraph
                    size={12}
                    color='neutral-6'
                    className={styles['account-dropdown__info--email']}
                  >
                    {userEmail}
                  </Paragraph>
                </TooltipWithFullLabelOfUserEmail>
              </Column>
            </Row>

            {isClosed || !shouldSeeItem ? null : (
              <Fragment>
                <Spacer space={20} />

                <VerticalBanner
                  variant='info'
                  closeButton={
                    <Button onClick={handleClosePanel}>
                      {t('word.close')}
                    </Button>
                  }
                  data-testid='account-dropdown-vertical-banner'
                >
                  <Paragraph size={12}>
                    <Trans i18nKey='topbar.account.dropdown.info.we.are.reorganized' />
                  </Paragraph>
                </VerticalBanner>
              </Fragment>
            )}
          </Column>

          <Divider />

          <Spacer space={15} />

          <Row
            justifyContent='space-between'
            className={styles['account-dropdown__actions']}
          >
            <Button
              icon='icon-users-circle'
              variant='text-primary'
              onClick={handleOpenMyProfile}
            >
              {t('topbar.my-profile')}
            </Button>

            <Button
              icon='icon-exit'
              variant='text-neutral'
              onClick={handleLogoutAccount}
            >
              {t('topbar.logout')}
            </Button>
          </Row>
        </Column>
      )}
    </Dropdown>
  )
}
