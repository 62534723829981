// @ts-nocheck
import { NEW_LANDINGI_URL } from '@config/env'
import { getGoogleLoginErrorMessage } from '@helpers/errors'
import { emitTimingToastToggle } from '@landingi/landingi-ui-kit'
import { useStepsContext } from '@pages/Authentication/contexts/steps'
import { useGoogleLogin } from '@react-oauth/google'
import { loginWithOAuth } from '@services/authentication'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSWRConfig } from 'swr'

import { GoogleButton } from '../GoogleButton'

/**
 * GoogleSignIn - stateful component used handling google sign in logic
 * @returns {object} children
 */
const GoogleSignIn = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { mutate } = useSWRConfig()
  const { startFromFirstStep } = useStepsContext()

  const { t } = useTranslation()

  /**
   * Handles google sign in logic after successful call to google api and calls /oauth-sign-in endpoint
   * @type {function}
   */
  const handleSignIn = useGoogleLogin({
    onSuccess: async response => {
      try {
        setIsLoading(true)

        const { access_token } = response

        await loginWithOAuth(access_token, 'google', NEW_LANDINGI_URL)

        startFromFirstStep()

        mutate('auth')
      } catch (error) {
        emitTimingToastToggle(
          t(getGoogleLoginErrorMessage(error?.error?.code)),
          'alert'
        )
      } finally {
        setIsLoading(false)
      }
    }
  })

  return (
    <GoogleButton
      onClick={handleSignIn}
      disabled={isLoading}
      isLoading={isLoading}
    >
      {t('registration.flow.sign.in.google')}
    </GoogleButton>
  )
}

GoogleSignIn.displayName = 'Google Sign In Component'

export default GoogleSignIn
