import ImageStep2 from '@images/landings/wizard/figma/step2.png'
import ImageStep4 from '@images/landings/wizard/figma/step4.png'
import { Button, Image, Paragraph, Spacer } from '@ui-kit'
import { Fragment } from 'react'
import { Trans } from 'react-i18next'

import { ImportButton } from '../ImportButton'

const handleDownloadDesignSystem = () =>
  window.open(`http://landingi.com/figma/desingsystem`, '_blank')

const handleOpenLandingiPlugin = () =>
  window.open(`http://landingi.com/figma/plugin`, '_blank')

export const HIDE_CHECKBOX_LOCAL_STORAGE_KEY = 'hide_figma_instruction'

export const instructionData = [
  {
    content: (
      <Fragment>
        <Paragraph size={16}>
          <Trans i18nKey='create.landing.figma.instruction.step.1' />
        </Paragraph>

        <Spacer space={20} />

        <Button onClick={handleDownloadDesignSystem}>
          <Trans i18nKey='create.landing.figma.instruction.step.1.button' />
        </Button>
      </Fragment>
    )
  },
  {
    content: (
      <Fragment>
        <Paragraph size={16}>
          <Trans i18nKey='create.landing.figma.instruction.step.2' />
        </Paragraph>
        <Spacer space={20} />
        <Image src={ImageStep2} />
      </Fragment>
    )
  },
  {
    content: (
      <Fragment>
        <Paragraph size={16}>
          <Trans i18nKey='create.landing.figma.instruction.step.3' />
        </Paragraph>

        <Spacer space={20} />

        <Button onClick={handleOpenLandingiPlugin}>
          <Trans i18nKey='create.landing.figma.instruction.step.3.button' />
        </Button>
      </Fragment>
    )
  },
  {
    content: (
      <Fragment>
        <Paragraph size={16}>
          <Trans
            i18nKey='create.landing.figma.instruction.step.4'
            components={{
              b: <b />
            }}
          />
        </Paragraph>
        <Spacer space={20} />
        <Image src={ImageStep4} />
      </Fragment>
    )
  },
  {
    content: (
      <Paragraph size={16}>
        <Trans
          i18nKey='create.landing.figma.instruction.step.5'
          components={{
            b: <b />
          }}
        />
      </Paragraph>
    )
  },
  {
    content: (
      <Fragment>
        <Paragraph size={16}>
          <Trans
            i18nKey='create.landing.figma.instruction.step.6'
            components={{
              b: <b />
            }}
          />
        </Paragraph>

        <Spacer space={20} />

        <ImportButton>
          <Button icon='icon-cloud-upload'>
            <Trans i18nKey='create.landing.import' />
          </Button>
        </ImportButton>
      </Fragment>
    )
  }
]
