import Fifth from './Fifth'
import First from './First'
import Fourth from './Fourth'
import Second from './Second'
import Third from './Third'

export const useComponentsWithoutCommunicationStep = () => {
  const components = [First, Second, Third, Fourth, Fifth]

  return components
}
