import GoogleIcon from '@images/registration/google_icon.svg'
import { Button, Image, Spreader, Text } from '@ui-kit'
import { FC } from 'react'
import { Row } from 'simple-flexbox'

interface GoogleButtonProps {
  onClick: () => void
  disabled: boolean
  isLoading: boolean
  children: string
}

export const GoogleButton: FC<GoogleButtonProps> = ({
  onClick,
  disabled,
  isLoading,
  children
}) => (
  <Button
    variant='outlined'
    size={16}
    onClick={onClick}
    disabled={disabled}
    isLoading={isLoading}
  >
    <Row alignItems='center' justifyContent='center'>
      <Image src={GoogleIcon} width='16px' height='16px' />

      <Spreader spread={10} />

      <Text size={16} weight={600} color='primary' lineHeight='normal'>
        {children}
      </Text>
    </Row>
  </Button>
)

GoogleButton.displayName = 'GoogleButton'
