import { useUserContext } from '@contexts/user'
import { NotFound } from '@pages/NotFound/routes/NotFound'
import { FC, ReactElement } from 'react'

interface ExcludeFromSupportModeProps {
  children: ReactElement
}

export const ExcludeFromSupportMode: FC<ExcludeFromSupportModeProps> = ({
  children
}) => {
  const { isSupportMode } = useUserContext()
  const hasAccess = !isSupportMode

  return hasAccess ? children : <NotFound />
}

ExcludeFromSupportMode.displayName = 'ExcludeFromSupportMode'
