import { Heading, Panel, Paragraph, Spacer } from '@landingi/landingi-ui-kit'
import { SPECTRUM_MARKETING_COLOR } from '@pages/Authentication/routes/WhitelabelPages/constants'
import { REGISTRATION } from '@routes/path'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Column } from 'simple-flexbox'

import GrantNewPasswordForm from './Form'

/**
 * GrantNewPassword - stateless presentational component with view of grant new password form
 * @return {object} An object of children
 */
const GrantNewPassword = () => {
  const { t } = useTranslation()

  return (
    <Column>
      <Panel borderRadius='10px' hasShadow={false}>
        <Heading level={1} bold>
          {t('registration.flow.change.password')}
        </Heading>

        <Spacer space='mini' />

        <Spacer space='large' />

        <GrantNewPasswordForm />
      </Panel>

      <Paragraph size={12} color='accent-2' align='center'>
        <Link
          to={`${REGISTRATION.LOGIN_SPECTRUM_MARKETING}`}
          style={{ color: SPECTRUM_MARKETING_COLOR }}
        >
          <Trans i18nKey='registration.flow.go.back.to.sign.in' />
        </Link>
      </Paragraph>
    </Column>
  )
}

GrantNewPassword.displayName = 'Grant New Password'

export default GrantNewPassword
