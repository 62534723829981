import { useAcl } from '@contexts/acl'
import { useUserContext } from '@contexts/user'
import { mixpanelEvent } from '@services/mixpanel'
import { Button } from '@ui-kit/Button'
import { Tooltip } from '@ui-kit/Tooltip'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import styles from '../ModalUpgradePlan.module.scss'

interface SecondaryButtonProps {
  customContent?: string
  customUrl?: string
  featureNameForMixpanel?: string
}

export const SecondaryButton: FC<SecondaryButtonProps> = ({
  customContent,
  customUrl,
  featureNameForMixpanel
}) => {
  const { t } = useTranslation()

  const { hasFreeTrial } = useUserContext()

  const hasAccessToChangePlan = useAcl('plan.change_plan')

  const url = hasFreeTrial
    ? t('trial.onboarding.demo.link')
    : t('paid.onboarding.demo.link')

  const content =
    customContent ||
    (hasFreeTrial ? t('schedule.demo') : t('schedule.free.test'))

  const handleClick = () => {
    if (featureNameForMixpanel) {
      mixpanelEvent({
        name: 'Access to feature - demo button',
        properties: {
          feature: featureNameForMixpanel
        }
      })
    }

    window.open(customUrl || url, '_blank')
  }

  return (
    <Tooltip
      content={t('upgrade.account.tooltip')}
      disabled={hasAccessToChangePlan}
    >
      <Button
        className={styles['upgrade-modal__footer__link']}
        variant='outlined'
        data-testid='scheduleDemo'
        onClick={handleClick}
        disabled={!hasAccessToChangePlan}
      >
        {content}
      </Button>
    </Tooltip>
  )
}
