import { useSidebarContext } from '@contexts/sidebar'
import { useUserContext } from '@contexts/user'
import { getTrimmedLabel } from '@helpers/getTrimmedLabel'
import { useLazyService } from '@hooks/useLazyService'
import { useStyles } from '@hooks/useStyles'
import Main from '@images/sidebar/icons/main.svg'
import MainDisabled from '@images/sidebar/icons/main-disabled.svg'
import MainNotActive from '@images/sidebar/icons/main-not-active.svg'
import Subaccount from '@images/sidebar/icons/subaccount.svg'
import SubaccountDisabled from '@images/sidebar/icons/subaccount-disabled.svg'
import SubaccountNotActive from '@images/sidebar/icons/subaccount-not-active.svg'
import { LANDINGS } from '@routes/path'
import { changeUserAccount } from '@services/subaccounts'
import { Button, Image, Paragraph, Spreader } from '@ui-kit'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Column, Row } from 'simple-flexbox'
import { useSWRConfig } from 'swr'

import styles from './DrawerItem.module.scss'

export interface DrawerItemProps {
  name: string
  email?: string
  uuid: string
  isActive?: boolean
  isMain?: boolean
  isDisabled?: boolean
}

export const AgencyDrawerItem = ({
  name,
  email,
  uuid,
  isActive,
  isMain,
  isDisabled
}: DrawerItemProps) => {
  const { t } = useTranslation()

  const { accountID } = useUserContext()

  const { mutate } = useSWRConfig()

  const navigate = useNavigate()

  const { toggleAccountDrawerOpen } = useSidebarContext()

  const agencyDrawerItemStyles = useStyles({
    [styles['agency-drawer-item']]: true
  })

  const {
    trimmedLabel: trimmedAccountName,
    TooltipWithFullLabel: TooltipWithFullLabelOfAccountName
  } = getTrimmedLabel({
    label: name,
    maxNumberOfCharacters: 28
  })

  const {
    trimmedLabel: trimmedAccountEmail,
    TooltipWithFullLabel: TooltipWithFullLabelOfAccountEmail
  } = getTrimmedLabel({
    label: email || '',
    maxNumberOfCharacters: 33
  })

  const getImage = () => {
    if (isDisabled) {
      return isMain ? MainDisabled : SubaccountDisabled
    }

    if (isActive) {
      return isMain ? Main : Subaccount
    }

    return isMain ? MainNotActive : SubaccountNotActive
  }

  const getColor = () => {
    if (isDisabled) {
      return 'neutral-2'
    }

    return isActive ? 'neutral' : 'neutral-6'
  }

  const refreshUserAuthorizationAndInfo = () => {
    mutate(() => true, undefined, { revalidate: false })
    mutate('auth', undefined, { revalidate: true })
    mutate('account/info', undefined, { revalidate: true })
    mutate('acl/access/features', undefined, { revalidate: true })
    navigate(LANDINGS.LANDINGS)
  }

  const [requestChangeUserAccount] = useLazyService(changeUserAccount, {
    onSuccess: refreshUserAuthorizationAndInfo,
    successToastText: t('toast.change.user-account.success'),
    errorToastText: t('toast.change.user-account.error')
  })

  const handleChangeUserAccount = async () => {
    if (uuid !== accountID) {
      await requestChangeUserAccount(uuid)
    }

    toggleAccountDrawerOpen()
  }

  return (
    <Button
      variant='text-neutral'
      onClick={handleChangeUserAccount}
      disabled={isDisabled}
      data-testid='agency-drawer-item'
    >
      <Row className={agencyDrawerItemStyles} alignItems='center'>
        <Image src={getImage()} width='18px' height='18px' />

        <Spreader spread={10} />

        <Column>
          <TooltipWithFullLabelOfAccountName>
            <Paragraph weight={600} lineHeight='normal' color={getColor()}>
              {trimmedAccountName}
            </Paragraph>
          </TooltipWithFullLabelOfAccountName>

          {email && (
            <TooltipWithFullLabelOfAccountEmail>
              <Paragraph
                size={12}
                color={isDisabled ? 'neutral-2' : 'neutral-6'}
                lineHeight='normal'
                data-testid='agency-drawer-item-email'
              >
                {trimmedAccountEmail}
              </Paragraph>
            </TooltipWithFullLabelOfAccountEmail>
          )}
        </Column>
      </Row>
    </Button>
  )
}

AgencyDrawerItem.displayName = 'AgencyDrawerItem'
