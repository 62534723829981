import { List, ListItem } from '@landingi/landingi-ui-kit'
import { getWhatHappensAfterFreeTrialQuestion } from '@pages/AddonsStore/routes/Addons/addons/helpers/FAQ'
import styles from '@pages/AddonsStore/routes/Addons/routes/Addon/Addon.module.scss'
import { Paragraph, Spacer, Spreader, StepNumber } from '@ui-kit'
import { TFunction } from 'i18next'
import { Fragment } from 'react'
import { Row } from 'simple-flexbox'

export const getFAQ = (t: TFunction<'translation', undefined>) => [
  getWhatHappensAfterFreeTrialQuestion(t),
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={2} />

        <Spreader spread={15} />

        {t('addons.store.faq.question.2')}
      </Row>
    ),
    content: <Paragraph size={14}>{t('addons.store.faq.answer.2')}</Paragraph>
  },
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={3} />

        <Spreader spread={15} />

        {t('addons.store.event.tracker.faq.question.3')}
      </Row>
    ),
    content: (
      <Fragment>
        <Paragraph size={14}>
          {t('addons.store.event.tracker.faq.answer.3')}
        </Paragraph>

        <table className={styles.faq__table}>
          <tbody>
            <tr>
              <th />
              <th>
                <Paragraph size={14} weight={600}>
                  EventTracker
                </Paragraph>
              </th>
              <th>
                <Paragraph size={14} weight={600}>
                  Google Analytics 4
                </Paragraph>
              </th>
            </tr>
            <tr>
              <td>
                <Paragraph size={14} weight={600}>
                  {t('addons.store.eventtracker.setup')}
                </Paragraph>
              </td>
              <td>
                <Paragraph size={14}>
                  {t('addons.store.eventtracker.ready-to-use')}
                </Paragraph>
              </td>
              <td>
                <Paragraph size={14}>
                  {t('addons.store.eventtracker.multi-step-process')}
                </Paragraph>
              </td>
            </tr>
            <tr>
              <td>
                <Paragraph size={14} weight={600}>
                  {t('addons.store.eventtracker.event.setup')}
                </Paragraph>
              </td>
              <td>
                <Paragraph size={14}>
                  {t('addons.store.eventtracker.automatic')}
                </Paragraph>
              </td>
              <td>
                <Paragraph size={14}>
                  {t('addons.store.eventtracker.manual')}
                </Paragraph>
              </td>
            </tr>
            <tr>
              <td>
                <Paragraph size={14} weight={600}>
                  {t('addons.store.eventtracker.visual.view')}
                </Paragraph>
              </td>
              <td>
                <Paragraph size={14}>
                  {t('addons.store.eventtracker.visual.map')}
                </Paragraph>
              </td>
              <td>
                <Paragraph size={14}>
                  {t('addons.store.eventtracker.none')}
                </Paragraph>
              </td>
            </tr>
            <tr>
              <td>
                <Paragraph size={14} weight={600}>
                  {t('addons.store.eventtracker.scroll.percent')}
                </Paragraph>
              </td>
              <td>
                <Paragraph size={14}>
                  {t('addons.store.eventtracker.many.percent')}
                </Paragraph>
              </td>
              <td>
                <Paragraph size={14}>
                  {t('addons.store.eventtracker.90.percent')}
                </Paragraph>
              </td>
            </tr>
            <tr>
              <td>
                <Paragraph size={14} weight={600}>
                  {t('addons.store.eventtracker.coverage')}
                </Paragraph>
              </td>
              <td>
                <Paragraph size={14}>
                  {t('addons.store.eventtracker.per.page')}
                </Paragraph>
              </td>
              <td>
                <Paragraph size={14}>
                  {t('addons.store.eventtracker.across.pages')}
                </Paragraph>
              </td>
            </tr>
            <tr>
              <td>
                <Paragraph size={14} weight={600}>
                  {t('addons.store.eventtracker.1st.party.cookies')}
                </Paragraph>
              </td>
              <td>
                <Paragraph size={14}>
                  {t('addons.store.eventtracker.yes')}
                </Paragraph>
              </td>
              <td>
                <Paragraph size={14}>
                  {t('addons.store.eventtracker.no')}
                </Paragraph>
              </td>
            </tr>
            <tr>
              <td>
                <Paragraph size={14} weight={600}>
                  {t('addons.store.eventtracker.support')}
                </Paragraph>
              </td>
              <td>
                <Paragraph size={14}>
                  {t('addons.store.eventtracker.chat.email.phone')}
                </Paragraph>
              </td>
              <td>
                <Paragraph size={14}>
                  {t('addons.store.eventtracker.help.center')}
                </Paragraph>
              </td>
            </tr>
            <tr>
              <td>
                <Paragraph size={14} weight={600}>
                  {t('addons.store.eventtracker.google.ads')}
                </Paragraph>
              </td>
              <td>
                <Paragraph size={14}>
                  {t('addons.store.eventtracker.ga4.integration')}
                </Paragraph>
              </td>
              <td>
                <Paragraph size={14}>
                  {t('addons.store.eventtracker.yes')}
                </Paragraph>
              </td>
            </tr>
          </tbody>
        </table>
      </Fragment>
    )
  },
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={4} />

        <Spreader spread={15} />

        {t('addons.store.event.tracker.faq.question.4')}
      </Row>
    ),
    content: (
      <Fragment>
        <Paragraph size={14}>
          {t('addons.store.event.tracker.faq.answer.4')}
        </Paragraph>
        <List listStyle='ordered-decimal'>
          <ListItem variant='list'>
            <Paragraph size={14}>
              {t('addons.store.event.tracker.faq.answer.4.case.1')}
            </Paragraph>
          </ListItem>
          <ListItem variant='list'>
            <Paragraph size={14}>
              {t('addons.store.event.tracker.faq.answer.4.case.2')}
            </Paragraph>
          </ListItem>
          <ListItem variant='list'>
            <Paragraph size={14}>
              {t('addons.store.event.tracker.faq.answer.4.case.3')}
            </Paragraph>
          </ListItem>
          <ListItem variant='list'>
            <Paragraph size={14}>
              {t('addons.store.event.tracker.faq.answer.4.case.4')}
            </Paragraph>
          </ListItem>
          <ListItem variant='list'>
            <Paragraph size={14}>
              {t('addons.store.event.tracker.faq.answer.4.case.5')}
            </Paragraph>
          </ListItem>
          <ListItem variant='list'>
            <Paragraph size={14}>
              {t('addons.store.event.tracker.faq.answer.4.case.6')}
            </Paragraph>
          </ListItem>
        </List>
      </Fragment>
    )
  },
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={5} />

        <Spreader spread={15} />

        {t('addons.store.event.tracker.faq.question.5')}
      </Row>
    ),
    content: (
      <Fragment>
        <Paragraph size={14}>
          {t('addons.store.event.tracker.faq.answer.5.1')}
        </Paragraph>

        <Spacer space={5} />

        <Paragraph size={14}>
          {t('addons.store.event.tracker.faq.answer.5.2')}
        </Paragraph>
      </Fragment>
    )
  }
]
