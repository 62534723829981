import { MODAL_CREDITS_PACKAGE } from '@components/Modals/ModalCreditsPackage'
import { CLICK_BUY_CREDITS, USAGE_DROPDOWN } from '@constants/mixpanel'
import { useAcl } from '@contexts/acl'
import NiceModal from '@ebay/nice-modal-react'
import { mixpanelEvent } from '@services/mixpanel'
import { CustomLink, Spacer } from '@ui-kit'
import { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './BuyCreditsButton.module.scss'

interface BuyCreditsButtonProps {
  close: () => void
}

export const BuyCreditsButton: FC<BuyCreditsButtonProps> = ({ close }) => {
  const { t } = useTranslation()

  const hasAccessToBuyCredits = useAcl('credits.buy_credits')

  const handleOpenModal = () => {
    close()

    mixpanelEvent({
      name: CLICK_BUY_CREDITS,
      properties: {
        click_location: USAGE_DROPDOWN,
        type: 'normal'
      }
    })

    NiceModal.show(MODAL_CREDITS_PACKAGE, {
      properties: {
        click_location: USAGE_DROPDOWN,
        type: 'normal'
      }
    })
  }

  return hasAccessToBuyCredits ? (
    <Fragment>
      <Spacer space={10} />

      <CustomLink
        className={styles.button}
        onClick={handleOpenModal}
        size={12}
        hasUnderline
        data-testid='buy-credits-button'
      >
        {t('topbar.credits.button')}
      </CustomLink>
    </Fragment>
  ) : null
}

BuyCreditsButton.displayName = 'MyUsageButtonsBuyCreditsButton'
