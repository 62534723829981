import {
  TABS,
  useCheckAccessToTab
} from '@components/SidebarNew/hooks/useCheckAccessToTab'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useUserContext } from '@contexts/user'
import {
  LANDINGS,
  LEADS,
  LIGHTBOXES,
  ORDERS,
  POPUPS,
  PRODUCTS,
  PROGRAMMATIC,
  SMART_SECTIONS
} from '@routes/path'
import { Badge, SidebarItem, Spacer, Text } from '@ui-kit'
import { ICONS } from '@ui-kit/ColorIcon/icons'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { OldItem } from './components/PopupsOld'
import styles from './FirstNavigationPart.module.scss'

interface FirstNavigationPartProps {
  isSidebarMini: boolean
}

export const FirstNavigationPart: FC<FirstNavigationPartProps> = ({
  isSidebarMini
}) => {
  const { t } = useTranslation()

  const location = useLocation()

  const { leadsCount } = useUserContext()

  const hasAccessToEcommerce = useFeatureFlags('ECOMMERCE_MODULE')
  const hasAccessToSmartSections = useFeatureFlags('SMART_SECTIONS')
  const hasAccesToPopups = useFeatureFlags('POPUPS')
  const hasAccessToProgrammatic = useFeatureFlags('PROGRAMMATIC_LP')

  const hasAccessToTab = useCheckAccessToTab

  return (
    <nav className={styles.navigation}>
      <SidebarItem
        id='sidebar-landing-pages'
        icon={ICONS.LANDING_PAGES}
        name={t('sidebar.landing-pages')}
        pathname={
          location.pathname === LANDINGS.WELCOME
            ? LANDINGS.WELCOME
            : LANDINGS.LANDINGS
        }
        isMini={isSidebarMini}
      />

      <SidebarItem
        id='sidebar-smart-sections'
        icon={ICONS.SMART_SECTIONS}
        name={t('sidebar.smart-sections')}
        pathname={SMART_SECTIONS.SMART_SECTIONS}
        hasAccess={hasAccessToSmartSections}
        isMini={isSidebarMini}
      />

      <SidebarItem
        id='sidebar-lightboxes'
        icon={ICONS.LIGHTBOXES}
        name={t('sidebar.lightboxes')}
        pathname={LIGHTBOXES.LIGHTBOXES}
        isMini={isSidebarMini}
      />

      <SidebarItem
        id='sidebar-products'
        icon={ICONS.PRODUCTS}
        name={t('sidebar.products')}
        pathname={PRODUCTS.PRODUCTS}
        hasAccess={hasAccessToEcommerce}
        isMini={isSidebarMini}
      />

      <OldItem
        id='sidebar-popups'
        icon={ICONS.POPUPS}
        name={t('sidebar.popups')}
        pathname={POPUPS.POPUPS_OLD}
        hasAccess={hasAccesToPopups}
        isMini={isSidebarMini}
      />

      <SidebarItem
        id='sidebar-programmatic'
        icon={ICONS.PROGRAMMATIC}
        name={t('sidebar.programmatic')}
        pathname={PROGRAMMATIC.PROGRAMMATIC}
        hasAccess={hasAccessToProgrammatic}
        isMini={isSidebarMini}
      />

      <Spacer space={15} />

      {hasAccessToTab(TABS.LEADS) ? (
        <SidebarItem
          id='sidebar-leads'
          icon={ICONS.LEADS}
          name={t('sidebar.leads')}
          pathname={LEADS.LEADS}
          isMini={isSidebarMini}
        >
          {/* TODO: leads count */}
          <Badge
            className={styles['navigation__lead-badge']}
            variant='warmpink-filled'
            radius={250}
          >
            <Text
              size={10}
              weight={600}
              color='white'
              align='center'
              lineHeight='0.8'
            >
              {leadsCount}
            </Text>
          </Badge>
        </SidebarItem>
      ) : null}

      {hasAccessToTab(TABS.ORDERS) ? (
        <SidebarItem
          id='sidebar-orders'
          icon={ICONS.ORDERS}
          name={t('sidebar.orders')}
          pathname={ORDERS.ORDERS}
          isMini={isSidebarMini}
        />
      ) : null}
    </nav>
  )
}

FirstNavigationPart.displayName = 'FirstNavigationPart'
