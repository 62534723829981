import { openWindowWithJWT } from '@helpers/semrush'
import { useLazyService } from '@hooks/useLazyService'
import ModalLightboxesCreateImage from '@images/lightboxes/create/modal_lightboxes_create_image.png'
import { LIGHTBOXES } from '@routes/path'
import { createLightbox } from '@services/lightboxes'
import { mixpanelEvent } from '@services/mixpanel'
import {
  Button,
  Close,
  Heading,
  Image,
  Input,
  Paragraph,
  Spacer
} from '@ui-kit'
import { useFormik } from 'formik'
import { motion } from 'framer-motion'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Column, Row } from 'simple-flexbox'

import { CREATE_LIGHTBOX } from '../constants'
import styles from './Create.module.scss'
import { validationSchema } from './validationSchema'

export const CreateLightbox = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const close = () => navigate(LIGHTBOXES.LIGHTBOXES)

  // This state prevents users from re-clicking the create button if the request succeeds but redirection to the editor is delayed due to a slow connection.
  const [isLoading, setIsLoading] = useState(false)

  const [handleCreateLightbox] = useLazyService(createLightbox, {
    handleErrorMessages: true,
    t,
    forwardError: true,
    onSuccess: async response => {
      await mixpanelEvent({
        name: CREATE_LIGHTBOX
      })

      const redirectLink = `${response?.data?.redirect}?new=true`

      openWindowWithJWT(redirectLink)
    }
  })

  const { values, handleChange, handleSubmit, isValid, dirty } = useFormik({
    validationSchema,
    initialValues: { lightboxName: '' },
    onSubmit: async () => {
      try {
        setIsLoading(true)

        await handleCreateLightbox({
          name: values.lightboxName
        })
      } catch {
        // do nothing
        // error message is handled by useLazyService
        // try-catch is needed to turn off loading, when request fails
        setIsLoading(false)
      }
    }
  })

  return (
    <motion.div
      initial={{ x: '-100vw' }}
      animate={{ x: 0 }}
      exit={{ x: '-100vw' }}
      transition={{ ease: [0.37, 0, 0.39, 1], duration: 0.85 }}
    >
      <Row className={styles.container} justifyContent='center'>
        <Close
          onClick={close}
          className={styles.close}
          data-testid='close-create-ligthbox'
        />

        <Column className={styles.container__form}>
          <form onSubmit={handleSubmit}>
            <Heading level={1} weight={600}>
              {t('modal.lightboxes.create.heading')}
            </Heading>

            <Spacer space={20} />

            <Paragraph color='neutral-6'>
              {t('modal.lightboxes.create.description')}
            </Paragraph>

            <Spacer space={20} />

            <Input
              size={14}
              value={values.lightboxName}
              onChange={handleChange}
              id='lightboxName'
              autoFocus
              i18n={{
                label: t('label.name')
              }}
            />

            <Spacer space={30} />

            <Row justifyContent='flex-end'>
              <Button
                type='submit'
                disabled={!isValid || !dirty || isLoading}
                isLoading={isLoading}
              >
                {t('modal.lightboxes.create.start.editing.button')}
              </Button>
            </Row>
          </form>
        </Column>

        <Column className={styles.container__image}>
          <Image src={ModalLightboxesCreateImage} />
        </Column>
      </Row>
    </motion.div>
  )
}
CreateLightbox.displayName = 'CreateLightbox'
