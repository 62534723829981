import { useSidebarContext } from '@contexts/sidebar'
import { useUserContext } from '@contexts/user'
import { getTrimmedLabel } from '@helpers/getTrimmedLabel'
import { useHover } from '@hooks/useHover'
import { useStyles } from '@hooks/useStyles'
import Chevrons from '@images/sidebar/icons/chevrons.svg'
import ChevronsHovered from '@images/sidebar/icons/chevrons-hovered.svg'
import Main from '@images/sidebar/icons/main.svg'
import MainHovered from '@images/sidebar/icons/main-hovered.svg'
import Subaccount from '@images/sidebar/icons/subaccount.svg'
import SubaccountHovered from '@images/sidebar/icons/subaccount-hovered.svg'
import { Image, Spreader, Text } from '@ui-kit'
import { ElementAnimation } from '@ui-kit/SidebarItem/components/ElementAnimation'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

import styles from './AccountSwitcher.module.scss'

interface AccountSwitcherProps {
  isMini: boolean
}

export const AccountSwitcher: FC<AccountSwitcherProps> = ({ isMini }) => {
  const { t } = useTranslation()

  const [accountSwitcherProps, isHoveredAccountSwitcher] = useHover()

  const { accountName, isAgency, isSubaccount } = useUserContext()

  const { toggleAccountDrawerOpen } = useSidebarContext()

  const accountSwitcherStyles = useStyles({
    [styles['account-switcher']]: true,
    [styles['account-switcher--hovered']]: isHoveredAccountSwitcher
  })

  const getLabelForSwitcher = () => {
    if (!accountName) {
      return t('word.main-account')
    }

    if (isAgency || isSubaccount) {
      return accountName
    }

    return t('word.main-account')
  }

  const getImage = () => {
    if (isHoveredAccountSwitcher) {
      if (isSubaccount) {
        return SubaccountHovered
      }

      return MainHovered
    }

    if (isSubaccount) {
      return Subaccount
    }

    return Main
  }

  const { trimmedLabel, TooltipWithFullLabel } = getTrimmedLabel({
    label: getLabelForSwitcher(),
    maxNumberOfCharacters: 15
  })

  return (
    <Row
      className={accountSwitcherStyles}
      alignItems='center'
      {...accountSwitcherProps}
      onClick={toggleAccountDrawerOpen}
      data-testid='account-switcher'
    >
      <Row>
        <Row alignItems='center' className={styles['account-switcher__image']}>
          <ElementAnimation>
            <Image src={getImage()} width='18px' height='18px' />
          </ElementAnimation>
        </Row>

        {isMini ? null : (
          <ElementAnimation>
            <Spreader spread={5} />

            <TooltipWithFullLabel>
              <Text
                weight={600}
                size={14}
                className={styles['account-switcher__text']}
                color={isHoveredAccountSwitcher ? 'primary' : 'neutral'}
              >
                {trimmedLabel}
              </Text>
            </TooltipWithFullLabel>
          </ElementAnimation>
        )}
      </Row>

      {isMini ? null : (
        <ElementAnimation>
          <Image src={isHoveredAccountSwitcher ? ChevronsHovered : Chevrons} />
        </ElementAnimation>
      )}
    </Row>
  )
}
