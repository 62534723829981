import { useLazyService } from '@hooks/useLazyService'
import { LANDINGS } from '@routes/path'
import { importLandingFromFile } from '@services/landings/list/importLandingFromFile'
import React, { ChangeEvent, Fragment, ReactElement, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export const ImportButton = ({ children }: { children: ReactElement }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const inputRef = useRef<HTMLInputElement>(null)

  const removeFile = () => {
    if (inputRef.current) {
      // set file input value to empty string, because otherwise it is not possible to upload the same file twice
      inputRef.current.value = ''
    }
  }

  const [uploadFile, { isLoading }] = useLazyService<FormData>(
    importLandingFromFile,
    {
      successToastText: t('landing.pages.import.success'),
      handleErrorMessages: true,
      t,
      onSuccess: async () => {
        navigate(LANDINGS.LANDINGS)
      },
      onError: () => {
        removeFile()
      }
    }
  )

  const handleFileImport = () => inputRef?.current?.click()

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0]
    const formData = new FormData()

    if (!file) return

    formData.append('landingPageFile', file)
    formData.append('fileType', 'application/zip')

    await uploadFile(formData)
  }

  return (
    <Fragment>
      {React.cloneElement(children, {
        onClick: handleFileImport,
        disabled: isLoading,
        isLoading
      })}
      <input
        style={{ display: 'none' }}
        ref={inputRef}
        type='file'
        onChange={handleFileChange}
      />
    </Fragment>
  )
}
