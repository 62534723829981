import { BREAKPOINT } from '@constants/breakpoints'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useUserContext } from '@contexts/user'
import { getLocalStorage, setLocalStorage } from '@helpers/storage'
import { useBreakpoint } from '@hooks/useBreakpoint'
import { useChangeSubaccountToMain } from '@hooks/useChangeSubaccountToMain'
import { useStyles } from '@hooks/useStyles'
import ADDONS_STORE from '@routes/path/addons'
import { CustomLink, Paragraph, Spreader } from '@ui-kit'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Column, Row } from 'simple-flexbox'

import styles from './AddonsStore.module.scss'

export const AddonsStoreBanner = () => {
  const { t } = useTranslation()

  const { isTypeClient } = useUserContext()

  const navigate = useNavigate()

  const hasAccessToPromoBanner = useFeatureFlags('PROMO_BANNER')

  const showAnimation =
    getLocalStorage('disableAddonStoreBannerAnimation') !== 'true'

  const showCharacterStyles = useStyles({
    [styles['animation-show__character']]: showAnimation
  })

  const boxContentStyles = useStyles({
    [styles.box__content]: true,
    [styles['box__content--animation']]: showAnimation
  })

  const boxStyles = useStyles({
    [styles.st6]: true,
    [styles['animation-show__box']]: showAnimation
  })

  const triangleStyles = useStyles({
    [styles.st6]: true,
    [styles['animation-show__triangle']]: showAnimation
  })

  const handleDisableAnimation = () => {
    if (showAnimation) {
      setLocalStorage('disableAddonStoreBannerAnimation', true)
    }
  }

  const { areSubaccountsLoading, changeToMainAccountIfNeeded, isTypeManager } =
    useChangeSubaccountToMain()

  const isVisible =
    useBreakpoint(BREAKPOINT[1130], 'min') &&
    hasAccessToPromoBanner &&
    !isTypeClient &&
    !isTypeManager

  const handleOpenURL = async () => {
    await changeToMainAccountIfNeeded()

    navigate(ADDONS_STORE.ADDONS_STORE)
  }

  return isVisible && !areSubaccountsLoading ? (
    <div
      style={{ width: 350, height: 60, position: 'relative' }}
      id='mrpromo'
      className='animation-show animation-hand'
    >
      <Row
        className={boxContentStyles}
        alignItems='center'
        justifyContent='center'
        onClick={handleOpenURL}
      >
        <Spreader spread={10} />

        <Column>
          <Paragraph color='primary'>
            <Trans
              i18nKey='topbar.promo-banner.addon-store.title'
              components={{
                b: <strong />
              }}
            />

            <Spreader spread={5} />

            <CustomLink size={12} hasUnderline>
              {t('topbar.promo-banner.addon-store.link')}
            </CustomLink>
          </Paragraph>
        </Column>
      </Row>

      <svg
        version='1.1'
        id='mrpromosvg'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        x='0px'
        y='0px'
        viewBox='0 0 350 60'
        enableBackground='new 0 0 350 60'
        xmlSpace='preserve'
      >
        <g
          className={showCharacterStyles}
          onAnimationEnd={handleDisableAnimation}
        >
          <g className={styles['animation-hand__character']}>
            <g id='hand' transform='translate(38.782 24.315) rotate(65)'>
              <path
                id='Path_117'
                className={styles.st0}
                d='M15.2,16.2L0.7,1.9'
              />
              <path id='Path_118' className={styles.st0} d='M0.9,2l0.5-3.2' />
              <path
                id='Path_119'
                className={styles.st0}
                d='M0.9,2.1l-3.3,0.9'
              />
              <path
                id='Path_120'
                className={styles.st1}
                d='M0.8,1.9l-2.5-2.1'
              />
            </g>
            <path
              id='Subtraction_1'
              className={styles.st2}
              d='M35.1,60H6c-10-14.9-6-35,8.8-45c4.2-2.8,9.1-4.6,14.1-5.3L35.1,60L35.1,60z'
            />
            <path
              id='Path_128'
              className={styles.st3}
              d='M20.5,5l7,9.3l9.3-7L20.5,5z'
            />
            <path
              id='Path_127'
              className={styles.st4}
              d='M26.5,27.3l3.1-5.7l3.5,6'
            />
            <path
              id='eye'
              className={styles.st5}
              d='M17.3,29.9l1.9-6.2l4.7,5.1'
            />
            <path
              id='Path_129'
              className={styles.st0}
              d='M18,34.2l12.1-1.9c0,0-1.6,5.9-3.9,6S18,34.2,18,34.2z'
            />
            <path d='M11.6,46.7L5.4,59.1C5.6,59.4,5.8,59.7,6,60h0.7L13,47.4L11.6,46.7z' />
          </g>
        </g>
        <rect
          x='60'
          y='0'
          className={boxStyles}
          width='290'
          height='60'
          style={{ position: 'relative' }}
        />
        <path className={triangleStyles} d='M51,32l11-9v18L51,32z' />
      </svg>
    </div>
  ) : null
}

AddonsStoreBanner.displayName = 'PromoAddonsStoreBanner'
