import ETUseCase1 from '@images/addons/usecases/eventracker/ET-usecase-1.png'
import ETUseCase2 from '@images/addons/usecases/eventracker/ET-usecase-2.png'
import ETUseCase3 from '@images/addons/usecases/eventracker/ET-usecase-3.png'
import { Paragraph, Spacer } from '@ui-kit'
import { TFunction } from 'i18next'
import { Fragment } from 'react'

export const getUseCases = (t: TFunction<'translation', undefined>) => [
  {
    title: t('addons.store.event.tracker.use.cases.1.title'),
    image: ETUseCase1,
    content: (
      <Fragment>
        <Paragraph>
          {t('addons.store.event.tracker.use.cases.1.content.paragraph.1')}
        </Paragraph>

        <Spacer space={10} />

        <Paragraph>
          {t('addons.store.event.tracker.use.cases.1.content.paragraph.2')}
        </Paragraph>
      </Fragment>
    )
  },
  {
    title: t('addons.store.event.tracker.use.cases.2.title'),
    reverse: true,
    image: ETUseCase2,
    content: (
      <Fragment>
        <Paragraph>
          {t('addons.store.event.tracker.use.cases.2.content.paragraph.1')}
        </Paragraph>

        <Spacer space={10} />

        <Paragraph>
          {t('addons.store.event.tracker.use.cases.2.content.paragraph.2')}
        </Paragraph>
      </Fragment>
    )
  },
  {
    title: t('addons.store.event.tracker.use.cases.3.title'),
    image: ETUseCase3,
    content: (
      <Fragment>
        <Paragraph>
          {t('addons.store.event.tracker.use.cases.3.content.paragraph.1')}
        </Paragraph>

        <Spacer space={10} />

        <Paragraph>
          {t('addons.store.event.tracker.use.cases.3.content.paragraph.2')}
        </Paragraph>
      </Fragment>
    )
  }
]
