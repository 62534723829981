import client from '@lib/http/client'

export interface CreateLightboxRequest {
  name: string
}

export const createLightbox = ({ name }: CreateLightboxRequest) =>
  client.post(`/lightboxes`, {
    name
  })

export const duplicateLightbox = ({
  name,
  uuid
}: {
  name: string
  uuid: string
}) =>
  client.post(`/lightboxes/${uuid}/duplicate`, {
    name
  })

export const deleteLightboxes = (lightboxesIds: string[]) =>
  client.delete(`lightboxes`, {
    params: {
      lightboxes: lightboxesIds
    }
  })

export const publishLightbox = ({ uuid }: { uuid: string }) =>
  client.patch(`/lightboxes/${uuid}/publish`)

export const unPublishLightbox = ({ uuid }: { uuid: string }) =>
  client.patch(`/lightboxes/${uuid}/unpublish`)

export const renameLightbox = ({
  uuid,
  name
}: {
  uuid: string
  name: string
}) =>
  client.post(`/lightboxes/${uuid}/rename`, {
    name
  })
