import { formatNumeric } from '@helpers/data'
import { Icon, Spreader, Text, Tooltip } from '@ui-kit'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

export const Credits = ({ credits }: { credits: number }) => {
  const { t } = useTranslation()

  return (
    <Fragment>
      <Text size={12} color='neutral-8' lineHeight='normal'>
        {t('topbar.credits')}
      </Text>

      <Row vertical='baseline' horizontal='start'>
        <Text
          size={12}
          color='neutral'
          weight={600}
          lineHeight='normal'
          data-testid='credits-quantity'
        >
          {formatNumeric(credits)}
        </Text>

        <Spreader spread={5} />

        <Tooltip content={t('topbar.credits.tooltip')}>
          <Icon icon='icon-info-circle' size={10} />
        </Tooltip>
      </Row>
    </Fragment>
  )
}

Credits.displayName = 'MyUsageCredits'
