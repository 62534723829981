import { PROD } from '@config/env'
import { getLoginErrorMessage } from '@helpers/errors'
import BlueLogo from '@images/registration/exwhitelabels/default/blue_logo.png'
import BonduelleLogo from '@images/registration/exwhitelabels/default/bonduelle_logo.png'
import D2Logo from '@images/registration/exwhitelabels/default/d2_logo_v2.png'
import DatalogyxLogo from '@images/registration/exwhitelabels/default/datalogyx_logo.png'
import DingiLogo from '@images/registration/exwhitelabels/default/dingi_logo.png'
import DtComunicacionLogo from '@images/registration/exwhitelabels/default/dtcomunicacion-logo.png'
import MloPagesLogo from '@images/registration/exwhitelabels/default/mlopages_logo.png'
import TapsiteLogo from '@images/registration/exwhitelabels/default/tapsite_logo.png'
import { emitTimingToastToggle } from '@landingi/landingi-ui-kit'
import { REGISTRATION } from '@routes/path'
import {
  customLogin,
  customSignInWithRecoveryCode,
  customVerify2FACode,
  postCustomChangePassword,
  postCustomResetPassword
} from '@services/authentication'
import PropTypes from 'prop-types'
import { createContext, useContext } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useSWRConfig } from 'swr'

const WhitelabelPageContext = createContext(null)

const whitelabelPagesConfigs = {
  default: {
    background: null,
    logoUrl: '',
    mainColor: null,
    buttonStyle: {
      borderRadius: null,
      boxShadow: null
    },
    panelStyle: {
      borderRadius: null,
      hasShadow: false
    }
  },
  dtcomunicacion: {
    background:
      'linear-gradient(180deg, rgba(0,0,0,1) 50%, rgba(244,178,61,1) 50%)',
    logoUrl: DtComunicacionLogo,
    mainColor: '#000000',
    buttonStyle: {
      borderRadius: null,
      boxShadow: null
    },
    panelStyle: {
      borderRadius: null,
      hasShadow: false
    }
  },
  clixium: {
    background: '#3f444b',
    logoUrl: TapsiteLogo,
    mainColor: '#ff4f58',
    buttonStyle: {
      backgroundColor: '#ff4f58',
      borderRadius: '4px',
      boxShadow: null
    },
    panelStyle: {
      borderRadius: '4px',
      hasShadow: false
    }
  },
  mlopages: {
    background: '#3b3a3a',
    logoUrl: MloPagesLogo,
    mainColor: '#3498db',
    buttonStyle: {
      backgroundColor: '#3498db',
      borderRadius: '4px',
      boxShadow: null
    },
    panelStyle: {
      borderRadius: '4px',
      hasShadow: false
    }
  },
  bbbbuzz: {
    background: '#004990',
    logoUrl: BlueLogo,
    mainColor: '#97c0e6',
    buttonStyle: {
      backgroundColor: '#97c0e6',
      borderRadius: '4px',
      boxShadow: null
    },
    panelStyle: {
      borderRadius: '4px',
      hasShadow: false
    }
  },
  d2funnels: {
    background: '#1F3044',
    logoUrl: D2Logo,
    mainColor: '#FB9039',
    buttonStyle: {
      backgroundColor: '#FB9039',
      borderRadius: '0px',
      boxShadow: null
    },
    panelStyle: {
      borderRadius: '0px',
      hasShadow: false
    }
  },
  israel: {
    background: 'linear-gradient(180deg, #101D42 40%,  #F1F8F9 40%)',
    logoUrl: DingiLogo,
    mainColor: '#101D42',
    buttonStyle: {
      backgroundColor: '#232ED1',
      borderRadius: '0px',
      boxShadow: null
    },
    panelStyle: {
      borderRadius: '0px',
      hasShadow: false
    }
  },
  bonduelle: {
    background: 'linear-gradient(180deg, #FFFFFF 50%,  #004927 50%)',
    logoUrl: BonduelleLogo,
    mainColor: '#004927',
    buttonStyle: {
      backgroundColor: '#004927',
      borderRadius: '0px',
      boxShadow: null
    },
    panelStyle: {
      borderRadius: '0px'
    },
    hasMicrosoftSignInButton: true
  },
  datalogyx: {
    background: '#f8f5f5',
    logoUrl: DatalogyxLogo,
    mainColor: '#0957d5',
    buttonStyle: {
      backgroundColor: '#0957d5',
      borderRadius: '0px',
      boxShadow: null
    },
    panelStyle: {
      borderRadius: '0px',
      hasShadow: false
    }
  }
}

/**
 * WhiteLabelPageProvider - provides information about style that should be displayed
 * on whitelabel page based on url
 * @param {object} props.children
 * @param {object} props
 */
export const WhitelabelPageProvider = ({ children }) => {
  const params = useParams()

  const currentWhitelabelName = params.clientName

  const { executeRecaptcha } = useGoogleReCaptcha()
  const { mutate } = useSWRConfig()
  const navigate = useNavigate()

  const { t } = useTranslation()

  const getWhitelabelPageConfig = () =>
    whitelabelPagesConfigs[currentWhitelabelName] ||
    whitelabelPagesConfigs.default

  const signIn = async (email, password, whitelabelName) => {
    try {
      let token
      if (PROD) {
        token = await executeRecaptcha('login')
      } else {
        token = true
      }

      const clientName = currentWhitelabelName || whitelabelName

      await customLogin({
        email,
        password,
        clientName,
        captchaChallenge: token
      })

      mutate('auth')
    } catch (error) {
      emitTimingToastToggle(
        t(getLoginErrorMessage(error?.error?.code)),
        'alert'
      )
    }
  }

  const changePassword = async (token, password, whitelabelName) => {
    try {
      const clientName = currentWhitelabelName || whitelabelName

      await postCustomChangePassword(token, password, clientName)

      const { DEFAULT } = REGISTRATION.LOGIN

      emitTimingToastToggle(
        t('registration.flow.reset.password.grant.new.success'),
        'success'
      )

      navigate(`${DEFAULT}/${clientName}`)
    } catch {
      emitTimingToastToggle(t('registration.flow.token.invalid'), 'alert')
    }
  }

  const verify2FACode = async (code, whitelabelName) => {
    await customVerify2FACode(code, currentWhitelabelName || whitelabelName)

    mutate('auth')
  }

  const resetPassword = async (email, whitelabelName) => {
    try {
      await postCustomResetPassword(
        email,
        currentWhitelabelName || whitelabelName
      )
    } finally {
      emitTimingToastToggle(
        t('registration.flow.reset.password.success'),
        'success'
      )
    }
  }

  const signInWithRecoveryCode = async (code, whitelabelName) => {
    await customSignInWithRecoveryCode(
      code,
      currentWhitelabelName || whitelabelName
    )

    mutate('auth')
  }

  const contextValue = {
    getWhitelabelPageConfig,
    signIn,
    changePassword,
    verify2FACode,
    resetPassword,
    signInWithRecoveryCode
  }

  return (
    <WhitelabelPageContext.Provider value={contextValue}>
      {children}
    </WhitelabelPageContext.Provider>
  )
}

WhitelabelPageProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const useWhitelabelPageContext = () => {
  const whitelabelPage = useContext(WhitelabelPageContext)

  if (!whitelabelPage) {
    throw new Error(
      'useWhitelabelPageContext must be used inside WhitelabelpageProvider'
    )
  }

  return whitelabelPage
}
