import agency_active from '@images/sidebar/icons/agency_active.svg'
import agency_inactive from '@images/sidebar/icons/agency_inactive.svg'
import landingpages_active from '@images/sidebar/icons/landingpages_active.svg'
import landingpages_inactive from '@images/sidebar/icons/landingpages_inactive.svg'
import leads_active from '@images/sidebar/icons/leads_active.svg'
import leads_inactive from '@images/sidebar/icons/leads_inactive.svg'
import lightboxes_active from '@images/sidebar/icons/lightboxes_active.svg'
import lightboxes_inactive from '@images/sidebar/icons/lightboxes_inactive.svg'
import orders_active from '@images/sidebar/icons/orders_active.svg'
import orders_inactive from '@images/sidebar/icons/orders_inactive.svg'
import popups_active from '@images/sidebar/icons/popups_active.svg'
import popups_inactive from '@images/sidebar/icons/popups_inactive.svg'
import products_active from '@images/sidebar/icons/products_active.svg'
import products_inactive from '@images/sidebar/icons/products_inactive.svg'
import programmatic_active from '@images/sidebar/icons/programmatic_active.svg'
import programmatic_inactive from '@images/sidebar/icons/programmatic_inactive.svg'
import smartsections_active from '@images/sidebar/icons/smartsections_active.svg'
import smartsections_inactive from '@images/sidebar/icons/smartsections_inactive.svg'

export const ICONS = {
  AGENCY: 'agency',
  LEADS: 'leads',
  LIGHTBOXES: 'lightboxes',
  LANDING_PAGES: 'landingpages',
  POPUPS: 'popups',
  PRODUCTS: 'products',
  PROGRAMMATIC: 'programmatic',
  SMART_SECTIONS: 'smartsections',
  ORDERS: 'orders'
} as const

export const STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive'
} as const

export type IconTypes = (typeof ICONS)[keyof typeof ICONS]
export type StatusTypes = (typeof STATUS)[keyof typeof STATUS]

export const icons = {
  [ICONS.AGENCY]: {
    [STATUS.ACTIVE]: agency_active,
    [STATUS.INACTIVE]: agency_inactive
  },
  [ICONS.LEADS]: {
    [STATUS.ACTIVE]: leads_active,
    [STATUS.INACTIVE]: leads_inactive
  },
  [ICONS.LIGHTBOXES]: {
    [STATUS.ACTIVE]: lightboxes_active,
    [STATUS.INACTIVE]: lightboxes_inactive
  },
  [ICONS.LANDING_PAGES]: {
    [STATUS.ACTIVE]: landingpages_active,
    [STATUS.INACTIVE]: landingpages_inactive
  },
  [ICONS.POPUPS]: {
    [STATUS.ACTIVE]: popups_active,
    [STATUS.INACTIVE]: popups_inactive
  },
  [ICONS.PRODUCTS]: {
    [STATUS.ACTIVE]: products_active,
    [STATUS.INACTIVE]: products_inactive
  },
  [ICONS.PROGRAMMATIC]: {
    [STATUS.ACTIVE]: programmatic_active,
    [STATUS.INACTIVE]: programmatic_inactive
  },
  [ICONS.SMART_SECTIONS]: {
    [STATUS.ACTIVE]: smartsections_active,
    [STATUS.INACTIVE]: smartsections_inactive
  },
  [ICONS.ORDERS]: {
    [STATUS.ACTIVE]: orders_active,
    [STATUS.INACTIVE]: orders_inactive
  }
}
