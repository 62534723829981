const LANDINGS = {
  LANDINGS: '/landings',
  WELCOME: '/welcome',
  LANDINGS_AB: '/landings/dashboard/:identifier/test',
  EVENT_TRACKER: '/landings/dashboard/:identifier/events',
  LANDINGS_ARCHIVED: '/landings/archived',
  LANDINGS_CREATE: '/landings/create',
  LANDINGS_CREATE_FIGMA: '/landings/create/figma',
  LANDINGS_CREATE_WIZARD: '/landings/create/wizard',
  LANDINGS_CREATE_WIZARD_FIRST: '/landings/create/wizard/first',
  LANDINGS_CREATE_WIZARD_SECOND: '/landings/create/wizard/second',
  LANDINGS_CREATE_WIZARD_THIRD: '/landings/create/wizard/third',
  LANDINGS_CREATE_WIZARD_FOURTH: '/landings/create/wizard/fourth',
  LANDINGS_CREATE_WIZARD_FIFTH: '/landings/create/wizard/fifth',
  LANDINGS_CREATE_WIZARD_SIXTH: '/landings/create/wizard/sixth',
  LANDINGS_CREATE_WIZARD_SEVENTH: '/landings/create/wizard/seventh',
  LANDINGS_WIZARD_GENERATION: '/landings/create/wizard/generation',
  LANDINGS_GROUP: '/landings/group/:identifier',
  LANDINGS_DASHBOARD: '/landings/dashboard/:identifier',
  LANDINGS_UNGROUPED: '/landings/ungrouped',
  LANDINGS_INFO_BAR: '/landings/dashboard/:identifier/infobar',
  LANDINGS_JAVASCRIPT: '/landings/dashboard/:identifier/javascript',
  LANDINGS_JAVASCRIPT_ADD: '/landings/dashboard/:identifier/javascript/add',
  LANDINGS_JAVASCRIPT_EDIT:
    '/landings/dashboard/:identifier/javascript/edit/:jsCodeIdentifier',
  LANDINGS_PREVIEW: '/landings/preview/:identifier',
  LANDINGS_TEMPLATES_PREVIEW: '/landings/templates/preview/:identifier',
  LANDINGS_PUBLISHING_OPTIONS: {
    DEFAULT: '/landings/publishing-options',
    MAIN: '/landings/publishing-options/main/:identifier',
    CHANGE_URL: '/landings/publishing-options/change_url/:identifier',
    WORDPRESS: '/landings/publishing-options/wordpress/:identifier',
    EMBED: '/landings/publishing-options/embed/:identifier',
    REVERSE_PROXY: '/landings/publishing-options/reverse_proxy/:identifier'
  },
  LANDINGS_SCHEDULE: '/landings/dashboard/:identifier/schedule',
  LANDINGS_SETTINGS: '/landings/dashboard/:identifier/settings',
  LANDINGS_UPLOAD: '/landings/upload',
  LANDINGS_TEMPLATES: '/landings/templates'
} as const

export default LANDINGS
